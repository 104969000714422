import React, { FC, ComponentProps } from 'react'
import { FormattedMessage } from 'components'
import { MessageDrawer } from 'tackl-material'

type Props = Pick<ComponentProps<typeof MessageDrawer>, 'open' | 'onClose'>

const InsufficientBalanceMessageDrawer: FC<Props> = ({ onClose, ...props }) => (
  <MessageDrawer
    {...props}
    onClose={onClose}
    closeMethod="button"
    headline={<FormattedMessage id="alert_insufficientbalance_title" />}
    body={<FormattedMessage id="alert_insufficientbalance_message" />}
  />
)

export default InsufficientBalanceMessageDrawer
