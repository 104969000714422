import React, { FC, useState, useCallback, useEffect } from 'react'
import useInterval from '@use-it/interval'

interface Props {
  date?: Date | number | string | null
  onExpired?: () => void
}

const CountdownClock: FC<Props> = ({ date, onExpired }) => {
  const [state, setState] = useState({ hours: 0, minutes: 0, seconds: 0, isExpired: false })

  const nextTick = useCallback(() => {
    const nowInSeconds = Math.floor(Date.now() / 1000)
    const thenInSeconds = date ? Math.floor(Number(new Date(date)) / 1000) : nowInSeconds

    if (!state.isExpired) {
      let seconds = thenInSeconds - nowInSeconds

      const hours = Math.floor(seconds / 3600)
      seconds -= hours * 3600
      const minutes = Math.floor(seconds / 60)
      seconds -= minutes * 60
      const isExpired = thenInSeconds <= nowInSeconds

      setState({ hours, minutes, seconds, isExpired })
    }
  }, [date, state.isExpired])

  useInterval(() => {
    nextTick()
  }, 1000)

  useEffect(() => {
    nextTick()
  }, [nextTick])

  useEffect(() => {
    if (state.isExpired && onExpired) {
      onExpired()
    }
  }, [onExpired, state.isExpired])

  return (
    <>
      {String(state.hours).padStart(2, '0')}:
      {String(state.minutes).padStart(2, '0')}:
      {String(state.seconds).padStart(2, '0')}
    </>
  )
}

export default CountdownClock
