import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Whistle: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="74"
    height="42"
    viewBox="0 0 74 42"
    {...props}
  >
    <g
      fill="inherit"
      fillRule="evenodd"
    >
      <path
        d="M28.8435185,41.1433048 C17.5410969,41.1433048 8.35092593,31.9531339 8.35092593,20.6569801 C8.35092593,9.35769231 17.5442308,0.167521368 28.8435185,0.167521368 C40.1365385,0.167521368 49.3274929,9.35769231 49.3274929,20.6569801 C49.3274929,31.9531339 40.1373219,41.1433048 28.8435185,41.1433048 Z M47.0030913,22.3062952 C46.1651534,31.5914316 38.3400678,38.8931624 28.8435185,38.8931624 C18.7805556,38.8931624 10.597151,30.7097578 10.597151,20.6530627 C10.597151,10.6841019 18.6333854,2.55781999 28.5712936,2.41182749 C28.0293463,1.76968787 27.7201239,1.36849823 27.7201239,1.29044274 C27.7201239,0.669169943 28.2220342,0.167251852 28.8433148,0.167251852 L72.8676026,0.167251852 C73.4888754,0.167251852 73.9907934,0.669169943 73.9907934,1.29044274 L73.9907934,7.57352678 C73.9907934,8.14888789 73.5531517,8.63247265 72.9838704,8.69060655 C72.7696368,8.71202913 51.4899957,11.0410339 49.2375028,22.248655 C49.130386,22.7811756 48.6621339,23.1483986 48.1388348,23.1483986 C48.0632325,23.148433 47.9897785,23.1423118 47.9163276,23.1270097 C47.8462054,23.1128436 47.5218688,22.8178664 47.0030913,22.3062952 Z M5.40975783,27.2123219 C2.54826923,27.2123219 0.222286325,24.886339 0.222286325,22.0248504 C0.222286325,19.1633618 2.54826923,16.8373789 5.40975783,16.8373789 C8.27124644,16.8373789 10.5972293,19.1633618 10.5972293,22.0248504 C10.5972293,24.886339 8.27124644,27.2123219 5.40975783,27.2123219 Z"
        opacity="0.2"
      />
      <path d="M28.8435185,34.8574786 C21.0087607,34.8574786 14.6336182,28.4856268 14.6336182,20.6538462 C14.6336182,12.8190883 21.0085256,6.44707977 28.8435185,6.44707977 C36.6721652,6.44707977 43.0408832,12.8189316 43.0408832,20.6538462 C43.0408832,28.4887607 36.6720869,34.8574786 28.8435185,34.8574786 Z M28.8435185,8.69643875 C22.2451638,8.69643875 16.8798433,14.0614459 16.8798433,20.6569801 C16.8798433,27.2492236 22.2448504,32.6112536 28.8435185,32.6112536 C35.4327066,32.6112536 40.7946581,27.2462464 40.7946581,20.6569801 C40.7946581,14.0616809 35.4327066,8.69643875 28.8435185,8.69643875 Z" />
      <path d="M28.8435185,41.1433048 C17.5410969,41.1433048 8.35092593,31.9531339 8.35092593,20.6569801 C8.35092593,9.35769231 17.5442308,0.167521368 28.8435185,0.167521368 C40.1365385,0.167521368 49.3274929,9.35769231 49.3274929,20.6569801 C49.3274929,31.9531339 40.1373219,41.1433048 28.8435185,41.1433048 Z M28.8435185,2.40982906 C18.7805556,2.40982906 10.597151,10.5932336 10.597151,20.6530627 C10.597151,30.7097578 18.7805556,38.8931624 28.8435185,38.8931624 C38.8970798,38.8931624 47.0773504,30.7097578 47.0773504,20.6530627 C47.0773504,10.5963675 38.8970798,2.40982906 28.8435185,2.40982906 Z" />
      <path d="M48.1397436,23.148433 C48.0632325,23.148433 47.9897785,23.1423118 47.9163276,23.1270097 C47.3103561,23.0045916 46.915594,22.4139214 47.0379729,21.8048943 C49.3363775,10.3739826 67.2187422,7.20325613 71.7480157,6.58195983 L71.7480157,2.41363362 L28.8433148,2.41363362 C28.222042,2.41363362 27.7201239,1.91172336 27.7201239,1.29044274 C27.7201239,0.669169943 28.2220342,0.167251852 28.8433148,0.167251852 L72.8676026,0.167251852 C73.4888754,0.167251852 73.9907934,0.669169943 73.9907934,1.29044274 L73.9907934,7.57352678 C73.9907934,8.14888789 73.5531517,8.63247265 72.9838704,8.69060655 C72.7696368,8.71202913 51.4899957,11.0410339 49.2375028,22.248655 C49.130386,22.7811756 48.6621339,23.1483986 48.1388348,23.1483986 L48.1397436,23.148433 Z" />
      <path d="M5.40975783,27.2123219 C2.54826923,27.2123219 0.222286325,24.886339 0.222286325,22.0248504 C0.222286325,19.1633618 2.54826923,16.8373789 5.40975783,16.8373789 C8.27124644,16.8373789 10.5972293,19.1633618 10.5972293,22.0248504 C10.5972293,24.886339 8.27124644,27.2123219 5.40975783,27.2123219 Z M5.40975783,19.0837607 C3.78772792,19.0837607 2.46866809,20.4028205 2.46866809,22.0248504 C2.46866809,23.6468803 3.78772792,24.9659402 5.40975783,24.9659402 C7.03178775,24.9659402 8.35084758,23.6468803 8.35084758,22.0248504 C8.35084758,20.4028205 7.03178775,19.0837607 5.40975783,19.0837607 Z" />
      <path d="M52.1033476,8.69643875 L48.2012464,8.69643875 C47.5799736,8.69643875 47.0780556,8.19452849 47.0780556,7.57324786 L47.0780556,1.29016382 C47.0780556,0.668891026 47.5799736,0.166972934 48.2012464,0.166972934 L52.1033476,0.166972934 C52.7246204,0.166972934 53.2265385,0.668891026 53.2265385,1.29016382 L53.2265385,7.57324786 C53.2265385,8.19452066 52.7246282,8.69643875 52.1033476,8.69643875 Z M49.3274929,6.45005698 L50.9801567,6.45005698 L50.9801567,2.4133547 L49.3274929,2.4133547 L49.3274929,6.45005698 Z" />
    </g>
  </SvgIcon>
)

export default Whistle
