import { IPrediction } from 'protos'
import { PredictionMap } from './stateReducer'

export const isValidPrediction = (prediction: IPrediction) =>
  prediction?.predictedScore?.homeScore! >= 0 && prediction?.predictedScore?.awayScore! >= 0

export function predictionMapToPredictions(predictionMap: PredictionMap): IPrediction[] {
  return Object
    .keys(predictionMap)
    .map((matchId) => predictionMap[matchId])
    .filter(isValidPrediction)
}

export function predictionsToPredictionMap(predictions: IPrediction[]): PredictionMap {
  return predictions
    .filter((prediction) => !!prediction.matchId)
    .reduce((predictionMap, prediction) => ({
      ...predictionMap,
      [prediction.matchId!]: prediction,
    }), {} as PredictionMap)
}
