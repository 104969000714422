const URI = (strings: TemplateStringsArray, ...params: string[]) => {
  let output = strings[0]

  for (let i = 1; i < strings.length; ++i) {
    output += encodeURIComponent(params[i - 1])
    output += strings[i]
  }

  return output
}

export default URI
