import React, { FC, ComponentProps, ChangeEvent, useCallback, memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Slider, thumbHeight } from 'tackl-material'

type Props = Omit<ComponentProps<typeof Slider>, 'value' | 'onChange'> & {
  value?: number
  onChange: (newValue: number) => void
}

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: thumbHeight / 2,
    paddingBottom: thumbHeight / 2,
  },
}))

const noScoreLabel = '–'
const scale = [
  { value: -1, label: noScoreLabel },
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
]

const getAriaValueText = (value: number) => {
  return value >= 0? value.toString() : noScoreLabel
}

const ScoreSlider: FC<Props> = ({ value, onChange, ...props }) => {
  const classes = useStyles()
  const simplifiedOnChange = useCallback(
    (e: ChangeEvent<{}>, newValue: number | number[]) => {
      if (value !== newValue) {
        onChange(newValue as number)
      }
    },
    [onChange, value],
  )

  return (
    <div className={classes.root}>
      <Slider
        {...props}
        min={-1}
        max={9}
        step={1}
        marks={scale}
        value={value}
        defaultValue={-1}
        orientation="vertical"
        valueLabelDisplay="off"
        onChange={simplifiedOnChange}
        getAriaValueText={getAriaValueText}
      />
    </div>
  )
}

export default memo(ScoreSlider)
