import { FC, ComponentProps } from 'react'
import { AppBar as OriginalAppBar } from '@material-ui/core'

const AppBar: FC<ComponentProps<typeof OriginalAppBar>> = OriginalAppBar

AppBar.defaultProps = {
  elevation: 0,
  color: 'default',
  position: 'static',
}

export default AppBar
