import { initializeApp, getApp, getApps } from 'firebase/app'
import config from 'services/config'

const getDefaultFirebaseApp = () => {
  if (getApps().length) {
    return getApp()
  } else {
    return initializeApp(config.firebase)
  }
}

export default getDefaultFirebaseApp()
