import React, { FC } from 'react'
import { RpcError } from 'services/gamelogic'
import { FormattedMessage } from 'components'
import { User } from 'tackl-material/Icons'
import { Message } from 'tackl-material'

type Props = {
  error: RpcError
}

const RpcErrorMessage: FC<Props> = ({ error }) => (
  <Message
    icon={(
      <User
        color="secondary"
        fontSize="large"
      />
    )}
    headline={error.title || <FormattedMessage id="alert_unexpectederror_title" />}
    body={error.message || <FormattedMessage id="alert_unexpectederror_message" />}
  />
)

export default RpcErrorMessage
