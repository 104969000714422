import React from 'react'
import MuiBadge, { BadgeProps as MuiBadgeProps } from '@material-ui/core/Badge'

const NotificationBadge: React.FC<MuiBadgeProps> = (props) => (
  <MuiBadge
    max={10}
    showZero={false}
    variant="standard"
    {...props}
  />
)

export default NotificationBadge
