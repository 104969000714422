import React, { FC, memo } from 'react'
import { FormattedMessage } from 'components'

type Props = {
  current: number
  total: number
}

const Counter: FC<Props> = ({ current, total }) => {
  return total > 1
    ? (
      <FormattedMessage
        id="predictioneditor_matchcount_format"
        values={[current, total]}
      />
    ) : null
}

export default memo(Counter)
