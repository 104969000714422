import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Warning: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="22"
    height="22"
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      fill="inherit"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5895 1.034L21.7562 18.909C21.9114 19.2115 22 19.5628 22 19.9375C22 21.076 21.1787 22 20.1667 22H1.83333C0.821333 22 0 21.076 0 19.9375C0 19.5628 0.0892222 19.2115 0.243833 18.909L9.4105 1.034C9.72767 0.416625 10.3211 0 11 0C11.6789 0 12.2723 0.416625 12.5895 1.034ZM10.9982 2.0625L1.83211 19.9396L20.1667 19.9375L11.0012 2.06456L11 2.0625H10.9982ZM11 15.125C10.3253 15.125 9.77778 15.741 9.77778 16.5C9.77778 17.2597 10.3247 17.875 11 17.875C11.6753 17.875 12.2222 17.259 12.2222 16.5C12.2222 15.741 11.6753 15.125 11 15.125ZM9.79489 9.1685C9.78389 9.09356 9.77778 9.01587 9.77778 8.9375C9.77778 8.1785 10.3253 7.5625 11 7.5625C11.6753 7.5625 12.2222 8.1785 12.2222 8.9375C12.2222 9.01587 12.2161 9.09356 12.2045 9.16846L11.6026 13.178C11.5537 13.5032 11.3025 13.75 11 13.75C10.6975 13.75 10.4463 13.5032 10.3968 13.178L9.79489 9.1685Z"
    />
  </SvgIcon>
)

export default Warning
