import React, { FC, ComponentProps } from 'react'
import clsx from 'clsx'
import { makeStyles, alpha } from '@material-ui/core/styles'
import Button from './Button'

type ButtonProps = ComponentProps<typeof Button>

interface FacebookButtonProps extends Omit<ButtonProps, 'color'> {}

const facebookBlue = '#3B5998'
const useStyles = makeStyles(() => ({
  text: {
    color: facebookBlue,
  },
  outlined: {
    color: facebookBlue,
    borderColor: facebookBlue,
  },
  contained: {
    color: '#fff',
    backgroundColor: facebookBlue,
    '&:hover': {
      backgroundColor: alpha(facebookBlue, 0.85),
    },
  },
}))

const FacebookButton: FC<FacebookButtonProps> = ({
  variant,
  className,
  ...props
}) => {
  const classes = useStyles()

  return (
    <Button
      {...props}
      variant={variant}
      className={clsx(className, {
        [classes.text]: variant === 'text',
        [classes.outlined]: variant === 'outlined',
        [classes.contained]: variant === 'contained',
      })}
    />
  )
}

export default FacebookButton
