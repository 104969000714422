import React, { FC, ComponentProps, ChangeEvent } from 'react'
import { useCurrentRoute, useNavigation } from 'react-navi'
import Tabs from './Tabs'

type Props = Omit<ComponentProps<typeof Tabs>, 'value' | 'onChange' | 'tabIndex'> & {
  tabIndex?: number
}

const Nav: FC<Props> = (props) => <nav {...props} />

const NaviTabs: FC<Props> = (props) => {
  const { url } = useCurrentRoute()
  const navigation = useNavigation()

  const replace = (e: ChangeEvent<{}>, newPathname: string) => {
    navigation.navigate(newPathname, { replace: true })
  }

  return (
    <Tabs
      {...props}
      component={Nav}
      onChange={replace}
      value={url.pathname}
    />
  )
}

export default NaviTabs
