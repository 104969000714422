import React, { FC } from 'react'
import clsx from 'clsx'
import { BetOffer } from 'protos'
import { Typography } from 'tackl-material'
import { Grid, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

interface Props {
  homeColor: string,
  awayColor: string,
  className?: string,
  coloredPercents?: boolean,
  market1X2: BetOffer.IMarket1X2,
}

interface StyleProps {
  homeColor: string,
  awayColor: string,
  homePercent: number,
  drawPercent: number,
  awayPercent: number,
  coloredPercents?: boolean,
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  line: ({ homeColor, awayColor, homePercent, drawPercent }) => ({
    height: 8,
    borderRadius: 4,
    backgroundRepeat: 'no-repeat',
    boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.2)',
    WebkitBoxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.2)',
    background: `
      linear-gradient(
        to right,
        ${homeColor} ${homePercent}%,
        white ${homePercent}%,
        white ${homePercent + drawPercent}%,
        ${awayColor} ${homePercent + drawPercent}%
      )
    `,
  }),
  bold: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
  homePercent: ({ homeColor, coloredPercents }) => ({
    color: coloredPercents ? homeColor : 'inherit',
  }),
  awayPercent: ({ awayColor, coloredPercents }) => ({
    color: coloredPercents ? awayColor : 'inherit',
  }),
}))

const BetOfferPrediction: FC<Props> = ({
  className,
  homeColor,
  awayColor,
  market1X2,
  coloredPercents,
}) => {
  const homePercent = Number(market1X2.home && market1X2.home.probabilityPercent)
  const drawPercent = Number(market1X2.draw && market1X2.draw.probabilityPercent)
  const awayPercent = Number(market1X2.away && market1X2.away.probabilityPercent)
  const sumPercents = homePercent + drawPercent + awayPercent

  const classes = useStyles({
    coloredPercents,
    homeColor: `#${homeColor}`.replace('##', '#'),
    awayColor: `#${awayColor}`.replace('##', '#'),
    homePercent: homePercent / sumPercents * 100,
    drawPercent: drawPercent / sumPercents * 100,
    awayPercent: awayPercent / sumPercents * 100,
  })

  return (
    <Grid
      container
      alignItems="center"
      className={className}
      justify="space-between"
    >
      <Grid
        item
      >
        <Typography
          variant="body2"
          alpha={coloredPercents ? 1 : .5}
          className={clsx(classes.bold, classes.homePercent)}
        >
          {homePercent}%
        </Typography>
      </Grid>
      <Grid
        item
      >
        <Typography
          alpha={0.3}
          variant="subtitle2"
          className={classes.bold}
        >
          {drawPercent}%
        </Typography>
      </Grid>
      <Grid
        item
      >
        <Typography
          variant="body2"
          alpha={coloredPercents ? 1 : .5}
          className={clsx(classes.bold, classes.awayPercent)}
        >
          {awayPercent}%
        </Typography>
      </Grid>
      <Grid
        xs={12}
        item
      >
        <div className={classes.line} />
      </Grid>
    </Grid>
  )
}

export default BetOfferPrediction
