import React, { FC, ComponentProps } from 'react'
import { SearchField } from 'tackl-material'
import { useIntl } from 'hooks'

interface Props extends ComponentProps<typeof SearchField> {
}

const SearchFriends: FC<Props> = ({ ...props }) => {
  const { t } = useIntl()

  return (
    <SearchField
      name="search"
      placeholder={t('friends_search_placeholder')}
      {...props}
    />
  )
}

export default SearchFriends
