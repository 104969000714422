import React, { ComponentProps, FC } from 'react'
import clsx from 'clsx'
import { IBetSlip } from 'protos'
import { Checkmark } from 'tackl-material/Icons'
import { Paper, Typography } from 'tackl-material'
import { List, ListItemText, makeStyles, Theme, ListItem, ListItemIcon, ListItemSecondaryAction } from '@material-ui/core'

interface Props extends ComponentProps<typeof Paper> {
  bets: IBetSlip['bet']
}

const useStyles = makeStyles<Theme>(({ palette }) => ({
  root: {
    clipPath: 'polygon(0% 0%, 100% 0%, 100% calc(100% - 5px), calc(100% - 5px) 100%, 5px 100%, 0% calc(100% - 5px))',
    WebkitClipPath: 'polygon(0% 0%, 100% 0%, 100% calc(100% - 5px), calc(100% - 5px) 100%, 5px 100%, 0% calc(100% - 5px))',
    borderBottom: `1px solid ${palette.divider}`,
  },
  iconContainer: {
    color: palette.accent[50].main,
    minWidth: 36,
  },
}))

const BetSlipBets: FC<Props> = ({ bets, className, ...props }) => {
  const classes = useStyles()

  return (
    <Paper
      className={clsx(className, classes.root)}
      {...props}
    >
      <List dense>
        {bets && bets.map(bet => (
          <ListItem key={bet.title!}>
            <ListItemIcon className={classes.iconContainer}>
              <Checkmark
                color="inherit"
                fontSize="small"
              />
            </ListItemIcon>
            <ListItemText
              primary={bet.title}
              secondary={bet.subtitle}
              primaryTypographyProps={{ noWrap: true, display: 'block' }}
              secondaryTypographyProps={{ variant: 'subtitle2', noWrap: true, display: 'block' }}
            />
            <ListItemSecondaryAction>
              <Typography
                color="bet"
                variant="body2"
              >
                <strong>{bet.odds}</strong>
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Paper>
  )
}

export default BetSlipBets
