import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Close: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="14.469"
    height="14.538"
    viewBox="0 0 14.469 14.538"
    {...props}
  >
    <g
      fill="inherit"
      fillRule="evenodd"
    >
      <path d="M8.683 7.27l5.573-5.572a.722.722 0 00.006-1.012l-.48-.48a.714.714 0 00-1.01.007L6.792 6.19a.682.682 0 00-.107.087l-.48.48a.707.707 0 00-.2.6.71.71 0 00.2.425l.48.48c.034.034.07.063.11.09l5.975 5.975a.722.722 0 001.012.006l.48-.48a.714.714 0 00-.007-1.011z" />
      <path d="M5.785 7.27L.213 1.699A.722.722 0 01.206.686l.48-.48a.714.714 0 011.011.007L7.675 6.19a.682.682 0 01.108.087l.48.48c.162.163.23.386.2.6a.702.702 0 01-.2.425l-.48.48a.709.709 0 01-.11.09l-5.976 5.975a.722.722 0 01-1.01.006l-.48-.48a.714.714 0 01.006-1.011z" />
    </g>
  </SvgIcon>
)

export default Close
