import React, { FC, ComponentProps } from 'react'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Container as MuiContainer } from '@material-ui/core'

type Props = Omit<ComponentProps<typeof MuiContainer>, 'disableGutters'> & {
  disableGutters?: 'xs'
}

const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
  disableGuttersXs: {
    [breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}))

const Container: FC<Props> = ({ disableGutters, className, ...props }) => {
  const classes = useStyles()

  return (
    <MuiContainer
      {...props}
      className={clsx(className, {
        [classes.disableGuttersXs]: disableGutters === 'xs',
      })}
    />
  )
}

export default Container
