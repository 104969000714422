import { useContext, useEffect } from 'react'
import NotificationsContext from 'contexts/NotificationsContext'
import { INotification } from 'protos'

const useHandleNotifications = (notifications?: INotification[] | null) => {
  const context = useContext(NotificationsContext)
  const { addNotifications } = context

  useEffect(() => {
    addNotifications(notifications)
  }, [notifications, addNotifications])

  return {
    hasNotifications: context.notifications.length > 0,
  }
}

export default useHandleNotifications
