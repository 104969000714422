import React, { FC, ComponentProps } from 'react'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Currency } from 'components'
import { Paper } from 'tackl-material'
import config from 'services/config'
import useFormatAmount from 'hooks/useFormatAmount'

type Props = Omit<ComponentProps<typeof Paper>, 'variant'> & {
  unit?: string | null
  amount?: number | Long | null
  variant?: 'neutral' | 'positive' | 'negative'
  backgroundClassName?: string
}

const useStyles = makeStyles<Theme>(({ typography, palette, customComponents }) => ({
  root: {
    fontFamily: typography.fontFamily,
    color: palette.primary.main,
    width: '100%',
    display: 'inline-block',
    fontSize: 0,
    lineHeight: 1,
  },
  fixedSize: {
    width: 70,
    height: 143,
  },
  backgroundNegative: {
    fill: 'tomato',
  },
  backgroundNeutral: {
    fill: 'aqua',
  },
  backgroundPositive: {
    fill: 'green',
  },
  ...customComponents.CreditBillPortrait,
}))

const CreditBillPortrait: FC<Props> = ({ amount = 0, unit = config.units.credit, backgroundClassName, className, variant, ...props }) => {
  const classes = useStyles()
  const formatAmount = useFormatAmount()
  const displayedAmount = String(amount)

  const isNegative = (!variant && Number(amount) < 0) || variant === 'negative'
  const isNeutral = (!variant && Number(amount) === 0) || variant === 'neutral'
  const isPositive = (!variant && Number(amount) > 0) || variant === 'positive'

  return (
    <Paper
      {...props}
      className={clsx(className, classes.root, classes.fixedSize)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 70 143"
      >
        <g
          fill="none"
          fillRule="evenodd"
          transform="rotate(90 35.5 35.5)"
        >
          <path
            fill="#fff"
            d="M4 1h138v70H4V1z"
          />
          <path
            className={clsx(backgroundClassName, {
              [classes.backgroundNegative]: isNegative,
              [classes.backgroundNeutral]: isNeutral,
              [classes.backgroundPositive]: isPositive,
            })}
            d="M4 3h138v66H4V3z"
          />
          <path
            fill="#000"
            d="M100.35 71H142V1h-41.65z"
            opacity="0.1"
          />
          <path
            fill="#FFF"
            d="M.989 71H37.12V1H.989z"
          />
          <text
            fill="#000"
            fontSize={4}
            opacity={.5}
            fontWeight="bold"
            transform="rotate(-90 7.011 51.75)"
          >
            <tspan
              x={-9.5}
              y={51}
            >
              tackl
            </tspan>
            <tspan
              x={1}
              y={51}
              fontWeight="normal"
            >
              deine Freunde
            </tspan>
          </text>
          <path
            fill="#000"
            d="M142.033 26.289v39.672l-7.668-1.01a12.371 12.371 0 01-6.042-2.37l-19.181-14.294c-5.02-3.74-6.15-10.51-2.76-15.538a5.72 5.72 0 01.571-.848l16.198-20.057c.183-.227.383-.442.602-.643 1.416-1.905 3.736-3.148 6.359-3.148 4.312 0 7.808 3.359 7.808 7.5a7.23 7.23 0 01-.845 3.396l4.553 7.44.405-.1zm0-2.421l-1.773-5.257a6.079 6.079 0 011.591-.915 6.339 6.339 0 01.182-.068v6.24zM51.125 71H29.348l-7.178-8.887c-.975-1.208-.752-2.945.5-3.889l11.328-8.543 17.182 21.277-.056.042zm13.171-52.052a7.232 7.232 0 01-.844-3.395c0-4.141 3.495-7.5 7.807-7.5 2.623 0 4.944 1.243 6.36 3.148.218.201.418.416.6.643L94.42 31.9c.218.271.41.555.57.848 3.392 5.029 2.261 11.799-2.759 15.538L73.05 62.581a12.371 12.371 0 01-6.043 2.37l-9.432 1.243-3.995 2.975-17.182-21.275 3.996-2.978L51.606 20.89c1.267-2.983 4.81-4.414 7.915-3.195a6.099 6.099 0 011.591.915l-2.527 7.49 1.158.289 4.553-7.442z"
            opacity="0.1"
          ></path>
          <path d="M19.055 1H142v70H19.055z"></path>
          <path
            stroke="#000"
            strokeDasharray="1.5,1"
            strokeLinejoin="round"
            d="M100.851 1v70"
            opacity={.15}
          />
          <g fill="#000">
            <path d="M128.982 7.872a.933.933 0 01.751-.372c.51 0 .923.397.923.886a.854.854 0 01-.1.401l.538.88.137-.035-.299-.885a.718.718 0 01.188-.108.727.727 0 01.935.378l1.325 2.838.472.352-2.03 2.513-.472-.351-1.114-.147a1.462 1.462 0 01-.714-.28l-2.266-1.689a1.341 1.341 0 01-.326-1.835.676.676 0 01.067-.1l1.914-2.37a.691.691 0 01.071-.076zm-7.024.915a.854.854 0 01-.1-.401c0-.49.413-.886.922-.886.31 0 .584.147.752.372a.692.692 0 01.07.076l1.914 2.37a.675.675 0 01.068.1c.4.594.267 1.394-.326 1.835l-2.266 1.69a1.462 1.462 0 01-.714.28l-1.114.146-.472.351-2.03-2.513.472-.352 1.324-2.838a.727.727 0 01.935-.378.72.72 0 01.188.108l-.298.885.137.034.538-.879zm-4.977 5.1a.32.32 0 01.059-.46l1.338-1.009 2.03 2.514-1.317.993a.374.374 0 01-.513-.06l-1.597-1.978zm18.552 0l-1.597 1.978a.375.375 0 01-.513.06l-1.318-.993 2.03-2.514 1.339 1.01a.32.32 0 01.059.459z"></path>
            <text
              y={24}
              fontSize={22}
              textAnchor="end"
              fontWeight="bold"
              transform="rotate(-90 20.766 13.734), translate(-5 0)"
            >
              <tspan x="50%">
                <Currency
                  amount={<tspan fontWeight="bold">{formatAmount(displayedAmount)}</tspan>}
                  unit={<tspan fontWeight="normal">{unit}</tspan>}
                />
              </tspan>
            </text>
            <text
              fontSize={9}
              fontWeight="bold"
              textAnchor="end"
              transform="translate(65 5)"
            >
              <tspan
                y={44}
                x="100%"
              >
                <Currency amount={formatAmount(displayedAmount)} />
              </tspan>
              <tspan
                y={54}
                x="100%"
                fontSize={6}
              >
                tackl
              </tspan>
              <tspan
                y={61}
                x="100%"
                fontSize={6}
                fontWeight="normal"
              >
                credits
              </tspan>
            </text>
          </g>
        </g>
      </svg>
    </Paper >
  )
}

CreditBillPortrait.defaultProps = {
  elevation: 1,
}

export default CreditBillPortrait
