import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const GameStateDraw: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="20"
    height="18"
    viewBox="0 0 20 18"
    {...props}
  >
    <g
      fill="inherit"
      fillRule="evenodd"
    >
      <path d="M7.5 13.09H.833a.826.826 0 0 0-.833.82C0 16.167 1.865 18 4.167 18c2.3 0 4.166-1.832 4.166-4.09a.826.826 0 0 0-.833-.82zM19.167 13.09H12.5a.826.826 0 0 0-.833.82c0 2.258 1.865 4.09 4.166 4.09C18.135 18 20 16.168 20 13.91a.826.826 0 0 0-.833-.82zM14.667 4.582c.039.05.053.114.038.176l-1.68 6.6a.403.403 0 0 0 .075.352c.079.1.2.158.328.158h.859a.415.415 0 0 0 .404-.31l.943-3.706a.208.208 0 0 1 .202-.155c.096 0 .18.064.202.155l.945 3.706c.046.182.212.31.404.31h.858a.42.42 0 0 0 .329-.158c.079-.1.107-.229.075-.351l-1.684-6.605a.2.2 0 0 1 .038-.176c.039-.05.1-.078.164-.078h1.583c.46 0 .833-.366.833-.818a.826.826 0 0 0-.833-.818h-7.5a.413.413 0 0 1-.417-.41V.819A.826.826 0 0 0 10 0a.826.826 0 0 0-.833.818v1.637c0 .225-.187.409-.417.409h-7.5a.826.826 0 0 0-.833.818c0 .452.373.818.833.818h1.583a.206.206 0 0 1 .205.254l-1.684 6.6a.403.403 0 0 0 .076.352c.079.1.2.158.328.158h.859a.415.415 0 0 0 .404-.31l.943-3.706a.208.208 0 0 1 .202-.155c.096 0 .18.064.202.155l.944 3.706c.047.182.213.31.405.31h.858a.42.42 0 0 0 .329-.158c.079-.1.107-.229.075-.351L5.3 4.754a.2.2 0 0 1 .037-.176c.04-.05.1-.078.164-.078h3.25c.23 0 .417.183.417.41v.408c0 .452.373.818.833.818.46 0 .833-.366.833-.818V4.91c0-.226.187-.409.417-.409h3.25a.21.21 0 0 1 .167.082z" />
    </g>
  </SvgIcon>
)

export default GameStateDraw
