import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Comment: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="18"
    height="20"
    viewBox="0 0 18 20"
    {...props}
  >
    <path
      fill="inheit"
      fillRule="evenodd"
      d="M13.286 10C12.576 10 12 9.442 12 8.75s.577-1.25 1.286-1.25c.709 0 1.285.558 1.285 1.25S13.995 10 13.286 10zM9 10c-.709 0-1.286-.558-1.286-1.25S8.291 7.5 9 7.5c.709 0 1.286.558 1.286 1.25S9.709 10 9 10zm-4.286 0c-.709 0-1.285-.558-1.285-1.25S4.005 7.5 4.714 7.5C5.424 7.5 6 8.058 6 8.75S5.423 10 4.714 10zM9 0C4.037 0 0 3.925 0 8.75c0 4.683 3.807 8.525 8.571 8.742v2.091c0 .134.065.259.174.334A.409.409 0 0 0 9 20a.636.636 0 0 0 .125-.017C14.101 18.517 18 13.583 18 8.75 18 3.925 13.963 0 9 0z"
    />
  </SvgIcon>
)

export default Comment
