import React, { FC, ComponentProps } from 'react'
import { RpcError } from 'services/gamelogic'
import { FormattedMessage } from 'components'
import { MessageDrawer, Button } from 'tackl-material'

type Props = Pick<ComponentProps<typeof MessageDrawer>, 'open' | 'onClose'> & {
  error?: RpcError
  onRetry?: () => void
}

const RetriableErrorMessageDrawer: FC<Props> = ({ error, onClose, onRetry, ...props }) => (
  <MessageDrawer
    {...props}
    onClose={onClose}
    headline={error && error.title? error.title : <FormattedMessage id="alert_unexpectederror_title" />}
    body={error && error.message? error.message : <FormattedMessage id="alert_unexpectederror_message" />}
    actions={[
      <Button
        fullWidth
        key="dismiss"
        color="secondary"
        variant="contained"
        onClick={onClose}
      >
        <FormattedMessage id="alert_no" />
      </Button>,
      <Button
        fullWidth
        key="retry"
        color="primary"
        variant="contained"
        onClick={onRetry}
      >
        <FormattedMessage id="alert_retry" />
      </Button>,
    ]}
  />
)

export default RetriableErrorMessageDrawer
