import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Down: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="22"
    height="14"
    viewBox="0 0 22 14"
    {...props}
  >
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M12.599 11.888a.997.997 0 0 1-.185.254l-.655.656a1.014 1.014 0 0 1-.759.296c-.274.01-.55-.088-.759-.296l-.655-.656a.997.997 0 0 1-.185-.254L.83 3.316a1.036 1.036 0 0 1-.011-1.458l.655-.656a1.03 1.03 0 0 1 1.459.011L11 9.281l8.067-8.068a1.03 1.03 0 0 1 1.459-.01l.655.655a1.036 1.036 0 0 1-.01 1.458l-8.572 8.572z"
    />
  </SvgIcon>
)

export default Down
