import React, { FC } from 'react'
import clsx from 'clsx'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { MatchTimeline } from 'protos'
import { Box, Typography } from 'tackl-material'
import TimelineListItem from './TimelineListItem'

interface Props {
  event: MatchTimeline.ISubstitutionEvent
}

interface ArrowProps {
  side: 'left' | 'right'
  variant: 'in' | 'out'
}

interface SideComponentProps {
  side: 'left' | 'right'
  event: Props['event']
}

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  arrow: {
    width: 0,
    height: 0,
    display: 'inline-block',
    marginTop: 5,
    borderStyle: 'solid',
    '&$in': {
      borderWidth: `0 6px 8px 6px`,
      borderColor: `transparent transparent #78d01a transparent`,
    },
    '&$out': {
      borderWidth: `8px 6px 0 6px`,
      borderColor: `#ed0332 transparent transparent transparent`,
    },
    '&$left': {
      marginLeft: spacing(1),
      marginRight: spacing(2),
    },
    '&$right': {
      marginLeft: spacing(2),
      marginRight: spacing(1),
    },
  },
  in: {},
  out: {},
  left: {},
  right: {},
}))

const Arrow: FC<ArrowProps> = ({ variant, side }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.arrow, {
        [classes.in]: variant === 'in',
        [classes.out]: variant === 'out',
        [classes.left]: side === 'left',
        [classes.right]: side === 'right',
      })}
    />
  )
}

const SideComponent: FC<SideComponentProps> = ({ event, side }) => {
  return (
    <Box
      display="flex"
      minWidth={0}
      flexDirection="column"
    >
      <Box
        display="flex"
        flexDirection={side === 'right' ? 'row' : 'row-reverse'}
      >
        <Arrow
          side={side}
          variant="in"
        />
        <Typography noWrap>
          <strong>{event.playerIn}</strong>
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection={side === 'right' ? 'row' : 'row-reverse'}
      >
        <Arrow
          side={side}
          variant="out"
        />
        <Typography noWrap>
          {event.playerOut}
        </Typography>
      </Box>
    </Box>
  )
}

const SubstitutionListItem: FC<Props> = ({ event }) => {
  return (
    <TimelineListItem
      event={event}
      leftNode={(
        <SideComponent
          side="left"
          event={event}
        />
      )}
      rightNode={(
        <SideComponent
          side="right"
          event={event}
        />
      )}
    />
  )
}

export default SubstitutionListItem
