import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Back: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="12"
    height="20"
    viewBox="0 0 12 20"
    {...props}
  >
    <g
      fill="inherit"
      fillRule="inherit"
    >
      <path
        transform="rotate(90 6 10)"
        d="M6.00032277,12.146695 L13.6446691,4.28399827 C14.0215089,3.8963946 14.6338891,3.89783691 15.0080234,4.28265788 L15.7258372,5.02097504 C16.1019572,5.40783843 16.1068464,6.0300391 15.724534,6.42327168 L6.68986699,15.7160017 C6.54312027,15.8669401 6.36065541,15.9588822 6.17045046,15.9921627 C5.87001749,16.0504061 5.54766544,15.959655 5.31077855,15.7160017 L-3.72388848,6.42327168 C-4.10072823,6.03566802 -4.09932598,5.40579601 -3.72519165,5.02097504 L-3.00737787,4.28265788 C-2.63125784,3.89579449 -2.02633594,3.89076568 -1.64402359,4.28399827 L6.00032277,12.146695 Z"
      />
    </g>
  </SvgIcon>
)

export default Back
