import React, { FC } from 'react'
import { getRelativeTime } from 'lib'
import { useIntl } from 'hooks'

interface Props {
  date: Date | number | string
  now?: Date | number | string
}

const FormattedRelative: FC<Props> = ({ now, date }) => {
  const { language } = useIntl()

  return (
    <>
      {getRelativeTime(language, new Date(date), new Date(now || new Date()))}
    </>
  )
}

export default FormattedRelative
