import React, { FC, ComponentProps, useEffect } from 'react'
import { useNavigation, useCurrentRoute } from 'react-navi'
import { HistoryWithDepth } from 'lib/createBrowserHistoryWithDepth'
import { RpcError } from 'services/gamelogic'
import { GamesCreatePublicResponse, IMatch } from 'protos'
import { isUpcoming } from 'util/match'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Container, NavBar, CenteredBox } from 'tackl-material'
import {
  LoadingLayer,
  PredictionEditor,
  FormattedMessage,
  RpcErrorMessage,
  RpcErrorMessageDrawer,
  RetriableErrorMessageDrawer,
  InsufficientBalanceMessageDrawer,
} from 'components'

const { WARNING_STARTED_MATCHES, ERROR_INSUFFICIENT_BALANCE } = GamesCreatePublicResponse.Result

type Props = ComponentProps<typeof PredictionEditor> & {
  isLoading: boolean
  fetchError?: RpcError
  submitError?: RpcError
  parentPathname: string
  onRetrySave?: () => void
  roundName?: string | null
  competitionName?: string | null
  navBarTitleKey?: keyof Messages
  onDismissSubmitError?: () => void
}

const useStyles = makeStyles<Theme>(({ spacing, breakpoints }) => ({
  container: {
    overflowX: 'hidden',
    marginTop: spacing(3) * -1,
    [breakpoints.up('md')]: {
      minHeight: 720,
    },
    [breakpoints.up('sm')]: {
      overflow: 'initial',
    },
  },
  content: {
    paddingBottom: spacing(2),
    marginLeft: spacing(2) * -1,
    marginRight: spacing(2) * -1,

    [breakpoints.up('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}))

const isUpcomingMatch = ({ state }: IMatch) => isUpcoming(state)

const PredictScreen: FC<Props> = ({
  matches,
  roundName,
  isLoading,
  fetchError,
  submitError,
  onRetrySave,
  parentPathname,
  competitionName,
  onDismissSubmitError,
  navBarTitleKey = 'predictioneditor_title',
  ...props
}) => {
  const classes = useStyles()
  const { url } = useCurrentRoute()
  const { navigate, _history } = useNavigation()
  const { getDepth, goBack } = _history as HistoryWithDepth
  const filteredMatches = matches.filter(isUpcomingMatch)

  useEffect(() => {
    if (!isLoading && filteredMatches.length === 0) {
      if (getDepth() > 1) {
        goBack()
      } else {
        navigate({ ...url, pathname: parentPathname }, { replace: true })
      }
    }
  }, [isLoading, filteredMatches.length, navigate, url, parentPathname, getDepth, goBack])

  return (
    <>
      <NavBar
        title={<FormattedMessage id={navBarTitleKey} />}
        titleProps={{ variant: 'h4', gutterBottom: true }}
        subtitle={[competitionName, roundName].filter(v => !!v).join(' - ')}
      />
      <Container
        maxWidth="sm"
        className={classes.container}
      >
        <section className={classes.content}>
          {
            fetchError
              ? (
                <CenteredBox p={4}>
                  <RpcErrorMessage error={fetchError} />
                </CenteredBox>
              ) : (
                filteredMatches.length
                  ? (
                    <PredictionEditor
                      matches={filteredMatches}
                      {...props}
                    />
                  ) : null
              )
          }
        </section>
      </Container>

      <LoadingLayer open={isLoading} />
      <InsufficientBalanceMessageDrawer
        onClose={onDismissSubmitError}
        open={submitError && submitError.result === ERROR_INSUFFICIENT_BALANCE}
      />
      <RetriableErrorMessageDrawer
        error={submitError}
        onClose={onDismissSubmitError}
        onRetry={onRetrySave}
        open={submitError && submitError.result === WARNING_STARTED_MATCHES}
      />
      <RpcErrorMessageDrawer
        error={submitError}
        onClose={onDismissSubmitError}
        open={submitError && submitError.result !== WARNING_STARTED_MATCHES && submitError.result !== ERROR_INSUFFICIENT_BALANCE}
      />
    </>
  )
}

export default PredictScreen
