import { getAnalytics, logEvent } from 'firebase/analytics'
import config from 'services/config'
import firebaseApp from 'services/firebase'

type LogEventParams = Parameters<typeof logEvent>

export interface AnalyticsService {
  logEvent: (eventName: LogEventParams[1], eventParams?: LogEventParams[2]) => void
}

let analyticsService: AnalyticsService = {
  logEvent: (...args: any[]) => console.log('[analytics] logEvent', ...args),
}

try {
  const analytics = getAnalytics(firebaseApp)
  analyticsService = { logEvent: (...args) => logEvent(analytics, ...args) }
} catch {
  // no-op
}

// NOTE: Disable to send automatic page_view events on initial page load
if ((window as any).gtag) {
  (window as any).gtag('config', config.firebase.measurementId, {
    'send_page_view': false,
  })
}

export default analyticsService
