import { useState, useEffect, useCallback } from 'react'
import { Theme } from '@material-ui/core'
import config from 'services/config'

const useThemeLoader = () => {
  const [state, setState] = useState<{ error?: Error, theme?: Theme }>({})

  const importTheme = useCallback(async () => {
    const importPath = {
      tackl: () => import('themes/base'),
      tippstar: () => import('themes/tippstar'),
      bild: () => import('themes/bild'),
    }[config.tenantId]

    try {
      const res = await importPath()

      setState({ theme: res.default })
    } catch (error) {
      setState({ error: error as Error })
    }
  }, [])

  useEffect(() => {
    importTheme()
  }, [importTheme])

  return state
}

export default useThemeLoader
