import { Game } from 'protos'
import { ListItemType } from './propTypes'

const createListItemsHeight = (item: ListItemType) => {
  if (typeof item === 'string') {
    return 25 + 16 // Start date height + margin
  }

  if (item.gameType === Game.GameType.GT_LOTTERY) {
    return 115 + 16 // LotteryMatchCard + margin
  }

  return 90 + 16 // MatchCard + margin
}

export default createListItemsHeight
