import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const ThumbsUp: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="47"
    height="45"
    viewBox="0 0 47 45"
    {...props}
  >
    <path d="M11 20H1c-.552 0-1 .43-1 .962v23.076C0 44.57.448 45 1 45h10c.552 0 1-.43 1-.962V20.962c0-.531-.448-.962-1-.962zM47 20.523c0-2.694-2.178-4.887-4.853-4.887h-12.22c.592-1.815 1.544-5.144 1.544-7.818C31.47 3.578 27.893 0 25.647 0c-2.017 0-3.457 1.141-3.52 1.19a.987.987 0 0 0-.362.765V8.58l-5.59 12.199-1.637.823a.983.983 0 0 0-.538.875v17.591c0 .26.103.508.283.692C15.737 42.222 19 43 20.794 43H38.75c2.409 0 4.368-1.972 4.368-4.398 0-.69-.158-1.354-.457-1.954a4.382 4.382 0 0 0 2.398-3.91c0-.69-.157-1.354-.456-1.954A4.382 4.382 0 0 0 47 26.875c0-1.173-.464-2.27-1.262-3.078A4.84 4.84 0 0 0 47 20.523z" />
  </SvgIcon>
)

export default ThumbsUp
