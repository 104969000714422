import { FC, ComponentProps as CP } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Users: FC<CP<typeof SvgIcon>> = props => (
  <SvgIcon
    width="49"
    height="42"
    viewBox="0 0 49 42"
    {...props}
  >
    <path d="M44.9462 31.1284C42.7833 29.6452 40.4665 28.4 38.0362 27.4144C37.3385 27.1685 36.8403 26.5486 36.7502 25.8144V24.7444C36.7507 24.6095 36.8054 24.4805 36.9021 24.3864C36.9988 24.2924 37.1293 24.2412 37.2642 24.2444C37.5722 24.2544 37.8642 24.2584 38.1622 24.2584C41.4893 24.441 44.7779 23.4735 47.4762 21.5184C47.7105 21.3306 47.852 21.0505 47.8642 20.7504C47.876 20.4413 47.7586 20.1413 47.5402 19.9224C46.2182 18.6024 45.4222 17.8064 45.0722 12.6504C44.4602 3.60039 38.1882 0.400391 33.0002 0.400391C31.8512 0.401752 30.7076 0.55782 29.6002 0.864391C29.4529 0.906249 29.3327 1.01307 29.2739 1.15447C29.2151 1.29588 29.224 1.4564 29.2982 1.59039C29.5561 2.04579 29.7525 2.53337 29.8822 3.04039C30.3226 5.24475 30.2106 7.52383 29.5562 9.67439C29.5244 9.78978 29.5358 9.91279 29.5882 10.0204C30.0265 10.9522 30.2494 11.9707 30.2402 13.0004C30.2671 14.4633 29.8038 15.8932 28.9242 17.0624C28.8713 17.129 28.8368 17.2083 28.8242 17.2924C28.5436 19.2974 27.679 21.1755 26.3382 22.6924C26.2283 22.8139 26.1849 22.9815 26.222 23.141C26.2591 23.3006 26.372 23.4318 26.5242 23.4924L32.6822 25.9324C36.5043 27.4309 39.0136 31.123 39.0002 35.2284C39.0002 41.2604 39.0262 41.0184 38.9702 41.4284C38.9518 41.5715 38.9959 41.7157 39.0912 41.824C39.1865 41.9323 39.3239 41.9944 39.4682 41.9944H47.5002C47.7763 41.9944 48.0002 41.7705 48.0002 41.4944V37.9644C48.2015 35.3163 47.0529 32.7454 44.9462 31.1284Z" />
    <path d="M35 42C35.5523 42 36 41.5523 36 41V35.228C36.0098 32.3536 34.2537 29.7682 31.578 28.718L23.316 25.446C23.126 25.3702 23.001 25.1866 23 24.982V21.88C22.9995 21.7159 23.0804 21.5623 23.216 21.47C24.9354 20.1667 25.9504 18.1375 25.962 15.98C25.9736 15.8362 26.0465 15.7043 26.162 15.618C26.9107 14.9617 27.3095 13.9932 27.24 13C27.2854 12.1236 26.9901 11.2637 26.416 10.6C26.2875 10.4678 26.2422 10.2757 26.298 10.1C27.0717 8.0788 27.3034 5.8904 26.97 3.752C26.278 0.984 22.512 0 19.5 0C16.916 0 13.78 0.724 12.5 2.682C12.4086 2.82093 12.2543 2.90556 12.088 2.908C11.2851 2.88222 10.5087 3.19672 9.95004 3.774C8.52404 5.312 9.23604 8.28 9.75004 10.096C9.79755 10.2708 9.74797 10.4577 9.62004 10.586C9.45536 10.7506 9.31586 10.9387 9.20604 11.144C8.9053 11.7159 8.752 12.3539 8.76004 13C8.69059 13.9932 9.0894 14.9617 9.83804 15.618C9.95363 15.7043 10.0265 15.8362 10.038 15.98C10.0497 18.1375 11.0647 20.1667 12.784 21.47C12.9189 21.563 12.9995 21.7162 13 21.88V24.984C13.0002 25.189 12.8748 25.3731 12.684 25.448L4.41204 28.74C1.74165 29.7917 -0.0100256 32.374 4.31783e-05 35.244V41C4.31783e-05 41.5523 0.447758 42 1.00004 42H35Z" />
  </SvgIcon>
)

export default Users
