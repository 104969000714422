import React, { FC, ComponentProps, ComponentType, memo } from 'react'
import { ActionDrawer } from 'components'
import SkipButton from './SkipButton'
import SaveButton from './SaveButton'
import FinishButton from './FinishButton'

type Props = {
  onClick: () => void
  step: 'skipMatch' | 'saveMatch' | 'finish'
  skipButtonComponent?: ComponentType<ComponentProps<typeof SkipButton>>
  saveButtonComponent?: ComponentType<ComponentProps<typeof SaveButton>>
  finishButtonComponent?: ComponentType<ComponentProps<typeof FinishButton>>
}

const Actions: FC<Props> = ({
  step,
  onClick,
  skipButtonComponent: SkipButtonComponent = SkipButton,
  saveButtonComponent: SaveButtonComponent = SaveButton,
  finishButtonComponent: FinishButtonComponent = FinishButton,
}) => {
  if (step === 'skipMatch') {
    return (
      <ActionDrawer
        action={(
          <SkipButtonComponent
            key="actionButton"
            onClick={onClick}
          />
        )}
      />
    )
  }

  if (step === 'saveMatch') {
    return (
      <ActionDrawer
        action={(
          <SaveButtonComponent
            key="actionButton"
            onClick={onClick}
          />
        )}
      />
    )
  }

  return (
    <ActionDrawer
      action={(
        <FinishButtonComponent
          key="actionButton"
          onClick={onClick}
        />
      )}
    />
  )
}

export default memo(Actions)
