import React, { FC, ReactNode, ComponentProps } from 'react'
import { makeStyles, Theme, Grid } from '@material-ui/core'
import { CenteredBox, Drawer } from 'tackl-material'

type Props = ComponentProps<typeof Drawer> & {
  action: ReactNode
}

const buttonSize = 100

const useStyles = makeStyles<Theme>(({ breakpoints, spacing }) => ({
  root: {
    marginTop: spacing(1) + 77,
    [breakpoints.up('md')]: {
      marginTop: spacing(9) + 77,
    },
  },
  drawerPaper: {
    border: 0,
    height: 77,
    overflow: 'visible',
    pointerEvents: 'none',
    backgroundColor: 'transparent',
    [breakpoints.up('md')]: {
      margin: 'auto',
      bottom: spacing(8),
    },
  },
  middleCol: {
    height: '100%',
    pointerEvents: 'auto',
    marginTop: spacing(1) * -1,
  },
  button: {
    width: buttonSize,
    height: buttonSize,
  },
}))

const ActionDrawer: FC<Props> = ({ action, ...props }) => {
  const classes = useStyles()

  return (
    <Drawer
      anchor="bottom"
      variant="permanent"
      {...props}
      classes={{
        paper: classes.drawerPaper,
      }}
      className={classes.root}
    >
      <Grid
        container
        spacing={0}
      >
        <Grid
          item
          xs={4}
        >
        </Grid>
        <Grid
          item
          xs={4}
          className={classes.middleCol}
        >
          <CenteredBox>
            {action}
          </CenteredBox>
        </Grid>
        <Grid
          item
          xs={4}
        >
        </Grid>
      </Grid>
    </Drawer>
  )
}

export default ActionDrawer
