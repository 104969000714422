import { useReducer, Reducer, useCallback } from 'react'
import { RpcError } from 'services/gamelogic'
import { useIntl } from 'hooks'

interface State<Res> {
  error?: RpcError
  response?: Res
  isLoading: boolean
  isSubmitted: boolean
}

type Action<Res> =
  | { type: 'reset' }
  | { type: 'submit' }
  | { type: 'reject', error: Error }
  | { type: 'resolve', response: Res }
  | { type: 'dismissError' }

const initialState = {
  isLoading: false,
  isSubmitted: false,
}

const reducer = <Res>(state: State<Res>, action: Action<Res>): State<Res> => {
  switch (action.type) {
    case 'reset':
      return { ...initialState }
    case 'submit':
      return { ...state, isLoading: true }
    case 'reject':
      return { ...state, isLoading: false, response: undefined, error: action.error, isSubmitted: true }
    case 'resolve':
      return { ...state, isLoading: false, response: action.response, error: undefined, isSubmitted: true }
    case 'dismissError':
      return { ...state, error: undefined }
  }
}

const useSubmit = <Req = {}, Res = {}>(fn: (request?: Req, language?: string) => Promise<Res>) => {
  const { language } = useIntl()
  const [ state, dispatch ] = useReducer<Reducer<State<Res>, Action<Res>>>(reducer, initialState)

  const submit = useCallback(async (request?: Req, callback?: (res?: Res, error?: RpcError) => void) => {
    try {
      dispatch({ type: 'submit' })
      const response = await fn(request, language)
      dispatch({ type: 'resolve', response })
      callback && callback(response)
    } catch (error) {
      dispatch({ type: 'reject', error: error as Error })
      callback && callback(undefined, error as Error)
    }

    // WORKAROUND: ESLint want Res and Req listed as React
    // hook dependencies, even though they are just types
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fn, language])

  const dismissError = useCallback(() => {
    dispatch({ type: 'dismissError' })
  }, [])

  const reset = useCallback(() => {
    dispatch({ type: 'reset' })
  }, [])

  return { ...state, submit, reset, dismissError }
}

export default useSubmit
