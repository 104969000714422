import React, { FC, ComponentProps } from 'react'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'

import { useIntl } from 'hooks'
import { IGame, Game } from 'protos'
import { Typography } from 'tackl-material'
import { FormattedMessage, FormattedDate } from 'components'

interface Props extends Omit<ComponentProps<typeof Typography>, 'display'> {
  display: IGame['timeDisplay']
  nextTime: IGame['nextTime']
  startTime: IGame['startTime']
  timeOther: IGame['timeOther']
  finishTime: IGame['finishTime']
}

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold',
  },
})

const TimeContainer: FC<ComponentProps<typeof Typography>> = ({ ...props }) => (
  <Typography
    alpha={.5}
    {...props}
    variant="h6"
    noWrap
  />
)

const GameCardHeaderTime: FC<Props> = ({
  display,
  nextTime,
  startTime,
  timeOther,
  finishTime,
  ...props
}) => {
  const { d } = useIntl()
  const classes = useStyles()

  switch (display) {
    case Game.TimeDisplay.TD_LIVE:
      return (
        <TimeContainer
          alpha={1}
          color="accent-100"
          {...props}
          className={clsx(props.className, classes.bold)}
        >
          <FormattedMessage id="game_time_live" />
        </TimeContainer>
      )
    case Game.TimeDisplay.TD_OTHER:
      return (
        <TimeContainer {...props}>
          {timeOther}
        </TimeContainer>
      )
    case Game.TimeDisplay.TD_SHOW_FINISH:
      return (
        <TimeContainer {...props}>
          <FormattedDate timestamp={Number(finishTime) * 1000} />
        </TimeContainer>
      )
    case Game.TimeDisplay.TD_SHOW_NEXT:
      return (
        <TimeContainer {...props}>
          <FormattedMessage
            id="game_time_next_format"
            values={[d(Number(nextTime) * 1000)]}
          />
        </TimeContainer>
      )
    case Game.TimeDisplay.TD_SHOW_START:
      return (
        <TimeContainer {...props}>
          <FormattedDate timestamp={Number(startTime) * 1000} />
        </TimeContainer>
      )
    case Game.TimeDisplay.TD_NONE:
    default:
      return (
        <TimeContainer {...props} />
      )
  }
}

export default GameCardHeaderTime
