import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Ticket: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="50"
    height="34"
    viewBox="0 0 50 34"
    {...props}
  >
    <g
      fill="inherit"
      fillRule="evenodd"
    >
      <rect
        opacity="0.2"
        x="2"
        y="28"
        width="46"
        height="4"
      />
      <path
        d="M49,10 C49.3353093,10.0091646 49.6695157,10.0425853 50,10.1 L50,3 C50,1.34314575 48.6568542,3.55271368e-15 47,0 L3,0 C1.34314575,0 9.74733172e-16,1.34314575 7.71827047e-16,3 L7.71827047e-16,10.1 C0.330484316,10.0425853 0.664690676,10.0091646 1,10 C4.86599319,10.0000001 7.99999985,13.1340068 7.99999985,17 C7.99999985,20.8659932 4.86599319,23.9999999 1,24 C0.664690676,23.9908354 0.330484316,23.9574147 0,23.9 L0,31 C2.0313219e-16,31.7956495 0.316070521,32.5587112 0.878679656,33.1213203 C1.44128879,33.6839295 2.20435053,34 3,34 L47,34 C48.6568542,34 50,32.6568542 50,31 L50,23.9 C47.4093784,24.2779715 44.8248496,23.1758728 43.3041599,21.0447497 C41.7834702,18.9136266 41.5818547,16.1111702 42.7818266,13.784316 C43.9817984,11.4574619 46.3819526,9.9967529 49,10 Z M3,32 C2.44798937,31.9993388 2.00066122,31.5520106 2,31 L2,25.9448 C6.55728809,25.4377221 10.0047347,21.5854119 10.0047347,17 C10.0047347,12.4145881 6.55728809,8.56227792 2,8.0552 L2,3 C2.00066122,2.44798937 2.44798937,2.00066122 3,2 L16,2 L16,32 L3,32 Z M40,17 C40.00582,21.5814661 43.4475793,25.4296971 48,25.9448 L48,31 C47.9993388,31.5520106 47.5520106,31.9993388 47,32 L18,32 L18,2 L47,2 C47.5520106,2.00066122 47.9993388,2.44798937 48,3 L48,8.0552 C43.4475793,8.57030286 40.00582,12.4185339 40,17 Z"
        fillRule="nonzero"
      />
      <path d="M23,12 L29,12 C29.5522847,12 30,11.5522847 30,11 C30,10.4477153 29.5522847,10 29,10 L23,10 C22.4477153,10 22,10.4477153 22,11 C22,11.5522847 22.4477153,12 23,12 Z" />
      <path d="M23,18 L29,18 C29.5522847,18 30,17.5522847 30,17 C30,16.4477153 29.5522847,16 29,16 L23,16 C22.4477153,16 22,16.4477153 22,17 C22,17.5522847 22.4477153,18 23,18 Z" />
      <path d="M35,22 L23,22 C22.4477153,22 22,22.4477153 22,23 C22,23.5522847 22.4477153,24 23,24 L35,24 C35.5522847,24 36,23.5522847 36,23 C36,22.4477153 35.5522847,22 35,22 Z" />
    </g>
  </SvgIcon>
)

export default Ticket
