import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Keyboard: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="52"
    height="41"
    viewBox="0 0 52 41"
    {...props}
  >
    <path
      d="M51 14.006H27v-3.5c0-1.4 1.1-2.5 2.5-2.5 2.5 0 4.5-2 4.5-4.5v-2.5c0-1.342-2-1.34-2 0v2.5c0 1.4-1.1 2.5-2.5 2.5-2.5 0-4.5 2-4.5 4.5v3.5H1c-.6 0-1 .4-1 1v24c0 .6.4 1 1 1h50c.6 0 1-.4 1-1v-24c0-.6-.4-1-1-1zm-1 24H2v-22h48v22zm-9-4H11v-2h30v2zm-32 0H5v-2h4v2zm0-4H5v-2h4v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm6 4h-4v-2h4v2zm0-4h-4v-2h4v2zm-38-4H5v-2h4v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm6 0h-4v-2h4v2zm-38-4H5v-2h4v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm6 0h-4v-2h4v2z"
    />
  </SvgIcon>
)

export default Keyboard
