import React, { FC } from 'react'
import { BetOffer } from 'protos'
import { Grid } from '@material-ui/core'
import { Typography } from 'tackl-material'
import FormattedMessage from '../FormattedMessage'
import BetOfferSponsorLogo from './BetOfferSponsorLogo'
import { useFontSize } from './util'

interface Props {
  sponsor: BetOffer.ISponsor
  variant?: 'light' | 'dark'
  className?: string
}

const BetOfferSponsor: FC<Props> = ({ className, ...props }) => {
  const fontSize = useFontSize()

  return (
    <Grid
      container
      spacing={1}
      wrap="nowrap"
      justify="center"
      alignItems="center"
      className={className}
    >
      <Grid item>
        <Typography
          alpha={0.5}
          variant="body2"
          component="span"
          className={fontSize.small}
        >
          <FormattedMessage id="match_odds_sponsor" />
        </Typography>
      </Grid>
      <Grid item>
        <BetOfferSponsorLogo {...props} />
      </Grid>
    </Grid>
  )
}

export default BetOfferSponsor
