import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const PushNotifications: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="44"
    height="42"
    viewBox="0 0 44 42"
    {...props}
  >
    <g fill="inherit">
      <path
        opacity="0.2"
        d="M30,28.4278 C30.3386,28.5742 42,37.0314 42,37.0314 L42,33.0314 C42,33.0314 30.3386,24.5744 30,24.4278 L30,28.4278 Z"
      />
      <path
        opacity="0.2"
        d="M10,24.004 C6.36223278,23.9931071 3.19076685,21.5269594 2.284,18.004 C2.10422396,18.6557846 2.00877877,19.3279338 2,20.004 C2.00495988,24.4202219 5.58377808,27.9990401 10,28.004 L28,28.004 L28,24.004 L10,24.004 L10,24.004 Z"
      />
      <path
        d="M42.997,0.004 C42.8003731,0.00432021341 42.6083137,0.0633029216 42.4454,0.1734 L29.7908,9.4258 C29.2769534,9.8016242 28.6568178,10.0041332 28.0202,10.004 L10,10.004 C6.42734407,10.0039999 3.12607382,11.9099892 1.33974584,15.004 C-0.446582145,18.0980107 -0.446582145,21.9099893 1.33974584,25.004 C3.12607382,28.0980108 6.42734407,30.0040001 10,30.004 L12,30.004 L12,39.004 C11.9985869,39.9032859 12.4011777,40.755651 13.0966327,41.3257908 C13.7920877,41.8959306 14.7068539,42.1235455 15.5884,41.9458 L19.2172,41.2202 C20.7785266,40.9078959 21.8238892,39.4302358 21.5986,37.854 L20.4774,30.0048 L28.02,30.0048 C28.6563691,30.0050774 29.2761653,30.2077072 29.7898,30.5834 L42.4442,39.8354 C42.6072067,39.9456857 42.7993908,40.0048729 42.9962,40.0054 C43.2624972,40.006092 43.5180984,39.9006669 43.7064747,39.7124409 C43.8948509,39.5242148 44.0004797,39.2686976 44,39.0024 L44,1.0054 C44.0000003,0.739534757 43.8942764,0.484581974 43.7061313,0.296736983 C43.5179861,0.108891992 43.2628649,0.00357521032 42.997,0.004 Z M19.6188,38.1358 C19.6935747,38.6612497 19.3453239,39.1537495 18.825,39.2584 L15.1962,39.9834 C15.1295597,39.9967368 15.0617617,40.0034362 14.9938,40.0034 C14.443938,39.9999908 13.9999894,39.5532725 14,39.0034 L14,30.0034 L18.457,30.0034 L19.6188,38.1358 Z M20.1916,28.004 L10,28.004 C5.581722,28.004 2,24.422278 2,20.004 C2,15.585722 5.581722,12.004 10,12.004 L24,12.004 L24,19.004 C24,19.5562847 24.4477153,20.004 25,20.004 C25.5522847,20.004 26,19.5562847 26,19.004 L26,12.004 L28,12.004 L28,28.004 L20.1916,28.004 Z M42,37.0312 C42,37.0312 30.3386,28.5742 30,28.4276 L30,11.58 C30.3386,11.433 42,2.974 42,2.974 L42,37.0312 Z"
        fillRule="nonzero"
      />
    </g>
  </SvgIcon>
)

export default PushNotifications
