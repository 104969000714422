import React, { FC } from 'react'
import beerImg from './assets/beer.svg'
import { ImageProps } from './propTypes'

const Beer: FC<ImageProps> = props => (
  <img
    alt="beer"
    {...props}
    src={beerImg}
  />
)

export default Beer
