import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const AddFriend: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="19"
    height="24"
    viewBox="0 0 19 24"
    {...props}
  >
    <g
      fill="inherit"
      fillRule="evenodd"
    >
      <path d="M18.99 14.236l-.663-5.808C18.17 7.044 17.073 6 15.777 6h-2.553c-1.296 0-2.392 1.044-2.55 2.428l-.663 5.808c-.054.486.095.968.412 1.323.272.305.634.483 1.027.505l.552 7.472A.5.5 0 0 0 12.5 24h4a.502.502 0 0 0 .499-.464l.552-7.472c.392-.022.754-.2 1.027-.505a1.7 1.7 0 0 0 .41-1.323M0 3h8v2H0z" />
      <path d="M3 0h2v8H3zM14.5 5C15.88 5 17 3.878 17 2.5S15.88 0 14.5 0A2.503 2.503 0 0 0 12 2.5C12 3.878 13.123 5 14.5 5" />
    </g>
  </SvgIcon>
)

export default AddFriend
