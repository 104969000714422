import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const CloseDown: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <g
      fill="inherit"
      fillRule="evenodd"
    >
      <path
        fill="#FFF"
        strokeWidth={2}
        strokeOpacity={0.1}
        stroke="currentColor"
        transform="rotate(-180 21 21)"
        d="M26 41c8.284 0 15-6.716 15-15s-6.716-15-15-15-15 6.716-15 15 6.716 15 15 15z"
      />
      <path
        fill="inherit"
        transform="rotate(-180 11.5 11)"
        d="M7.0009,3.49768 L12.3654,8.86219 C12.63567,9.13246 13.07619,9.13013 13.35041,8.85591 L13.77625,8.43007 C14.04999,8.15633 14.04585,7.70838 13.78253,7.44506 L8.04471,1.70724 C8.01538,1.65834 7.97959,1.61217 7.93736,1.56993 L7.51152,1.14409 C7.39,1.02257 7.23416,0.95582 7.07537,0.94218 C6.86836,0.9162 6.65082,0.98355 6.49028,1.14409 L6.06443,1.56993 C6.02286,1.6115 5.9877,1.65709 5.95888,1.70544 L0.219265,7.44506 C-0.051005,7.71533 -0.048675,8.15585 0.225541,8.43007 L0.651385,8.85591 C0.925121,9.12965 1.37307,9.12551 1.63639,8.86219 L7.0009,3.49768 Z"
      />
    </g>
  </SvgIcon>
)

export default CloseDown
