import React, { FC, ReactNode } from 'react'
import { Markdown } from 'components'

type Props = {
  unit?: ReactNode
  amount?: ReactNode
  custom?: string | null
}

const Currency: FC<Props> = ({ unit, amount, custom }) => {
  if (custom) {
    return <Markdown>{custom}</Markdown>
  }

  return (
    <>
      {amount}{unit}
    </>
  )
}

Currency.defaultProps = {
  amount: 0,
  unit: '',
}

export default Currency
