import {
  UsersGetProfileRequest,
  UsersGetProfileResponse,
  IUsersGetProfileRequest,
  GamesGetActiveResponse,
  IGamesGetActiveRequest,
  GamesGetActiveRequest,
  GamesGetTemplatesRequest,
  IGamesGetTemplatesRequest,
  GamesGetTemplatesResponse,
  GamesGetPublicInputsRequest,
  IGamesGetPublicInputsRequest,
  GamesGetPublicInputsResponse,
  GamesGetPrivateInputsRequest,
  IGamesGetPrivateInputsRequest,
  GamesGetPrivateInputsResponse,
  GamesCreatePublicRequest,
  IGamesCreatePublicRequest,
  GamesCreatePublicResponse,
  GamesCreatePrivateRequest,
  IGamesCreatePrivateRequest,
  GamesCreatePrivateResponse,
  GamesGetDetailsRequest,
  IGamesGetDetailsRequest,
  GamesGetDetailsResponse,
  GamesEditPredictionsRequest,
  IGamesEditPredictionsRequest,
  GamesEditPredictionsResponse,
  ISessionsLoginRequest,
  SessionsLoginResponse,
  SessionsLoginRequest,
  IUsersUpdateProfileRequest,
  UsersUpdateProfileResponse,
  UsersUpdateProfileRequest,
  UsersConfirmNotificationRequest,
  UsersConfirmNotificationResponse,
  IUsersConfirmNotificationRequest,
  IMatchesGetAllTeamsRequest,
  MatchesGetAllTeamsResponse,
  MatchesGetAllTeamsRequest,
  UsersGetFriendsRequest,
  UsersGetFriendsResponse,
  IUsersGetFriendsRequest,
  IGamesGetHistoryRequest,
  GamesGetHistoryResponse,
  GamesGetHistoryRequest,
  GamesDeclineInvitationRequest,
  IGamesDeclineInvitationRequest,
  GamesDeclineInvitationResponse,
  GamesAcceptInvitationRequest,
  IGamesAcceptInvitationRequest,
  GamesAcceptInvitationResponse,
  GamesCancelPrivateRequest,
  IGamesCancelPrivateRequest,
  GamesCancelPrivateResponse,
  IUsersGetStatusRequest,
  UsersGetStatusResponse,
  UsersGetStatusRequest,
  IUsersSearchRequest,
  UsersSearchResponse,
  UsersSearchRequest,
  UsersGetLeaderboardRequest,
  UsersGetLeaderboardResponse,
  IUsersGetLeaderboardRequest,
  UsersAddFriendResponse,
  IUsersAddFriendRequest,
  UsersAddFriendRequest,
  IUsersRemoveFriendRequest,
  UsersRemoveFriendResponse,
  UsersRemoveFriendRequest,
  IMatchesGetTimelineRequest,
  MatchesGetTimelineRequest,
  MatchesGetTimelineResponse,
  IUsersClaimBonusRequest,
  UsersClaimBonusResponse,
  UsersClaimBonusRequest,
  IUsersGetNotificationsRequest,
  UsersGetNotificationsResponse,
  UsersGetNotificationsRequest,
  IUsersSetNotificationRequest,
  UsersSetNotificationResponse,
  UsersSetNotificationRequest,
  IGamesGetMissingPredictionsRequest,
  GamesGetMissingPredictionsRequest,
  GamesGetMissingPredictionsResponse,
  IUsersGetFacebookFriendsRequest,
  UsersGetFacebookFriendsRequest,
  UsersGetFacebookFriendsResponse,
  IUsersAddFacebookFriendRequest,
  UsersAddFacebookFriendRequest,
  UsersAddFacebookFriendResponse,
  IGroupsGetPrivateInputsRequest,
  GroupsGetPrivateInputsRequest,
  GroupsGetPrivateInputsResponse,
  IGamesSendMessageRequest,
  GamesSendMessageRequest,
  GamesSendMessageResponse,
  IGroupsGetActiveRequest,
  GroupsGetActiveResponse,
  GroupsGetActiveRequest,
  IGroupsGetHistoryRequest,
  GroupsGetHistoryResponse,
  GroupsGetHistoryRequest,
  IGroupsCreatePrivateRequest,
  GroupsCreatePrivateRequest,
  GroupsCreatePrivateResponse,
  IGamesInviteFriendsRequest,
  GamesInviteFriendsResponse,
  GamesInviteFriendsRequest,
  IGamesGetInvitableFriendsRequest,
  GamesGetInvitableFriendsResponse,
  GamesGetInvitableFriendsRequest,
  IGamesMuteNotificationsRequest,
  GamesMuteNotificationsRequest,
  GamesMuteNotificationsResponse,
} from 'protos'

import createGamelogicFunction from './createGamelogicFunction'

export const sessionsLogin = createGamelogicFunction<ISessionsLoginRequest, SessionsLoginResponse>({
  rpcPath: 'Sessions/Login',
  requestClass: SessionsLoginRequest,
  responseClass: SessionsLoginResponse,
  errorResultBlacklist: [
    SessionsLoginResponse.Result.ERROR_UNKNOWN,
    SessionsLoginResponse.Result.ERROR_USER_SETUP_NEEDED,
  ],
})

export const usersGetProfile = createGamelogicFunction<IUsersGetProfileRequest, UsersGetProfileResponse>({
  rpcPath: 'Users/GetProfile',
  requestClass: UsersGetProfileRequest,
  responseClass: UsersGetProfileResponse,
})

export const usersGetFriends = createGamelogicFunction<IUsersGetFriendsRequest, UsersGetFriendsResponse>({
  rpcPath: 'Users/GetFriends',
  requestClass: UsersGetFriendsRequest,
  responseClass: UsersGetFriendsResponse,
})

export const usersAddFriend = createGamelogicFunction<IUsersAddFriendRequest, UsersAddFriendResponse>({
  rpcPath: 'Users/AddFriend',
  requestClass: UsersAddFriendRequest,
  responseClass: UsersAddFriendResponse,
})

export const usersRemoveFriend = createGamelogicFunction<IUsersRemoveFriendRequest, UsersRemoveFriendResponse>({
  rpcPath: 'Users/RemoveFriend',
  requestClass: UsersRemoveFriendRequest,
  responseClass: UsersRemoveFriendResponse,
})

export const usersGetStatus = createGamelogicFunction<IUsersGetStatusRequest, UsersGetStatusResponse>({
  rpcPath: 'Users/GetStatus',
  requestClass: UsersGetStatusRequest,
  responseClass: UsersGetStatusResponse,
})

export const usersSearch = createGamelogicFunction<IUsersSearchRequest, UsersSearchResponse>({
  rpcPath: 'Users/Search',
  requestClass: UsersSearchRequest,
  responseClass: UsersSearchResponse,
})

export const usersGetLeaderboard = createGamelogicFunction<IUsersGetLeaderboardRequest, UsersGetLeaderboardResponse>({
  rpcPath: 'Users/GetLeaderboard',
  requestClass: UsersGetLeaderboardRequest,
  responseClass: UsersGetLeaderboardResponse,
})

export const usersUpdateProfile = createGamelogicFunction<IUsersUpdateProfileRequest, UsersUpdateProfileResponse>({
  rpcPath: 'Users/UpdateProfile',
  requestClass: UsersUpdateProfileRequest,
  responseClass: UsersUpdateProfileResponse,
})

export const usersUpdatePhoto = createGamelogicFunction<Blob, UsersUpdateProfileResponse>({
  rpcPath: 'Users/UpdateProfile',
  responseClass: UsersUpdateProfileResponse,
  getBody: req => {
    if (!req) throw new Error()

    return req
  },
})

export const usersUpdateProfileAndPhoto = createGamelogicFunction<{ proto: IUsersUpdateProfileRequest, photo: Blob }, UsersUpdateProfileResponse>({
  rpcPath: 'Users/UpdateProfile',
  responseClass: UsersUpdateProfileResponse,
  getBody: req => {
    if (!req) throw new Error()

    const fd = new FormData()
    fd.append('proto', new Blob([UsersUpdateProfileRequest.encode(req.proto || {}).finish()], { type: 'application/x-protobuf' }))
    fd.append('photo', req.photo)

    return fd
  },
})

export const usersConfirmNotification = createGamelogicFunction<IUsersConfirmNotificationRequest, UsersConfirmNotificationResponse>({
  rpcPath: 'Users/ConfirmNotification',
  requestClass: UsersConfirmNotificationRequest,
  responseClass: UsersConfirmNotificationResponse,
})

export const usersClaimBonus = createGamelogicFunction<IUsersClaimBonusRequest, UsersClaimBonusResponse>({
  rpcPath: 'Users/ClaimBonus',
  requestClass: UsersClaimBonusRequest,
  responseClass: UsersClaimBonusResponse,
})

export const usersGetNotifications = createGamelogicFunction<IUsersGetNotificationsRequest, UsersGetNotificationsResponse>({
  rpcPath: 'Users/GetNotifications',
  requestClass: UsersGetNotificationsRequest,
  responseClass: UsersGetNotificationsResponse,
})

export const usersSetNotification = createGamelogicFunction<IUsersSetNotificationRequest, UsersSetNotificationResponse>({
  rpcPath: 'Users/SetNotification',
  requestClass: UsersSetNotificationRequest,
  responseClass: UsersSetNotificationResponse,
})

export const gamesGetActive = createGamelogicFunction<IGamesGetActiveRequest, GamesGetActiveResponse>({
  rpcPath: 'Games/GetActive',
  requestClass: GamesGetActiveRequest,
  responseClass: GamesGetActiveResponse,
})

export const matchesGetAllTeams = createGamelogicFunction<IMatchesGetAllTeamsRequest, MatchesGetAllTeamsResponse>({
  rpcPath: 'Matches/GetAllTeams',
  requestClass: MatchesGetAllTeamsRequest,
  responseClass: MatchesGetAllTeamsResponse,
})

export const matchesGetTimeline = createGamelogicFunction<IMatchesGetTimelineRequest, MatchesGetTimelineResponse>({
  rpcPath: 'Matches/GetTimeline',
  requestClass: MatchesGetTimelineRequest,
  responseClass: MatchesGetTimelineResponse,
})

export const gamesGetTemplates = createGamelogicFunction<IGamesGetTemplatesRequest, GamesGetTemplatesResponse>({
  rpcPath: 'Games/GetTemplates',
  requestClass: GamesGetTemplatesRequest,
  responseClass: GamesGetTemplatesResponse,
})

export const gamesGetPublicInputs = createGamelogicFunction<IGamesGetPublicInputsRequest, GamesGetPublicInputsResponse>({
  rpcPath: 'Games/GetPublicInputs',
  requestClass: GamesGetPublicInputsRequest,
  responseClass: GamesGetPublicInputsResponse,
})

export const gamesGetPrivateInputs = createGamelogicFunction<IGamesGetPrivateInputsRequest, GamesGetPrivateInputsResponse>({
  rpcPath: 'Games/GetPrivateInputs',
  requestClass: GamesGetPrivateInputsRequest,
  responseClass: GamesGetPrivateInputsResponse,
})

export const gamesCreatePublic = createGamelogicFunction<IGamesCreatePublicRequest, GamesCreatePublicResponse>({
  rpcPath: 'Games/CreatePublic',
  requestClass: GamesCreatePublicRequest,
  responseClass: GamesCreatePublicResponse,
})

export const gamesCreatePrivate = createGamelogicFunction<IGamesCreatePrivateRequest, GamesCreatePrivateResponse>({
  rpcPath: 'Games/CreatePrivate',
  requestClass: GamesCreatePrivateRequest,
  responseClass: GamesCreatePrivateResponse,
})

export const gamesGetHistory = createGamelogicFunction<IGamesGetHistoryRequest, GamesGetHistoryResponse>({
  rpcPath: 'Games/GetHistory',
  requestClass: GamesGetHistoryRequest,
  responseClass: GamesGetHistoryResponse,
})

export const gamesGetDetails = createGamelogicFunction<IGamesGetDetailsRequest, GamesGetDetailsResponse>({
  rpcPath: 'Games/GetDetails',
  requestClass: GamesGetDetailsRequest,
  responseClass: GamesGetDetailsResponse,
})

export const gamesEditPredictions = createGamelogicFunction<IGamesEditPredictionsRequest, GamesEditPredictionsResponse>({
  rpcPath: 'Games/EditPredictions',
  requestClass: GamesEditPredictionsRequest,
  responseClass: GamesEditPredictionsResponse,
})

export const gamesCancelPrivate = createGamelogicFunction<IGamesCancelPrivateRequest, GamesCancelPrivateResponse>({
  rpcPath: 'Games/CancelPrivate',
  requestClass: GamesCancelPrivateRequest,
  responseClass: GamesCancelPrivateResponse,
})

export const gamesDeclineInvitation = createGamelogicFunction<IGamesDeclineInvitationRequest, GamesDeclineInvitationResponse>({
  rpcPath: 'Games/DeclineInvitation',
  requestClass: GamesDeclineInvitationRequest,
  responseClass: GamesDeclineInvitationResponse,
})

export const gamesAcceptInvitation = createGamelogicFunction<IGamesAcceptInvitationRequest, GamesAcceptInvitationResponse>({
  rpcPath: 'Games/AcceptInvitation',
  requestClass: GamesAcceptInvitationRequest,
  responseClass: GamesAcceptInvitationResponse,
})

export const gamesGetMissingPredictions = createGamelogicFunction<IGamesGetMissingPredictionsRequest, GamesGetMissingPredictionsResponse>({
  rpcPath: 'Games/GetMissingPredictions',
  requestClass: GamesGetMissingPredictionsRequest,
  responseClass: GamesGetMissingPredictionsResponse,
})

export const groupsGetPrivateInputs = createGamelogicFunction<IGroupsGetPrivateInputsRequest, GroupsGetPrivateInputsResponse>({
  rpcPath: 'Groups/GetPrivateInputs',
  requestClass: GroupsGetPrivateInputsRequest,
  responseClass: GroupsGetPrivateInputsResponse,
})

export const groupsCreatePrivate = createGamelogicFunction<IGroupsCreatePrivateRequest, GroupsCreatePrivateResponse>({
  rpcPath: 'Groups/CreatePrivate',
  requestClass: GroupsCreatePrivateRequest,
  responseClass: GroupsCreatePrivateResponse,
})

export const usersGetFacebookFriends = createGamelogicFunction<IUsersGetFacebookFriendsRequest, UsersGetFacebookFriendsResponse>({
  rpcPath: 'Users/GetFacebookFriends',
  requestClass: UsersGetFacebookFriendsRequest,
  responseClass: UsersGetFacebookFriendsResponse,
})

export const usersAddFacebookFriend = createGamelogicFunction<IUsersAddFacebookFriendRequest, UsersAddFacebookFriendResponse>({
  rpcPath: 'Users/AddFacebookFriend',
  requestClass: UsersAddFacebookFriendRequest,
  responseClass: UsersAddFacebookFriendResponse,
})

export const gamesSendMessage = createGamelogicFunction<IGamesSendMessageRequest, GamesSendMessageResponse>({
  rpcPath: 'Games/SendMessage',
  requestClass: GamesSendMessageRequest,
  responseClass: GamesSendMessageResponse,
})

export const groupsGetActive = createGamelogicFunction<IGroupsGetActiveRequest, GroupsGetActiveResponse>({
  rpcPath: 'Groups/GetActive',
  requestClass: GroupsGetActiveRequest,
  responseClass: GroupsGetActiveResponse,
})

export const groupsGetHistory = createGamelogicFunction<IGroupsGetHistoryRequest, GroupsGetHistoryResponse>({
  rpcPath: 'Groups/GetHistory',
  requestClass: GroupsGetHistoryRequest,
  responseClass: GroupsGetHistoryResponse,
})

export const gamesInviteFriends = createGamelogicFunction<IGamesInviteFriendsRequest, GamesInviteFriendsResponse>({
  rpcPath: 'Games/InviteFriends',
  requestClass: GamesInviteFriendsRequest,
  responseClass: GamesInviteFriendsResponse,
})

export const gamesGetInvitableFriends = createGamelogicFunction<IGamesGetInvitableFriendsRequest, GamesGetInvitableFriendsResponse>({
  rpcPath: 'Games/GetInvitableFriends',
  requestClass: GamesGetInvitableFriendsRequest,
  responseClass: GamesGetInvitableFriendsResponse,
})

export const gamesMuteNotifications = createGamelogicFunction<IGamesMuteNotificationsRequest, GamesMuteNotificationsResponse>({
  rpcPath: 'Games/MuteNotifications',
  requestClass: GamesMuteNotificationsRequest,
  responseClass: GamesMuteNotificationsResponse,
})

export * from './createGamelogicFunction'
