import React, { FC } from 'react'
import { INotification } from 'protos'
import { makeStyles } from '@material-ui/core/styles'
import { Beer, Drinks } from 'tackl-material/Graphics'
import { CreditBillLandscape } from 'components'

type Props = {
  notification: INotification
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'inline-block',
  },
  beerIcon: {
    width: 46,
    height: 'auto',
    position: 'absolute',
    right: '-10%',
    bottom: '-5%',
  },
  drinksIcon: {
    width: 121,
    height: 'auto',
  },
  image: {
    maxWidth: '100%',
  },
}))

const NotificationHeader: FC<Props> = ({ notification }) => {
  const classes = useStyles()
  const { creditValue, drinkValue, imageUrl, title } = notification

  if (creditValue) {
    return (
      <div className={classes.root}>
        <CreditBillLandscape
          unit={creditValue.unit!}
          amount={creditValue!.amount!}
        />
        {!!drinkValue && <Beer className={classes.beerIcon} />}
      </div>
    )
  } else if (!!drinkValue) {
    return (
      <Drinks
        alt={title!}
        className={classes.drinksIcon}
      />
    )
  }

  return imageUrl ? (
    <img
      alt={title!}
      src={imageUrl!}
      className={classes.image}
    />
  ) : null
}

export default NotificationHeader
