import { useState, useCallback } from 'react'
import useInterval from '@use-it/interval'
import useFetch from './useFetch'

const usePolling = <Req = {}, Res = {}>(
  fn: (request?: Req, language?: string) => Promise<Res>,
  request?: Req,
  pollingInterval: number | null = 60000,
) => {
  const [_pollingInterval, updatePollingInterval] = useState(pollingInterval)
  const [_request, updateRequest] = useState(request)
  const { refetch, ...state } = useFetch(fn, _request)

  const isPaused = _pollingInterval === null

  const pause = useCallback(() => {
    updatePollingInterval(null)
  }, [])

  const resume = useCallback((doRefetch: boolean = false) => {
    updatePollingInterval(pollingInterval)
    doRefetch && refetch()
  }, [pollingInterval, refetch])

  useInterval(() => {
    refetch()
  }, _pollingInterval)

  return { ...state, isPaused, refetch, pause, resume, updateRequest }
}

export default usePolling
