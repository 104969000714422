import React, { FC, ComponentProps, memo } from 'react'
import clsx from 'clsx'
import { Colon } from 'tackl-material/Icons'
import { IMatch } from 'protos'
import { LinearProgress } from '@material-ui/core'
import { fade, makeStyles, Theme } from '@material-ui/core/styles'
import { FormattedDate, JerseyStriped, MatchClock } from 'components'
import { Box, CenteredBox, Paper, Divider, Typography } from 'tackl-material'
import { isLive, isCancelled } from 'util/match'
import { BetOfferPrediction, BetOfferSponsor } from './BetOffer'

const MemoizedTypography = memo(Typography)

type Props = ComponentProps<typeof Box> & {
  match: IMatch
  homeScore: number
  awayScore: number
  hidePredictions?: boolean
  variant?: 'normal' | 'overflowing'
}

const useStyles = makeStyles<Theme>(({ palette, typography, spacing }) => ({
  root: {
    overflow: 'hidden',
  },
  divided: {
    borderRight: `1px dashed ${palette.divider}`,
  },
  teams: {
    wordBreak: 'break-word',
  },
  teamsWhenPredictionPresent: {
    height: 55,
  },
  teamsWhenPredictionAbsent: {
    height: 55 + spacing(3),
  },
  scores: {
    position: 'relative',
    overflow: 'hidden',
    minHeight: 108,
    paddingTop: spacing(3),
  },
  overflowingScores: {
    marginLeft: '-25%',
    marginRight: '-25%',
  },
  scoreContainer: {
    position: 'relative',
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
  },
  leftJersey: {
    position: 'absolute',
    top: spacing(3) * -1,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  rightJersey: {
    position: 'absolute',
    top: spacing(3) * -1,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  colon: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
    color: '#b0b0b0',
    fontSize: typography.pxToRem(32),
  },
  score: {
    zIndex: 1,
    position: 'relative',
    fontSize: typography.pxToRem(60),
    textShadow: `0 0 2px ${fade(palette.common.white, .4)}`,
  },
  status: {
    minWidth: 1,
    minHeight: 22,
  },
  date: {
    display: 'block',
    '&:first-word': {
      fontWeight: typography.fontWeightBold,
    },
  },
  sponsor: {
    marginTop: spacing(0.5),
  },
  prediction: {
    marginTop: -21,
  },
  predictionContainer: {
    padding: spacing(0, 2, 1),
    borderTop: `1px solid ${palette.divider}`,
  },
  predictionContainerWhenPredictionPresent: {
    margin: spacing(3, 0, 0),
  },
}))

const dateOptions = {
  month: 'long',
  day: 'numeric',
  weekday: 'long',
} as const

const timeOptions = {
  hour: '2-digit',
  minute: '2-digit',
} as const

const MatchBanner: FC<Props> = ({
  match,
  homeScore,
  awayScore,
  className,
  hidePredictions,
  variant = 'normal',
  ...props
}) => {
  const classes = useStyles()
  const hasPrediction = !!(match?.betOffer?.predictionEnabled && match.betOffer?.market_1x2)

  return (
    <Box
      {...props}
      className={clsx(className, classes.root)}
    >
      <Box
        pt={1.5}
        flexBasis="100%"
      >
        <MemoizedTypography
          align="center"
          variant="subtitle1"
          className={classes.date}
        >
          <FormattedDate
            options={dateOptions}
            timestamp={Number(match.startTime!) * 1000}
          />
        </MemoizedTypography>
        <Box
          textAlign="center"
          className={classes.status}
        >
          {
            isLive(match.state)
              ? (
                <MatchClock>
                  {match.clock}
                </MatchClock>
              ) : (
                <MemoizedTypography
                  alpha={.5}
                  align="center"
                  variant="subtitle1"
                >
                  <strong>
                    {
                      isCancelled(match.state)
                        ? match.cancelReason
                        : (
                          <FormattedDate
                            options={timeOptions}
                            timestamp={Number(match.startTime!) * 1000}
                          />
                        )
                    }
                  </strong>
                </MemoizedTypography>
              )
          }
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        className={clsx(classes.scores, {
          [classes.overflowingScores]: variant === 'overflowing',
        })}
      >
        <CenteredBox
          flexBasis="100%"
          className={classes.colon}
        >
          <Colon fontSize="inherit" />
        </CenteredBox>
        <Box
          pt={1}
          pb={3}
          flexBasis="50%"
          className={clsx(classes.divided, classes.scoreContainer)}
        >
          <JerseyStriped
            size="large"
            side="back"
            stripeOrigin="left"
            className={classes.leftJersey}
            color={match.homeTeam ? match.homeTeam.primaryColor : null}
          />
          <MemoizedTypography
            variant="h1"
            align="center"
            color="inherit"
            className={classes.score}
          >
            {homeScore >= 0 ? homeScore : '–'}
          </MemoizedTypography>
        </Box>
        <Box
          pt={1}
          pb={3}
          flexBasis="50%"
          className={classes.scoreContainer}
        >
          <JerseyStriped
            size="large"
            side="back"
            stripeOrigin="right"
            className={classes.rightJersey}
            color={match.awayTeam ? match.awayTeam.primaryColor : null}
          />
          <MemoizedTypography
            variant="h1"
            align="center"
            color="inherit"
            className={classes.score}
          >
            {awayScore >= 0 ? awayScore : '–'}
          </MemoizedTypography>
        </Box>
      </Box>
      {
        isLive(match.state) && (
          <LinearProgress
            color="primary"
            variant="determinate"
            value={Math.min((match.progress || 0), 1) * 100}
          />
        )
      }
      <Divider />
      <Paper>
        <Box
          display="flex"
          flexDirection="row"
          className={clsx(classes.teams, {
            [classes.teamsWhenPredictionPresent]: hasPrediction,
            [classes.teamsWhenPredictionAbsent]: !hasPrediction,
          })}
        >
          <CenteredBox
            px={1}
            flexBasis="50%"
            flexGrow={0}
            flexShrink={0}
            className={classes.divided}
          >
            <MemoizedTypography
              variant="h5"
              align="center"
              color="inherit"
            >
              {match.homeTeam!.teamName}
            </MemoizedTypography>
          </CenteredBox>
          <CenteredBox
            px={1}
            flexGrow={0}
            flexShrink={0}
            flexBasis="50%"
          >
            <MemoizedTypography
              variant="h5"
              align="center"
              color="inherit"
            >
              {match.awayTeam!.teamName}
            </MemoizedTypography>
          </CenteredBox>
        </Box>
      </Paper>
      {
        !hidePredictions && (
          <Paper
            color="neutral-200"
            className={clsx(classes.predictionContainer, {
              [classes.predictionContainerWhenPredictionPresent]: hasPrediction,
            })}
          >
            {match?.betOffer?.predictionEnabled && match.betOffer?.market_1x2 && (
              <BetOfferPrediction
                className={classes.prediction}
                market1X2={match?.betOffer?.market_1x2}
                homeColor={match?.homeTeam?.primaryColor!}
                awayColor={match?.awayTeam?.primaryColor!}
              />
            )}

            {
              match?.betOffer?.sponsor && (
                <BetOfferSponsor
                  variant="dark"
                  className={classes.sponsor}
                  sponsor={match?.betOffer?.sponsor}
                />
              )
            }
          </Paper>
        )
      }
    </Box>
  )
}

export default memo(MatchBanner)
