import { Tabs, withStyles, Theme } from '@material-ui/core'

const BetSlipTabs = withStyles((theme: Theme) => ({
  root: {
    minHeight: 36,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    margin: theme.spacing(0, 1),
    backgroundColor: theme.palette.neutral[300].main,
  },
}))(Tabs)

BetSlipTabs.defaultProps = {
  variant: 'fullWidth',
}

export default BetSlipTabs
