import config from 'services/config'
import customMessages from './customMessages'

const messages = async () => {
  try {
    switch (config.tenantId) {
      case 'tippstar':
        return { ...(await import(`./tippstar`)).default, ...customMessages }
      case 'bild':
        return {
          ...(await import(`./bild`)).default,
          ...customMessages,
          ...(await import(`./overrides/bild`)).default,
        }
      default:
        return { ...(await import(`./tackl`)).default, ...customMessages }
    }
  } catch (error) {
    // Notify developers if translations missing for tenant
    console.warn(error)

    // Fallback to tackl translations
    return { ...(await import(`./tackl`)).default, ...customMessages }
  }
}

export default messages
