import { lazy } from 'react'
import { tenantSwitch } from 'lib'

export const BOTTOM_STEPPER_HEIGHT = 45

export default tenantSwitch({
  tackl: lazy(() => import('./BottomStepperTackl')),
  tippstar: lazy(() => import('./BottomStepperTackl')),
  bild: lazy(() => import('./BottomStepperBild')),
})
