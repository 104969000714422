import React, { FC, ComponentProps } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import MuiTypography from '@material-ui/core/Typography'
import { textColor } from '../util'
import { TextColor } from '../propTypes'

type MuiTypographyProps = ComponentProps<typeof MuiTypography>

type Props = Omit<MuiTypographyProps, 'color'> & {
  color?: TextColor
  alpha?: .3 | .5 | .7 | 1
  component?: React.ReactNode
}

type StyleProps = Pick<Props, 'color' | 'alpha'>

const useStyles = makeStyles<Theme, StyleProps>(({ palette }) => ({
  colorPrimary: textColor(palette.primary),
  colorSecondary: textColor(palette.secondary),
  colorBet: textColor(palette.bet),
  colorBrand: textColor(palette.brand),
  colorAccent50: textColor(palette.accent[50]),
  colorAccent100: textColor(palette.accent[100]),
  colorAccent200: textColor(palette.accent[200]),
  colorAccent300: textColor(palette.accent[300]),
  colorAccent400: textColor(palette.accent[400]),
  colorDanger: textColor(palette.danger),
  colorError: textColor(palette.error),
  hasAlpha: ({ alpha }) => ({
    opacity: alpha,
  }),
}))

const Typography: FC<Props> = (props) => {
  const { color, alpha, className, ...typographyProps } = props
  const classes = useStyles({ color, alpha })

  return (
    <MuiTypography
      {...typographyProps}
      className={clsx(className, {
        [classes.hasAlpha]: alpha && alpha < 1,
        [classes.colorPrimary]: color === 'primary',
        [classes.colorBet]: color === 'bet',
        [classes.colorBrand]: color === 'brand',
        [classes.colorAccent50]: color === 'accent-50',
        [classes.colorAccent100]: color === 'accent-100',
        [classes.colorAccent200]: color === 'accent-200',
        [classes.colorAccent300]: color === 'accent-300',
        [classes.colorAccent400]: color === 'accent-400',
        [classes.colorAccent500]: color === 'accent-500',
        [classes.colorDanger]: color === 'danger',
        [classes.colorError]: color === 'error',
      })}
    />
  )
}

export default Typography
