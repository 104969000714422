import React, { FC } from 'react'

import { BetOffer, IBetOffer, IPrediction } from 'protos'

import { LotteryBetOfferOdds, LotteryBetOfferSponsor } from './components'

type Props = {
  betOffer?: IBetOffer | null
  prediction?: IPrediction | null
  variant?: 'light' | 'dark'
}

const LotteryBetOffer: FC<Props> = ({ betOffer, prediction, ...props }) => {
  let market: BetOffer.IMarket | null | undefined
  let sponsor: BetOffer.ISponsor | null | undefined

  if (betOffer?.gameOddsEnabled) {
    sponsor = betOffer?.sponsor

    const homeScore = prediction?.predictedScore?.homeScore ?? 0
    const awayScore = prediction?.predictedScore?.awayScore ?? 0

    if (homeScore === awayScore) {
      market = betOffer?.market_1x2?.draw
    }

    if (homeScore > awayScore) {
      market = betOffer?.market_1x2?.home
    }

    if (homeScore < awayScore) {
      market = betOffer?.market_1x2?.away
    }
  }

  if (market && sponsor) {
    return (
      <LotteryBetOfferOdds
        market={market}
        sponsor={sponsor}
        {...props}
      />
    )
  }

  if (!market && sponsor) {
    return (
      <LotteryBetOfferSponsor
        sponsor={sponsor}
        {...props}
      />
    )
  }

  return null
}

export default LotteryBetOffer
