import React, { FC, ChangeEvent, useState, useCallback, useEffect } from 'react'
import xor from 'lodash.xor'
import { IOpponent } from 'protos'
import { Box, Checkbox, Typography } from 'tackl-material'
import { List, ListItem, ListItemText, FormControlLabel } from '@material-ui/core'
import SearchFriends from './SearchFriends'
import FormattedMessage from './FormattedMessage'
import { OpponentListItem } from './OpponentListItem'

type Props = {
  opponents: IOpponent[]
  initialSearchTerm?: string
  maxSelected?: number | null
  disableInitialSelected?: boolean
  initialSelectedUserIds?: string[]
  onSelectionChanged: (newUserIds: string[]) => void
}

const doesIncludeTerm = (opponent: IOpponent, searchTerm: string) => {
  const nickname = ((opponent.user && opponent.user.nickname) || '').toLowerCase()
  const lastName = ((opponent.user && opponent.user.lastName) || '').toLowerCase()
  const firstName = ((opponent.user && opponent.user.firstName) || '').toLowerCase()

  return nickname.includes(searchTerm) || firstName.includes(searchTerm) || lastName.includes(searchTerm)
}

const OpponentSelect: FC<Props> = ({
  opponents,
  maxSelected,
  initialSearchTerm = '',
  onSelectionChanged,
  disableInitialSelected = false,
  initialSelectedUserIds = [],
}) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm)
  const [selectedUserIds, setSelectedUserIds] = useState(initialSelectedUserIds)

  const trimmedSearchTerm = searchTerm.trim()
  const filteredOpponents = trimmedSearchTerm
    ? opponents.filter((opponent) => doesIncludeTerm(opponent, trimmedSearchTerm))
    : opponents

  const isFull = maxSelected && selectedUserIds.length >= maxSelected
  const displaySelectAllHeader = !searchTerm

  const changeSearchTerm = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }, [])

  const toggleSelectedUserId = useCallback((userId: string) => {
    setSelectedUserIds((prevSelectedIds) => xor(prevSelectedIds, [userId]))
  }, [])

  const selectAll = useCallback(() => {
    setSelectedUserIds(opponents.map(opponent => opponent.user?.userId ?? ''))
  }, [opponents])

  const deselectAll = useCallback(() => {
    setSelectedUserIds(disableInitialSelected ? initialSelectedUserIds : [])
  }, [disableInitialSelected, initialSelectedUserIds])

  useEffect(() => {
    onSelectionChanged(selectedUserIds)
  }, [onSelectionChanged, selectedUserIds])

  return (
    <>
      <SearchFriends
        value={searchTerm}
        onChange={changeSearchTerm}
      />
      <Box mx={-2}>
        <List>
          {
            displaySelectAllHeader && (
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{ variant: 'h4' }}
                  primary={<FormattedMessage id="invite_friends_header_title" />}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  labelPlacement="start"
                  checked={selectedUserIds.length === opponents.length}
                  onChange={(_, checked) => checked ? selectAll() : deselectAll()}
                  label={(
                    <Typography variant="body2">
                      <FormattedMessage id="invite_friends_header_selectall" />
                    </Typography>
                  )}
                />
              </ListItem>
            )
          }
          {
            filteredOpponents.map((opponent) => {
              const userId = opponent.user!.userId!
              const isSelected = selectedUserIds.includes(userId)
              const isDisabled = (!isSelected && isFull) || (disableInitialSelected && initialSelectedUserIds.includes(userId))

              return (
                <OpponentListItem
                  key={userId}
                  button={!isDisabled}
                  variant="selectable"
                  onClick={isDisabled ? undefined : () => toggleSelectedUserId(userId)}
                  opponent={opponent}
                  disabled={isDisabled}
                  isSelected={isSelected}
                />
              )
            })
          }
        </List>
      </Box>
    </>
  )
}

export default OpponentSelect
