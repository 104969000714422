import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const RadioChecked: FC<ComponentProps<typeof SvgIcon>> = (props) => (
  <SvgIcon
    width="46"
    height="46"
    viewBox="0 0 46 46"
    {...props}
  >
    <g fillRule="inherit">
      <rect
        stroke="#FFF"
        strokeWidth="3"
        width="40"
        height="40"
        x="3"
        y="3"
        rx="20"
      />
      <rect
        fill="#FFF"
        x="18"
        y="18"
        width="10"
        height="10"
        rx="5"
      />
    </g>
  </SvgIcon>
)

export default RadioChecked
