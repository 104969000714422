import React, { FC, ComponentProps, memo } from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import { Avatar as MuiAvatar } from '@material-ui/core'
import { User } from 'tackl-material/Icons'

type Props = Omit<ComponentProps<typeof MuiAvatar>, 'src'> & {
  src?: string | null
}

const useStyles = makeStyles<Theme>(({ palette }) => ({
  defaultIcon: {
    width: '85%',
    height: 'auto',
    marginTop: '20%',
    marginLeft: '7%',
  },
}))

const Avatar: FC<Props> = ({ src, children, ...props }) => {
  const classes = useStyles()

  return (
    <MuiAvatar
      {...props}
      src={src || undefined}
    >
      {!src && (children || (
        <User
          color="inherit"
          className={classes.defaultIcon}
        />
      ))}
    </MuiAvatar>
  )
}

export default memo(Avatar)
