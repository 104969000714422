import { mount, lazy, redirect } from 'navi'
import { RouterContext } from 'components/Router'
import { tenantOnly } from 'lib'

export default mount<RouterContext>({
  '/': tenantOnly(['bild'], './games')(
    redirect('./games/lotto'),
  ),
  '/docs': lazy(() => import('./docs')),
  '/auth': lazy(() => import('./auth')),
  '/setup': lazy(() => import('./setup')),
  '/games': lazy(() => import('./games')),
  '/groups': lazy(() => import('./groups')),
  '/friends': lazy(() => import('./friends')),
  '/profile': lazy(() => import('./profile')),
  '*': tenantOnly(['bild'], './games', { exact: false })(
    redirect('./games/lotto', { exact: false }),
  ),
})
