import { useEffect } from 'react'

const usePausePolling = (
  condition: boolean,
  polling: {
    isPaused: boolean
    pause: () => void
    resume: (doRefetch?: boolean) => void
  },
) => {
  const { isPaused, pause, resume } = polling

  useEffect(() => {
    if (condition && !isPaused) {
      pause()
    }

    if (!condition && isPaused) {
      resume(true)
    }
  }, [condition, isPaused, pause, resume])
}

export default usePausePolling
