import React, { ComponentProps } from 'react'
import Box, { BoxProps } from '@material-ui/core/Box'
import { Typography } from '../Typography'
import DashedDivider from './DashedDivider'

interface DividerHeadlineProps extends BoxProps {
  dividerProps?: ComponentProps<typeof DashedDivider>
  typographyProps?: ComponentProps<typeof Typography>
}

const DividerHeadline: React.FC<DividerHeadlineProps> = ({
  children,
  dividerProps,
  typographyProps,
  ...props
}) => {
  return (
    <Box
      {...props}
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      <Box flex={1}>
        <DashedDivider {...dividerProps} />
      </Box>
      {children && (
        <Box mx={2}>
          <Typography
            variant="h6"
            alpha={.5}
            {...typographyProps}
          >
            {children}
          </Typography>
        </Box>
      )}
      <Box flex={1}>
        <DashedDivider {...dividerProps} />
      </Box>
    </Box>
  )
}

export default DividerHeadline
