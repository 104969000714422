import React, { FC } from 'react'
import { Container, Box, Message } from 'tackl-material'
import { Beers } from 'tackl-material/Graphics'
import { Ticket, Whistle } from 'tackl-material/Icons'
import { FormattedMessage } from 'components'
import config from 'services/config'

interface Props {
  state?: 'upcoming' | 'live' | 'cancelled'
}

const Icon: FC<Props> = ({ state }) => {
  if (state === 'live') {
    return (
      <Whistle
        fontSize="large"
        color="secondary"
      />
    )
  }

  if (config.tenantId === 'bild') {
    return (
      <Ticket
        fontSize="large"
        color="primary"
      />
    )
  }

  return <Beers />
}

const Headline: FC<Props> = ({ state }) => {
  if (state === 'live') {
    return <FormattedMessage id="timeline_empty_live_title" />
  }

  if (state === 'cancelled') {
    return <FormattedMessage id="web_bild_timeline_empty_cancelled_title" />
  }

  return <FormattedMessage id="timeline_empty_title" />
}

const Body: FC<Props> = ({ state }) => {
  if (state === 'live') {
    return <FormattedMessage id="timeline_empty_live_message" />
  }

  if (state === 'cancelled') {
    return null
  }

  return <FormattedMessage id="timeline_empty_message" />
}

const EmptyState: FC<Props> = ({ state = 'live' }) => {
  return (
    <Container maxWidth="sm">
      <Box pt={2}>
        <Message
          icon={<Icon state={state} />}
          headline={<Headline state={state} />}
          body={<Body state={state} />}
        />
      </Box>
    </Container>
  )
}

export default EmptyState
