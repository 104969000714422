import useIntl from './useIntl'

export default function useFormatAmount() {
  const { language } = useIntl()

  return (amount: string | number | Long.Long | null | undefined) => formatAmount(amount, language)
}

function formatAmount(amount: string | number | Long.Long | null | undefined, language: string) {
  if (typeof amount === 'number') {
    return formatNumber(amount, language)
  }

  if (!amount) {
    return null
  }

  if (typeof amount === 'string') {
    const asNumber = Number(amount)

    if (Number.isNaN(asNumber)) {
      return amount
    }

    return formatNumber(asNumber, language)
  }

  return formatNumber(amount.toNumber(), language)
}

function formatNumber(value: number, language: string) {
  return value.toLocaleString(language)
}
