import React, { FC, ComponentProps, ComponentType } from 'react'
import clsx from 'clsx'
import { makeStyles, Theme, alpha } from '@material-ui/core'
import MuiTab from '@material-ui/core/Tab'
import { NotificationBadge } from '../Badge'

type Props = Omit<ComponentProps<typeof MuiTab>, 'component'> & {
  size?: 'small' | 'medium'
  component?: ComponentType
  notificationCount?: ComponentProps<typeof NotificationBadge>['badgeContent']
}

const useStyles = makeStyles<Theme>(({ palette, typography, overrides }) => ({
  root: {
    alignItems: 'inherit',
    textTransform: 'none',
    color: alpha(palette.common.black, .15),
    ...overrides?.MuiTab?.root,
  },
  selected: {
    color: palette.common.black,
    ...overrides?.MuiTab?.selected,
  },
  label: {
    color: 'inherit',
  },
  sizeSmall: typography.h4,
  sizeMedium: typography.h3,
  wrapper: {
    alignItems: 'inherit',
  },
}))

const Tab: FC<Props> = ({ size, label: labelNode, selected, notificationCount, className, ...props }) => {
  const classes = useStyles()

  return (
    <MuiTab
      {...props}
      classes={classes}
      className={clsx(className, {
        [classes.selected]: selected,
        [classes.sizeSmall]: size === 'small',
        [classes.sizeMedium]: size === 'medium',
      })}
      label={(
        <NotificationBadge badgeContent={notificationCount}>
          <span className={clsx(classes.label)}>
            {labelNode}
          </span>
        </NotificationBadge>
      )}
    />
  )
}

Tab.defaultProps = {
  size: 'medium',
}

export default Tab
