import { noop } from 'lib'
import { AuthService } from './auth'
import createSingleton from './createSingleton'
import analytics, { AnalyticsService } from './analytics'

export const authService = createSingleton<AuthService>({
  signOut: noop,
  initialize: async () => {},
  onAuthStateChanged: () => noop,
  authorizedFetch: window.fetch,
  getProvider: () => undefined,
})

export const lastStatusResponseTimestamp = createSingleton<number>(0)

export const identityToken = createSingleton<string | undefined>(undefined)

export const analyticsService = createSingleton<AnalyticsService>(analytics)
