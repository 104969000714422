import React, { FC } from 'react'
import beersImg from './assets/beers.svg'
import { ImageProps } from './propTypes'

const Beers: FC<ImageProps> = props => (
  <img
    alt="beers"
    {...props}
    src={beersImg}
  />
)

export default Beers
