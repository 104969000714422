import React from 'react'
import { Beers } from 'tackl-material/Graphics'
import { FormattedMessage } from 'components'
import { Container, Box, Message, Button } from 'tackl-material'
import config from 'services/config'

const AuthFailed = () => {
  return (
    <Container maxWidth="sm">
      <Box py={8}>
        <Message
          icon={config.tenantId !== 'bild' && <Beers />}
          headline={<FormattedMessage id="alert_unexpectederror_title" />}
          body={<FormattedMessage id="alert_unexpectederror_message" />}
          actions={[
            <Box
              key="games"
              textAlign="center"
            >
              <Button
                color="secondary"
                variant="contained"
                onClick={() => window.location.reload()}
              >
                <FormattedMessage id="alert_retry" />
              </Button>
            </Box>,
          ]}
        />
      </Box>
    </Container>
  )
}

export default AuthFailed
