import React, { FC } from 'react'
import { makeStyles } from '@material-ui/styles'
import { SimplePaletteColorOptions, Theme } from '@material-ui/core/styles'
import { Box } from 'tackl-material'
import { IGame } from 'protos'
import IndicatorIcon from './IndicatorIcon'

interface Props {
  color: SimplePaletteColorOptions
  state: IGame['state']
}

interface StyleProps {
  color: SimplePaletteColorOptions
}

const useStyles = makeStyles<Theme, StyleProps>({
  root: ({ color }) => ({
    width: 45,
    color: color.contrastText,
    clipPath: 'polygon(0 0, 100% 0, 50% 100%, 0% 100%)',
    '-webkit-clip-path': 'polygon(0 0, 100% 0, 50% 100%, 0% 100%)',
    backgroundColor: color.main,
  }),
})

const FriendGameIndicator: FC<Props> = ({ color, state }) => {
  const classes = useStyles({ color })

  return (
    <Box
      pt={1}
      pl={1}
      className={classes.root}
    >
      <IndicatorIcon
        state={state}
        fontSize="small"
      />
    </Box>
  )
}

export default FriendGameIndicator
