import { map, redirect, Matcher } from 'navi'
import { SessionsLoginResponse } from 'protos'
import { RouterContext } from 'components/Router'

const { ERROR_USER_SETUP_NEEDED } = SessionsLoginResponse.Result

const redirectOptions = {
  exact: false,
}

const createWithSetupNeededCheck = (redirectTo: string) => (matcher: Matcher<RouterContext>) => {
  return map<RouterContext>((request, { sessionsLoginError }) => {
    const isSetupNeeded = sessionsLoginError && sessionsLoginError.result === ERROR_USER_SETUP_NEEDED

    if (isSetupNeeded) {
      return redirect({
        pathname: redirectTo,
      }, redirectOptions)
    }

    return matcher
  })
}

export default createWithSetupNeededCheck('/setup')
