import { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import App from 'App'
import { IntlContextProvider } from 'contexts/IntlContext'
import { AppWrapper } from 'components'
import config from 'services/config'

if (process.env.PUBLIC_URL && config.forcedIframeOnlyUrl && window.self === window.top) {
  window.location.replace(config.forcedIframeOnlyUrl)
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Suspense fallback={null} /* TODO: provide fallback UI */>
    <IntlContextProvider>
      <AppWrapper>
        <App />
      </AppWrapper>
    </IntlContextProvider>
  </Suspense>,
)
