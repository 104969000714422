import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const RadioUnchecked: FC<ComponentProps<typeof SvgIcon>> = (props) => (
  <SvgIcon
    width="46"
    height="46"
    viewBox="0 0 46 46"
    {...props}
  >
    <g
      fill="#FFF"
      stroke="#DCDCDC"
      fillRule="inherit"
    >
      <rect
        width="40"
        height="40"
        x="3"
        y="3"
        rx="20"
      />
    </g>
  </SvgIcon >
)

export default RadioUnchecked
