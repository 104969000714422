import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const StatusPending: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="17"
    height="20"
    viewBox="0 0 17 20"
    {...props}
  >
    <g
      fill="inherit"
      fillRule="evenodd"
    >
      <path
        d="M8.432 17.759v-.284c3.367 0 6.108-2.805 6.108-6.253 0-3.447-2.74-6.25-6.108-6.25-3.368 0-6.11 2.803-6.11 6.25 0 3.448 2.742 6.253 6.11 6.253v.284zm0-16.067v.284a.59.59 0 0 0-.582.596c0 .33.262.597.582.597a.59.59 0 0 0 .58-.597.59.59 0 0 0-.58-.596v-.284zm7.816 9.53c0 4.408-3.506 7.995-7.816 7.995-4.311 0-7.817-3.587-7.817-7.995 0-3.766 2.58-6.987 6.11-7.793-.13-.264-.2-.555-.2-.857C6.526 1.5 7.38.625 8.433.625c1.05 0 1.905.874 1.905 1.947 0 .302-.07.593-.2.857 3.53.806 6.11 4.027 6.11 7.793z"
        fillRule="nonzero"
      />
      <path d="M16.2 4.199l-2.325-1.997a.754.754 0 0 0-1.079.097.796.796 0 0 0 .095 1.104l2.326 1.996a.75.75 0 0 0 1.078-.097.795.795 0 0 0-.094-1.103M8.432 11.85a.62.62 0 0 1-.613-.628.62.62 0 0 1 .613-.626.62.62 0 0 1 .612.626.62.62 0 0 1-.612.627m2.817-4.227L8.67 9.838a1.318 1.318 0 0 0-.237-.025c-.76 0-1.378.633-1.378 1.41 0 .777.618 1.409 1.378 1.409.76 0 1.377-.632 1.377-1.41 0-.083-.01-.163-.024-.242l2.165-2.64c.153-.186.157-.462-.061-.718a.49.49 0 0 0-.64 0" />
    </g>
  </SvgIcon>
)

export default StatusPending
