import { IPrediction, Match } from 'protos'

export const isUpcoming = (state: Match.State | null = 0) => (
  state === Match.State.MS_UPCOMING
)

export const isLive = (state: Match.State | null = 0) => (
  state === Match.State.MS_LIVE
)

export const isCancelled = (state: Match.State | null = 0) => (
  state === Match.State.MS_CANCELLED
)

export const isScoreDisplayActive = (state: Match.State | null = 0) => (
  state === Match.State.MS_LIVE || state === Match.State.MS_FINISHED
)

export const isPredictionEditable = (state: Match.State | null = 0, prediction?: IPrediction | null) => (
  isUpcoming(state) && (!prediction || !!prediction?.editable)
)

export const isFinished = (state: Match.State | null = 0) => (
  state === Match.State.MS_FINISHED
)
