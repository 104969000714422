import { INotification } from 'protos'
import { createContext } from 'react'

type Context = {
  notifications: INotification[]
  addNotifications: (notifications?: INotification[] | null) => void
}

const NotificationsContext = createContext<Context>({
  notifications: [],
  addNotifications: () => {},
})

export default NotificationsContext
