import React, { FC, ComponentProps } from 'react'
import { ContentContainer } from 'tackl-material'
import { Box } from '../Box'
import { Message } from '../Typography'
import BottomDrawer from './BottomDrawer'

type MessageProps = ComponentProps<typeof Message>
type BottomDrawerProps = Pick<ComponentProps<typeof BottomDrawer>, 'open' | 'closeMethod' | 'onClose'>

const MessageDrawer: FC<MessageProps & BottomDrawerProps> = ({ open, closeMethod, onClose, ...props }) => (
  <BottomDrawer
    open={open}
    onClose={onClose}
    closeMethod={closeMethod}
  >
    <ContentContainer>
      <Box p={2}>
        <Message {...props} />
      </Box>
    </ContentContainer>
  </BottomDrawer>
)

export default MessageDrawer
