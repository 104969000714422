import { Game } from 'protos'
import { createContext } from 'react'

interface Context {
  setIsOpen: (isOpen: boolean) => void
  setMatchId: (matchId?: string) => void
  setOnClose: (onClose?: () => void) => void
  setGameType: (gameType?: Game.GameType | null) => void
}

const initialContext: Context = {
  setIsOpen: () => undefined,
  setMatchId: () => undefined,
  setOnClose: () => undefined,
  setGameType: () => undefined,
}

const MatchDetailsDrawerContext = createContext<Context>(initialContext)

export default MatchDetailsDrawerContext
