import { FC, ComponentProps as CP } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Bulb: FC<CP<typeof SvgIcon>> = props => (
  <SvgIcon
    width="15"
    height="24"
    viewBox="0 0 15 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.379 0H7.5C11.636 0 15 3.36 15 7.5c0 2.18-.955 4.26-2.62 5.69a.447.447 0 00-.168.27v.004C11.98 14.707 11.37 18 9.5 18h-4c-1.87 0-2.46-3.18-2.712-4.54a.444.444 0 00-.169-.27 7.522 7.522 0 01-2.614-5.4C-.07 5.82.69 3.84 2.086 2.37 3.481.89 5.41.03 7.379 0zM5 19.5c0-.28.224-.5.5-.5h4c.276 0 .5.22.5.5s-.224.5-.5.5h-4c-.276 0-.5-.22-.5-.5zm.5 1.5c-.276 0-.5.22-.5.5s.224.5.5.5h4c.276 0 .5-.22.5-.5s-.224-.5-.5-.5h-4zm.5 2.5c0-.28.224-.5.5-.5h2c.276 0 .5.22.5.5s-.224.5-.5.5h-2c-.276 0-.5-.22-.5-.5z"
    />
  </SvgIcon>
)

export default Bulb
