import config from 'services/config'
import { map, redirect, Matcher } from 'navi'
import { RouterContext } from 'components/Router'

const createTenantOnly = (
  tenantIds: typeof config['tenantId'][],
  redirectTo: string,
  redirectOptions?: { exact?: boolean },
) => (matcher: Matcher<RouterContext>) => {
  return map<RouterContext>(() => {
    if (!tenantIds.includes(config.tenantId)) {
      return redirect(redirectTo, redirectOptions)
    }

    return matcher
  })
}

export default createTenantOnly
