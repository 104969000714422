import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const TicketFull: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="24"
    height="18"
    viewBox="0 0 24 18"
    {...props}
  >
    <g
      fill="inherit"
      fillRule="evenodd"
      transform="translate(-27 -20)"
    >
      <path d="M44.1458333,26 C43.8123333,26 43.5416667,25.736 43.5416667,25.4 L43.5416667,23 C43.5416667,22.664 43.8123333,22.4 44.1458333,22.4 C44.4805417,22.4 44.75,22.664 44.75,23 L44.75,25.4 C44.75,25.736 44.4805417,26 44.1458333,26 Z M44.1458333,30.8 C43.8123333,30.8 43.5416667,30.536 43.5416667,30.2 L43.5416667,27.8 C43.5416667,27.464 43.8123333,27.2 44.1458333,27.2 C44.4805417,27.2 44.75,27.464 44.75,27.8 L44.75,30.2 C44.75,30.536 44.4805417,30.8 44.1458333,30.8 Z M44.1458333,35.6 C43.8123333,35.6 43.5416667,35.336 43.5416667,35 L43.5416667,32.6 C43.5416667,32.264 43.8123333,32 44.1458333,32 C44.4805417,32 44.75,32.264 44.75,32.6 L44.75,35 C44.75,35.336 44.4805417,35.6 44.1458333,35.6 Z M34.4583333,25.4 C34.4583333,25.736 34.188875,26 33.8541667,26 C33.5206667,26 33.25,25.736 33.25,25.4 L33.25,23 C33.25,22.664 33.5206667,22.4 33.8541667,22.4 C34.188875,22.4 34.4583333,22.664 34.4583333,23 L34.4583333,25.4 L34.4583333,25.4 Z M34.4583333,30.2 C34.4583333,30.536 34.188875,30.8 33.8541667,30.8 C33.5206667,30.8 33.25,30.536 33.25,30.2 L33.25,27.8 C33.25,27.464 33.5206667,27.2 33.8541667,27.2 C34.188875,27.2 34.4583333,27.464 34.4583333,27.8 L34.4583333,30.2 L34.4583333,30.2 Z M34.4583333,35 C34.4583333,35.336 34.188875,35.6 33.8541667,35.6 C33.5206667,35.6 33.25,35.336 33.25,35 L33.25,32.6 C33.25,32.264 33.5206667,32 33.8541667,32 C34.188875,32 34.4583333,32.264 34.4583333,32.6 L34.4583333,35 L34.4583333,35 Z M50.3958333,26 C50.7305417,26 51,25.736 51,25.4 L51,20.6 C51,20.264 50.7305417,20 50.3958333,20 L27.6041667,20 C27.2706667,20 27,20.264 27,20.6 L27,25.4 C27,25.736 27.2706667,26 27.6041667,26 C29.2704583,26 30.625,27.344 30.625,29 C30.625,30.656 29.2704583,32 27.6041667,32 C27.2706667,32 27,32.264 27,32.6 L27,37.4 C27,37.736 27.2706667,38 27.6041667,38 L50.3958333,38 C50.7305417,38 51,37.736 51,37.4 L51,32.6 C51,32.264 50.7305417,32 50.3958333,32 C48.7295417,32 47.375,30.656 47.375,29 C47.375,27.344 48.7295417,26 50.3958333,26 L50.3958333,26 Z" />
    </g>
  </SvgIcon>
)

export default TicketFull
