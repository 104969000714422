import config from 'services/config'
import { ComponentType, LazyExoticComponent } from 'react'

type LazyMap<T extends ComponentType<any>> = {
  [key in typeof config['tenantId']]?: LazyExoticComponent<T>
}

const tenantSwitch = <T extends ComponentType<any>>(imports: LazyMap<T>, tenantId = config.tenantId) => {
  return imports[tenantId] ?? ((() => null) as unknown as LazyExoticComponent<T>)
}

export default tenantSwitch
