import { IGameMember, IPrediction } from 'protos'

export type UserPredictionMap = { [userId: string]: IPrediction | null }
type Mapper = (props: { members: IGameMember[], selectedMatchId: string }) => UserPredictionMap

const mapPredictionsToMembers: Mapper = ({ members, selectedMatchId }) => {
  return members.reduce((predMap, member) => ({
    ...predMap,
    [member.user!.userId!]: member.prediction!.find((pred) => (
      pred.matchId === selectedMatchId
    )) || null,
  }), {} as UserPredictionMap)
}

export default mapPredictionsToMembers
