import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Next: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="10"
    height="17"
    viewBox="0 0 10 17"
    {...props}
  >
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M6.765 8.179L.59 14.353a.801.801 0 0 0 .008 1.134l.49.49a.81.81 0 0 0 1.134.008L8.828 9.38a.82.82 0 0 0 .156-.123l.49-.49a.805.805 0 0 0 .233-.5.81.81 0 0 0-.232-.676l-.49-.49a.8.8 0 0 0-.155-.12L2.222.372A.801.801 0 0 0 1.088.38l-.49.49A.81.81 0 0 0 .59 2.004L6.765 8.18z"
    />
  </SvgIcon>
)

export default Next
