import React, { FC } from 'react'
import { useIntl } from 'hooks'

type Props = {
  timestamp: number
  options?: Intl.DateTimeFormatOptions
}

const FormattedDate: FC<Props> = ({ timestamp, options }) => {
  const { d } = useIntl()

  return (
    <>
      {d(timestamp, options)}
    </>
  )
}

FormattedDate.defaultProps = {
  options: {
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  },
}

export default FormattedDate
