import { createBrowserHistory, History } from 'history'

export interface HistoryWithDepth extends History {
  getDepth: () => number
}

const createBrowserHistoryWithDepth = (): HistoryWithDepth => {
  let depth = 1

  const history = {
    ...createBrowserHistory(),
    getDepth: () => depth,
  }

  history.listen((_, action) => {
    switch (action) {
      case 'PUSH':
        depth++
        break
      case 'POP':
        depth--
        break
    }
  })

  return history
}

export default createBrowserHistoryWithDepth
