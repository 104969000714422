import React, { FC } from 'react'
import { IGameDetails } from 'protos'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Box, Paper } from 'tackl-material'
import CreditGamePrizeDistribution from './CreditGamePrizeDistribution'
import DrinkGamePrizeDistribution from './DrinkGamePrizeDistribution'

type Props = {
  gameDetails: IGameDetails
}

const useStyles = makeStyles<Theme>(({ palette, shadows, spacing }) => ({
  paperWithTip: {
    minWidth: 250,
    maxWidth: 300,
    position: 'relative',
    overflow: 'visible',
    filter: `drop-shadow(${shadows[2]})`,

    '&:after': {
      content: '" "',
      bottom: '100%',
      right: spacing(1),
      position: 'absolute',
      height: 0,
      width: 0,
      display: 'block',
      border: 'solid transparent',
      borderBottomColor: palette.background.paper,
      borderWidth: 8,
      marginLeft: -8,
    },
  },
}))

const GamePrizeDistributionTooltip: FC<Props> = ({ gameDetails }) => {
  const classes = useStyles()

  return (
    <Paper
      elevation={1}
      square={false}
      className={classes.paperWithTip}
    >
      <Box p={2}>
        {
          gameDetails.drinkPrize
            ? (
              <DrinkGamePrizeDistribution
                gameState={gameDetails.game!.state!}
                drinkPrizeDetails={gameDetails.drinkPrize}
              />
            )
            : <CreditGamePrizeDistribution gameDetails={gameDetails} />
        }
      </Box>
    </Paper>
  )
}

export default GamePrizeDistributionTooltip
