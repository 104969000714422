import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Up: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="15"
    height="9"
    viewBox="0 0 15 9"
    {...props}
  >
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M7.354 2.595l5.44 5.342a.715.715 0 0 0 1 .005l.426-.42a.686.686 0 0 0-.005-.98L7.914.355a.72.72 0 0 0-.565-.203.72.72 0 0 0-.555.203l-6.3 6.187a.684.684 0 0 0-.006.98l.427.42a.718.718 0 0 0 .999-.005l5.44-5.342z"
    />
  </SvgIcon>
)

export default Up
