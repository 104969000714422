import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const User: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      fill="inherit"
      fillRule="evenodd"
    >
      <path d="M11.5 16.425c-2.36 0-4.456-1.91-5.326-4.801-.792-.046-1.382-1.062-1.382-2.4 0-.43.065-.85.187-1.216a.48.48 0 01.909.303c-.09.27-.138.585-.138.914 0 .929.36 1.44.48 1.44a.514.514 0 01.449-.048.483.483 0 01.3.317c.67 2.71 2.487 4.53 4.521 4.53 2.035 0 3.852-1.82 4.522-4.53a.479.479 0 01.72-.292c.164.022.508-.489.508-1.417 0-.329-.047-.644-.136-.913a.48.48 0 11.908-.304c.122.365.186.786.186 1.217 0 1.337-.589 2.353-1.38 2.399-.87 2.89-2.968 4.8-5.328 4.8" />
      <path d="M6.922 15.375a.477.477 0 00-.274.012l-2.464.857A6.245 6.245 0 000 22.14v.705c0 .265.214.48.479.48h14.214c-.205-.179-.373-.372-.373-.574v-2.14c0-.083.054-.155.13-.172l1.349-.358a.109.109 0 01.082 0l1.35.358c.075.017.128.09.128.171v2.141c0 .202-.168.395-.373.574h5.535a.479.479 0 00.479-.48v-.705a6.243 6.243 0 00-4.182-5.895l-2.466-.857a.48.48 0 00-.51.129c-1.209 1.32-2.75 2.048-4.342 2.048-1.59 0-3.133-.727-4.342-2.048a.483.483 0 00-.236-.141zm8.877 5.441l-.676.178a.173.173 0 00-.127.172v.871c0 .1.075.178.168.178h.506v.822c0 .064.03.12.08.152a.163.163 0 00.09.026c.025 0 .05-.003.074-.018.308-.157.58-.336.713-.46a.19.19 0 00.057-.133v-.567c0-.1-.075-.18-.168-.18h-.508v-.869a.182.182 0 00-.065-.14.153.153 0 00-.144-.032zM11.5 0C7.802 0 4.792 3.014 4.792 6.72c0 .445.055.902.173 1.439.048.22.243.376.468.376.542 0 1.198.098 1.204.1a.484.484 0 00.54-.64c-.058-.958.003-1.927.136-2.235h.073c1.658 0 2.483-.526 2.864-1.191 1.033 1.021 3.111 2.006 5.12 2.137.114.806.466 1.609.484 1.649a.48.48 0 00.438.285l.039-.001 1.276-.105a.48.48 0 00.429-.375c.118-.537.172-.994.172-1.439C18.208 3.014 15.2 0 11.5 0" />
      <path d="M10.063 9.6h-.959a.48.48 0 010-.96h.959a.48.48 0 110 .96M13.896 9.6h-.959a.48.48 0 010-.96h.959a.48.48 0 110 .96" />
    </g>
  </SvgIcon>
)

export default User
