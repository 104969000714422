import React, { FC, ComponentProps } from 'react'
import { Search as SearchIcon } from '@material-ui/icons'
import { TextField } from './'

const SearchField: FC<ComponentProps<typeof TextField>> = props => (
  <TextField
    {...props}
    type="search"
    spacing="auto"
    label={<SearchIcon />}
  />
)

export default SearchField
