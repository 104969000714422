import React, { FC, ComponentProps } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme, Divider } from '@material-ui/core'
import { usePageViewAnalytics } from 'hooks'
import { Game, IMatch, IMatchTimeline } from 'protos'
import { MatchDetailsHeader, DetailsDrawer } from 'components'
import { MatchTimeline } from './components'

type Props = ComponentProps<typeof DetailsDrawer> & {
  match: IMatch
  timeline?: IMatchTimeline
  isTimelineLoading?: boolean
  gameType?: Game.GameType | null
}

const useStyles = makeStyles<Theme>(({ spacing, breakpoints }) => ({
  paper: {
    height: '75vh',
    [breakpoints.up('md')]: {
      width: '33%',
      height: '100%',
    },
  },
  banner: {
    overflow: 'initial',
    marginTop: spacing(4) * -1,
    pointerEvents: 'none',
  },
}))

const MatchDetailsDrawer: FC<Props> = ({
  match,
  timeline,
  gameType,
  isTimelineLoading,
  ...props
}) => {
  usePageViewAnalytics('Game_MatchDetails', !!props.open)

  const classes = useStyles()

  return (
    <DetailsDrawer
      {...props}
      elevation={1}
      closeMethod="button"
      classes={{ paper: classes.paper }}
    >
      <MatchDetailsHeader
        match={match}
        className={classes.banner}
      />
      <Divider />
      <MatchTimeline
        timeline={timeline}
        gameType={gameType}
        matchState={match.state}
        betOffer={match.betOffer}
        isLoading={isTimelineLoading}
      />
    </DetailsDrawer>
  )
}

export default MatchDetailsDrawer
