import React, { FC, ReactNode } from 'react'
import clsx from 'clsx'

import { makeStyles, Theme, ButtonBase, Grid } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

import inactiveChatIconUrl from '../../assets/chat_inactive.svg'
import activeChatIconUrl from '../../assets/chat_active.svg'

interface Props {
  open?: boolean
  active?: boolean
  title: ReactNode
  onClick: () => void
}

export const height = 40

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    height,
    width: '100%',
    display: 'block',
    textAlign: 'left',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 7px 7px -7px rgba(0, 0, 0, 0.25)',
  },
  disabled: {
    opacity: 0.5,
  },
  container: {
    height,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

const GameChatBar: FC<Props> = ({
  active,
  open,
  title,
  onClick,
}) => {
  const classes = useStyles()

  return (
    <ButtonBase
      classes={{ root: classes.root }}
      disabled={!active}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    >
      <Grid
        container
        alignItems="center"
        className={clsx(classes.container, { [classes.disabled]: !active })}
      >
        <img
          src={active ? activeChatIconUrl : inactiveChatIconUrl}
          className={classes.icon}
          alt="Chat bubble"
        />
        <Grid
          item
          xs
        >
          {title}
        </Grid>
        {open ? <ExpandMore /> : <ExpandLess />}
      </Grid>
    </ButtonBase>
  )
}

export default GameChatBar
