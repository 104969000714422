import React, { FC, useEffect, useCallback, useState, useRef } from 'react'
import { IMatch } from 'protos'
import { MatchBanner } from 'components'
import { useDrag } from 'react-use-gesture'
import { useWindowSize, usePrevious } from 'hooks'
import { animated, useSprings } from 'react-spring'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { PredictionMap, getPredictedScore } from './stateReducer'

type Props = {
  matches: IMatch[]
  skipped?: number
  predictions: PredictionMap
  onRequestNextStep: () => void
  baseZIndex?: number
}

const useStyles = makeStyles<Theme>(({ shadows, palette }) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    position: 'relative',
  },
  item: {
    width: '100%',
    minHeight: 50,
    borderRadius: 5,
    overflow: 'hidden',
    userSelect: 'none',
    touchAction: 'none',
    position: 'absolute',
    boxShadow: shadows[2],
    willChange: 'transform, opacity',
    backgroundColor: palette.common.white,
  },
}))

const MatchList: FC<Props> = ({ matches = [], predictions, onRequestNextStep, skipped = 0, baseZIndex = 0 }) => {
  const classes = useStyles()
  const previousSkipped = usePrevious(skipped)
  const { height: windowHeight } = useWindowSize()

  const [renderIndex, setRenderIndex] = useState(skipped)
  const [currentMatchIndex, setCurrentMatchIndex] = useState(skipped)

  const lastCurrentMatchIndexRef = useRef(currentMatchIndex)
  useEffect(() => { lastCurrentMatchIndexRef.current = currentMatchIndex }, [currentMatchIndex])

  const onRest = useCallback(() => setRenderIndex(lastCurrentMatchIndexRef.current), [])

  const setTransition = useCallback((i: number) => {
    const delta = i - currentMatchIndex
    const isGone = i < currentMatchIndex
    const isVisible = delta >= -1 && delta < 3

    return {
      config: {
        clamp: true,
        tension: 400,
      },
      onRest,
      opacity: Number(isVisible),
      topScale: isGone ? [windowHeight, 1] : [15 * delta, 1 - delta * 0.05],
    }
  }, [currentMatchIndex, onRest, windowHeight])

  const [springProps, setSpringProps] = useSprings(matches.length, setTransition)

  const bindDragProps = useDrag(({
    down,
    velocity,
    args: [index],
    movement: [_, verticalMovement],
  }) => {
    if (down) {
      setSpringProps(i => i === index ? { topScale: [verticalMovement, 1] } : {})
    } else {
      if (velocity > 0.1) {
        setCurrentMatchIndex(currentMatchIndex => currentMatchIndex + 1)
      } else {
        setSpringProps(setTransition)
      }
    }
  }, {
    axis: 'y',
    bounds: { top: 0 },
  })

  useEffect(() => {
    setSpringProps(setTransition)
  }, [setSpringProps, setTransition])

  useEffect(() => {
    if (!!previousSkipped && !skipped) {
      setRenderIndex(0)
      setCurrentMatchIndex(0)
    } else if (renderIndex > skipped) {
      onRequestNextStep()
    } else if (skipped > renderIndex) {
      setCurrentMatchIndex(skipped)
    }
  }, [onRequestNextStep, matches.length, renderIndex, skipped, previousSkipped])

  if (!matches.length) {
    return null
  }

  return (
    <div className={classes.root}>
      {springProps.map(({ topScale, opacity }, i) => {
        if (i < renderIndex || i > renderIndex + 3) {
          return null
        }

        const style = {
          opacity,
          zIndex: baseZIndex + matches.length + 1 - i,
          transform: topScale.to((top, scale) => `translate3d(0, ${top}px, 0) scale(${scale})`),
        }

        return (
          <animated.div
            style={style as any /* WORKAROUND */}
            {...bindDragProps(i)}
            key={`match-card-${i}`}
            className={classes.item}
          >
            <MatchBanner
              match={matches[i]}
              variant="overflowing"
              {...getPredictedScore(predictions, matches[i].matchId!)}
            />
          </animated.div>
        )
      })}
    </div>
  )
}

export default MatchList
