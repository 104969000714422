import { useEffect } from 'react'

export default function useNavigationPrompt() {
  useEffect(() => {
    window.onbeforeunload = function () {
      return ''
    }

    return () => {
      window.onbeforeunload = null
    }
  })
}
