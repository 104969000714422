import React, { FC, ComponentProps } from 'react'
import Container from './Container'

type Props = ComponentProps<typeof Container>

const ContentContainer: FC<Props> = (props) => (
  <Container
    maxWidth="xs"
    {...props}
  />
)

export default ContentContainer
