import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const GameTypePublic: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M.842 8c0-2.164.972-4.11 2.5-5.423.166.067.353.084.49.1.063 0 .155.018.22.026.032.169-.023.556-.053.783-.051.362-.1.708-.034.986.104.437.496 1.145.727 1.465.353.488 1.03.985 1.746 1.507.897.657 2 1.457 2.12 2.097-.04.025-.09.059-.128.084-.22.143-.522.337-.522.682 0 .607.617 1.036 1.215 1.457.304.21.722.505.794.682.075.177.066.767-.013 1.036-.093.303-.672 1.095-1.11 1.625-.26.034-.525.05-.794.05A7.164 7.164 0 0 1 .842 8zm14.316 0c0 .75-.117 1.474-.331 2.147-.032-.303-.098-.572-.225-.75-.274-.378-.844-.48-1.394-.589-.26-.042-.584-.1-.7-.176-.039-.026-.13-.144-.19-.21-.155-.195-.316-.388-.532-.48-.279-.119-.722-.094-1.202-.035-.355.05-.745.042-.775.034-.609-.253-1.415-.867-1.67-1.238l.116.025c.35.076.72.127.955-.016.502-.312.767-1.061 1.024-1.777.136-.388.277-.783.408-.96a1.12 1.12 0 0 1 .187-.143c.26-.186.618-.438.553-.843-.08-.488-.61-.648-1.078-.783-.14-.042-.354-.11-.405-.143-.03-.025-.1-.126-.147-.185-.18-.253-.405-.556-.763-.556-.346 0-.541.295-.683.514a.801.801 0 0 1-.03.05 1.175 1.175 0 0 1-.216-.311c-.048-.11-.013-.522.005-.733C11.98.884 15.158 4.076 15.158 8zm-.531 4.48A7.964 7.964 0 0 0 16 8c0-4.413-3.59-8-8-8-.086 0-.168.008-.253.017a.323.323 0 0 0-.179.008C3.358.245 0 3.731 0 8c0 4.413 3.59 8 8 8 .34 0 .671-.025 1-.067h.01c.01 0 .02-.009.03-.009a7.979 7.979 0 0 0 5.516-3.351c.028-.034.052-.06.07-.093z"
    />
  </SvgIcon>
)

export default GameTypePublic
