import React, { FC, ComponentProps, ComponentType } from 'react'
import clsx from 'clsx'
import { Theme } from '@material-ui/core'
import { ButtonBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

interface Props extends ComponentProps<typeof ButtonBase> {
  component?: ComponentType | keyof HTMLElementTagNameMap
  size?: 'small' | 'medium'
  variant?: 'default' | 'primary' | 'secondary'
}

const useStyles = makeStyles<Theme>(({ palette }) => ({
  root: {
    width: 42,
    height: 42,
    padding: 0,
    borderWidth: 1,
    borderRadius: 21,
    borderStyle: 'solid',
    borderColor: palette.grey[400],
    color: palette.grey[500],
    backgroundColor: palette.grey[300],
  },
  sizeSmall: {
    width: 30,
    height: 30,
    fontSize: 30 / 2,
  },
  variantPrimary: {
    borderWidth: 3,
    color: palette.accent[50].contrastText,
    borderColor: palette.accent[50].contrastText,
    backgroundColor: palette.accent[50].main,
  },
  variantSecondary: {
    color: palette.neutral[50].contrastText,
    backgroundColor: palette.neutral[50].main,
  },
}))

const preventDefaults = (onClick: Props['onClick']) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  if (onClick) {
    e.preventDefault()
    e.stopPropagation()
    onClick(e)
  }
}

const ActionButton: FC<Props> = ({
  onClick,
  className,
  size = 'medium',
  variant = 'default',
  ...props
}) => {
  const classes = useStyles()

  return (
    <ButtonBase
      {...props}
      onClick={preventDefaults(onClick)}
      className={clsx(className, classes.root, {
        [classes.sizeSmall]: size === 'small',
        [classes.variantPrimary]: variant === 'primary',
        [classes.variantSecondary]: variant === 'secondary',
      })}
    />
  )
}

export default ActionButton
