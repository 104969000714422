import React, { ComponentProps } from 'react'
import { Link } from 'react-navi'

type Props = ComponentProps<typeof Link> & {
  showLabel?: boolean
}

const NaviLink = React.forwardRef<HTMLAnchorElement, Props>(({ showLabel, ...props }, ref) => (
  <Link
    {...props}
    ref={ref}
  />
))

export default NaviLink
