import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Drag: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="13"
    height="5"
    viewBox="0 0 13 5"
    {...props}
  >
    <g>
      <rect
        width="13"
        height="1.5"
      />
      <rect
        y="4"
        width="13"
        height="1.5"
      />
    </g>
  </SvgIcon>
)

export default Drag
