import React, { FC } from 'react'
import { ButtonBase, Grid, InputBase, makeStyles, Theme } from '@material-ui/core'

import inactiveSendIconUrl from '../../assets/send_inactive.svg'
import activeSendIconUrl from '../../assets/send_active.svg'

interface Props {
  value: string
  onChange: (value: string) => void
  onSend: () => void
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    padding: theme.spacing(1, 2),
    borderTop: '1px solid #f0f0f0',
  },
  inputRoot: {
    padding: 0,
    flex: 1,
    '& > div': {
      display: 'flex',
    },
  },
  inputInput: {
    border: '1px solid #eee',
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(1),
  },
  sendIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const GameChatInput: FC<Props> = ({ value, onChange, onSend }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      alignItems="flex-end"
      className={classes.root}
    >
      <InputBase
        placeholder="Comment..." // TODO: where is this?
        value={value}
        onChange={ev => { onChange(ev.target.value) }}
        margin="none"
        rowsMax="4"
        multiline
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
      />
      <ButtonBase
        disabled={value.length === 0}
        onClick={onSend}
        className={classes.sendIcon}
      >
        <img
          src={value.length ? activeSendIconUrl : inactiveSendIconUrl}
          alt="Send"
        />
      </ButtonBase>
    </Grid>
  )
}

export default GameChatInput
