import React, { FC, ComponentProps } from 'react'

import { SvgIcon } from '@material-ui/core'

const Tackl: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="45"
    height="16"
    viewBox="0 0 45 16"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.28.5a1.6 1.6 0 00-1.62 1.61c0 .89.7 1.6 1.62 1.6a1.6 1.6 0 001.63-1.6c0-.89-.7-1.6-1.63-1.6zm0 2.87c-.71 0-1.25-.57-1.25-1.26 0-.69.54-1.25 1.25-1.25.72 0 1.25.56 1.25 1.25 0 .7-.53 1.26-1.25 1.26zm-.63-2.07v1.62h.4V2.3h.2l.34.62h.46l-.38-.65c.19-.07.32-.23.32-.47 0-.32-.24-.5-.54-.5h-.8zm.4.31h.3c.14 0 .24.07.24.21 0 .13-.1.2-.24.2h-.3v-.4zM.1 7.98H1.9v5.05c0 1.76.93 2.74 2.7 2.74.47 0 .88-.05 1.31-.15l.18-.04v-1.74l-.27.05c-.24.04-.42.06-.66.06-.97 0-1.22-.3-1.22-1.32V7.98h2.15V6.2H3.93V2.23l-.37.27-1.58 1.15-.1.07V6.2H.12v1.78zm34.78-1.64V12.94c0 1.76.93 2.74 2.71 2.74.47 0 .87-.05 1.3-.15l.18-.04v-1.74l-.27.05c-.24.04-.42.06-.66.06-.96 0-1.22-.3-1.22-1.32V4.32l-.1-.06-1.57-1.16-.37-.27v3.51zM20.9 15.77c-2.79 0-4.57-1.98-4.57-4.83 0-2.89 1.78-4.92 4.55-4.92 1.72 0 3.03.8 3.79 2.22l.1.2-.2.1-1.3.75-.22.12-.1-.21c-.47-.9-1.15-1.37-2.1-1.37-1.52 0-2.46 1.24-2.46 3.08 0 1.83.94 3.06 2.56 3.06 1 0 1.68-.46 2.12-1.32l.11-.22.21.12 1.25.71.2.11-.1.2a4.1 4.1 0 01-3.84 2.2zm10.56-.18h2.48l-.25-.36-3.1-4.5 2.95-4.16.26-.37h-2.45l-.07.1-2.4 3.53h-1V3.06H25.81V15.59h2.04V11.6h.93l2.6 3.89.06.1zm-16.47-.23v.23H12.97v-.22l-.02-.65a3.25 3.25 0 01-2.51 1.05c-1.86 0-3.11-1.2-3.11-2.92 0-1.26.65-2.13 1.89-2.53.83-.27 1.81-.37 3.73-.44v-.57c0-.88-.63-1.5-1.75-1.5-.97 0-1.57.42-1.84 1.2l-.09.24-.23-.11-1.32-.64-.19-.09.08-.2c.54-1.4 1.88-2.19 3.64-2.19 2.29 0 3.73 1.24 3.73 3.29v6.05zm-4.36-1.26c-.9 0-1.44-.48-1.44-1.21 0-.8.64-1.05 1.52-1.17a25 25 0 012.17-.16v.45c0 1.62-1 2.1-2.25 2.1z"
    />
  </SvgIcon>
)

export default Tackl
