import React, { FormEvent, ComponentProps, forwardRef } from 'react'

export type FormValues = { [key: string]: string }

interface FormProps extends Omit<ComponentProps<'form'>, 'onSubmit'> {
  onSubmit?: (e: FormEvent<HTMLFormElement>, values: FormValues) => void
}

const formDataToObject = (formData: FormData): FormValues =>
  [...formData.entries()].reduce(
    (o, [key, value]) => ({
      ...o,
      [key]: value || null,
    }),
    {},
  )

const Form = forwardRef<HTMLFormElement, FormProps>(({ onSubmit, ...props }, ref) => (
  <form
    {...props}
    ref={ref}
    onSubmit={(e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      const formData = new FormData(e.currentTarget)
      const values = formDataToObject(formData)

      if (onSubmit) {
        onSubmit(e, values)
      }
    }}
  />
))

export default Form
