import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Email: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="52"
    height="48"
    viewBox="0 0 52 48"
    {...props}
  >
    <g
      fill="inherit"
      fillRule="evenodd"
    >
      <path d="M2.84 41c-.282 0-.565-.095-.788-.308a44.058 44.058 0 0 1-1.712-1.68 1.18 1.18 0 0 1 0-1.657 1.13 1.13 0 0 1 1.644 0 58.04 58.04 0 0 0 1.643 1.586c.471.45.499 1.207.067 1.68-.23.26-.544.379-.855.379M9.212 45.932c-.177 0-.353-.043-.52-.108a33.2 33.2 0 0 1-3.206-1.874 1.028 1.028 0 0 1-.283-1.486c.357-.495 1.062-.603 1.57-.28.995.668 1.991 1.25 2.96 1.723.554.28.769.927.482 1.444a1.143 1.143 0 0 1-1.003.581zM17.307 48a16.79 16.79 0 0 1-3.249-.323c-.612-.108-1.005-.69-.881-1.271.126-.582.73-.948 1.335-.84.94.194 1.88.28 2.795.28h.522c.734-.043 1.148.43 1.17 1.034.023.581-.465 1.077-1.087 1.098l-.59.022h-.015zM23.055 47c-.41 0-.798-.228-.97-.621a1.039 1.039 0 0 1 .556-1.367c.587-.227 1.178-.538 1.767-.87.5-.29 1.146-.124 1.446.373.295.477.129 1.118-.374 1.408-.652.373-1.328.725-2.01.994a.982.982 0 0 1-.415.083M30.873 36l-4.65 6.202a1.127 1.127 0 0 0 .225 1.573c.202.158.44.225.673.225.34 0 .678-.157.898-.45L33 36.9 30.873 36M51.53.198a1.084 1.084 0 0 0-1.182-.044l-41.8 24.445a1.093 1.093 0 0 0-.542 1.067c.042.444.335.8.746.933l12.516 4.223a1.11 1.11 0 0 0 1.127-.267L43.522 9.221a1.087 1.087 0 0 1 1.498-.067c.435.378.508 1.045.16 1.511L29.246 32.133c-.43.578-.207 1.4.448 1.689l14.173 6.09A.992.992 0 0 0 44.3 40c.18 0 .361-.044.522-.133.297-.156.504-.445.56-.778l6.601-37.78A1.125 1.125 0 0 0 51.53.2" />
    </g>
  </SvgIcon>
)

export default Email
