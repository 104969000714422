import React, { FC } from 'react'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { CenteredBox } from 'tackl-material'
import { Add, Checkmark } from 'tackl-material/Icons'
import { ActionProps } from './propTypes'

type Props = ActionProps

const useStyles = makeStyles<Theme>(({ palette }) => ({
  action: {
    width: 30,
    height: 30,
    borderRadius: 15,
    fontSize: '.9rem',
    color: palette.primary.contrastText,
    backgroundColor: palette.primary.main,
    border: `1px solid ${palette.divider}`,
  },
  selected: {
    borderColor: palette.common.white,
    color: palette.brand.contrastText,
    backgroundColor: palette.brand.main,
  },
}))

const MatchCardToggleActions: FC<Props> = ({ isSelected }) => {
  const classes = useStyles()

  return (
    <CenteredBox
      mx={1.5}
      className={clsx(classes.action, {
        [classes.selected]: isSelected,
      })}
    >
      {
        isSelected
          ? <Checkmark fontSize="inherit" />
          : <Add fontSize="inherit" />
      }
    </CenteredBox>
  )
}

export default MatchCardToggleActions
