/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FC,
  memo,
  useMemo,
  useState,
  useEffect,
  useCallback,
  ComponentType,
  CSSProperties,
} from 'react'

import { List } from '@material-ui/core'
import throttle from 'lodash.throttle'
import ResizeObserver from 'resize-observer-polyfill'

interface Props<T = any> {
  items: T[]
  itemKey: (item: T, index: number) => string | number
  itemHeight: (item: T, index: number) => number
  itemComponent: ComponentType<ItemComponentProps<T>>
  component?: 'div' | typeof List
  offsetTop?: number
  className?: string
  container?: HTMLDivElement | null
  offsetBottom?: number
  scrollThrottle?: number
  resizeThrottle?: number
}

interface ItemProps<T = any> {
  data: T
  height: number
  isVisible: boolean
  itemComponent: ComponentType<ItemComponentProps<T>>
}

export interface ItemComponentProps<T = any> {
  data: T
  style: CSSProperties
}

const isItemInViewport = (
  top: number,
  bottom: number,
  offsetTop: number,
  windowHeight: number,
  offsetBottom: number,
  scrollPosition: number,
) => {
  const boundaryTop = scrollPosition + offsetTop
  const boundaryHeight = windowHeight - offsetTop - offsetBottom
  const boundaryBottom = boundaryTop + boundaryHeight
  const isTopInBoundary = boundaryTop < top && top < boundaryBottom
  const isBottomInBoundary = boundaryTop < bottom && bottom < boundaryBottom

  return isTopInBoundary || isBottomInBoundary
}

const VirtualListItem: FC<ItemProps> = ({
  data,
  height,
  isVisible,
  itemComponent: Component,
}) => {
  if (!isVisible) {
    return (
      <div style={{ height }} />
    )
  }

  return (
    <Component
      data={data}
      style={{ height }}
    />
  )
}

const VirtualListItemMemoized = memo(VirtualListItem)

const VirtualList: FC<Props> = ({
  items,
  itemKey,
  offsetTop = 0,
  container,
  itemHeight,
  offsetBottom = 0,
  itemComponent,
  scrollThrottle,
  resizeThrottle,
  component: Component = 'div',
  ...props
}) => {
  const [windowHeight, setWindowHeight] = useState(container ? container.clientHeight : window.innerHeight)
  const [scrollPosition, setScrollPosition] = useState(container ? container.scrollTop : window.scrollY)

  const windowResizeHandler = useCallback(throttle(() => {
    setWindowHeight(container ? container.clientHeight : window.innerHeight)
  }, resizeThrottle), [container])

  const windowScrollHandler = useCallback(throttle(() => {
    setScrollPosition(container ? container.scrollTop : window.scrollY)
  }, scrollThrottle), [container])

  useEffect(() => {
    const element = container ?? window
    const resizeObserver = new ResizeObserver(windowResizeHandler)

    if (element === window) {
      element.addEventListener('resize', windowResizeHandler)
    } else {
      resizeObserver.observe(element as HTMLDivElement)
    }

    element.addEventListener('scroll', windowScrollHandler)

    windowResizeHandler()
    windowScrollHandler()

    return () => {
      if (element === window) {
        element.removeEventListener('resize', windowResizeHandler)
      } else {
        resizeObserver.disconnect()
      }

      element.removeEventListener('scroll', windowScrollHandler)
    }
  }, [container, windowResizeHandler, windowScrollHandler])

  const staticItemProps = useMemo(() => {
    const props: Array<{
      key: string | number,
      top: number,
      bottom: number,
      height: number,
    }> = []

    for (let i = 0; i < items.length; i++) {
      const top = i && props[i - 1].bottom
      const key = itemKey(items[i], i)
      const height = itemHeight(items[i], i)
      const bottom = i ? props[i - 1].bottom + height : height

      props[i] = { key, top, bottom, height }
    }

    return props
  }, [itemHeight, itemKey, items])

  return (
    <Component {...props}>
      {
        items.map((item, index) => {
          const { key, top, bottom, height } = staticItemProps[index]

          const isVisible = isItemInViewport(
            top,
            bottom,
            offsetTop,
            windowHeight,
            offsetBottom,
            scrollPosition,
          )

          return (
            <VirtualListItemMemoized
              key={key}
              data={item}
              height={height}
              isVisible={isVisible}
              itemComponent={itemComponent}
            />
          )
        })
      }
    </Component>
  )
}

VirtualList.defaultProps = {
  offsetTop: 0,
  component: 'div',
  offsetBottom: 0,
  scrollThrottle: 50,
  resizeThrottle: 50,
}

export default VirtualList
