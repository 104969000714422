import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Badge: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="46"
    height="53"
    viewBox="0 0 46 53"
    {...props}
  >
    <g fill="none">
      <path
        fill="currentColor"
        fillOpacity="0.2"
        d="M28.314 31.741l.911 5.473c.065.138.103.291.103.453a1.047 1.047 0 01-1.048 1.047.137.137 0 01-.042 0 1.032 1.032 0 01-.536-.148L23 35.745l-4.7 2.82a1.041 1.041 0 01-1.127-.032 1.05 1.05 0 01-.444-1.04l.96-5.753-3.81-3.809a1.046 1.046 0 01-.257-1.06 1.052 1.052 0 01.825-.715l5.706-.949 1.877-4.69c.314-.797 1.628-.797 1.942 0l1.877 4.69 5.706.95a1.046 1.046 0 01.568 1.775l-3.81 3.808zM23 3.141l19.905 5.974v6.191L23.302 9.425a1.051 1.051 0 00-.602 0L3.095 15.306V9.115L23 3.14zm.302-2.097a1.05 1.05 0 00-.602 0L1.748 7.33A1.05 1.05 0 001 8.333v23.048c0 9.265 14.128 17.506 21.69 19.859.1.031.207.046.31.046a1.053 1.053 0 00.312-.046C30.874 48.887 45 40.646 45 31.38V8.333c0-.46-.302-.87-.746-1.003L23.302 1.044z"
      />
      <path
        stroke="currentColor"
        strokeWidth="2"
        d="M28.314 31.741l.911 5.473c.065.138.103.291.103.453a1.047 1.047 0 01-1.048 1.047.137.137 0 01-.042 0 1.032 1.032 0 01-.536-.148L23 35.745l-4.7 2.82a1.041 1.041 0 01-1.127-.032 1.05 1.05 0 01-.444-1.04l.96-5.753-3.81-3.809a1.046 1.046 0 01-.257-1.06 1.052 1.052 0 01.825-.715l5.706-.949 1.877-4.69c.314-.797 1.628-.797 1.942 0l1.877 4.69 5.706.95a1.046 1.046 0 01.568 1.775l-3.81 3.808zM23.302 1.044a1.05 1.05 0 00-.602 0L1.748 7.33A1.05 1.05 0 001 8.333v23.048c0 9.265 14.128 17.506 21.69 19.859.1.031.207.046.31.046a1.053 1.053 0 00.312-.046C30.874 48.887 45 40.646 45 31.38V8.333c0-.46-.302-.87-.746-1.003L23.302 1.044zM23 1l22 6.623v8.517L23.302 9.425a1.051 1.051 0 00-.602 0L1 16.14V7.623L23 1z"
      />
    </g>
  </SvgIcon >
)

export default Badge
