import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const GameStateWin: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="21"
    height="17"
    viewBox="0 0 21 17"
    {...props}
  >
    <g
      transform="translate(-4)"
      fill="none"
      fillRule="evenodd"
    >
      <ellipse
        fill="currentColor"
        cx="14.5"
        cy="8.5"
        rx="3.774"
        ry="3.792"
      />
      <g
        stroke="currentColor"
        strokeWidth="1.2"
      >
        <path d="M21.016 15.83c4.038-4.038 4.022-10.601-.036-14.66M18.83 13.624c2.826-2.826 2.815-7.419-.025-10.258" />
      </g>
      <g
        stroke="currentColor"
        strokeWidth="1.2"
      >
        <path d="M7.777 15.83c-4.039-4.038-4.022-10.601.036-14.66M9.962 13.624c-2.825-2.826-2.814-7.419.026-10.258" />
      </g>
    </g>
  </SvgIcon>
)

export default GameStateWin
