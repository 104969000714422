import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Add: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="14"
    height="14"
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M7.811 7.811v5.23c0 .3-.235.53-.524.53h-.45a.527.527 0 0 1-.525-.53V7.689H.96a.522.522 0 0 1-.53-.524v-.45c0-.291.238-.525.53-.525h5.23V.96c0-.292.234-.53.524-.53h.45c.29 0 .525.23.525.53v5.352h5.352c.292 0 .53.234.53.524v.45c0 .29-.23.525-.53.525H7.81z"
    />
  </SvgIcon>
)

export default Add
