import React, { FC, ReactNode } from 'react'
import { Game, IGame, IGameDetails } from 'protos'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { List, ListItem, Grid } from '@material-ui/core'
import { Currency, FormattedMessage, Markdown } from 'components'
import { Box, Typography, Divider, DashedDivider, Avatar } from 'tackl-material'
import config from 'services/config'
import useFormatAmount from 'hooks/useFormatAmount'

type Props = {
  gameDetails: IGameDetails
}

const useStyles = makeStyles<Theme>(({ palette }) => ({
  darkDivider: {
    backgroundColor: palette.common.black,
  },
  condensedText: {
    lineHeight: '18px',
  },
}))

type ItemProps = {
  title: ReactNode
  subtitle?: ReactNode
  prize?: IGame['prizeValue']
}

const Item: FC<ItemProps> = ({ title, subtitle, prize }) => {
  const formatAmount = useFormatAmount()

  return (
    <ListItem disableGutters>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography
            variant="h3"
            align="right"
          >
            <Currency
              {...prize}
              amount={formatAmount(prize?.amount)}
            />
          </Typography>
        </Box>
        {
          !!subtitle && (
            <Box textAlign="right">
              <Typography
                alpha={.5}
                variant="h6"
              >
                {subtitle}
              </Typography>
            </Box>
          )
        }
      </Box>
    </ListItem>
  )
}

const CreditGamePrizeDistribution: FC<Props> = ({ gameDetails }) => {
  const classes = useStyles()

  const groupHost = (gameDetails.groupDetails && gameDetails.groupDetails.host) || undefined
  const groupTitle = (gameDetails.game && gameDetails.game.titleText) || ''
  const groupHostPhotoUrl = (groupHost && groupHost.user && groupHost.user.photoUrl) || ''
  const groupHostNickname = (groupHost && groupHost.user && groupHost.user.nickname) || ''
  const groupHostState = (groupHost && groupHost.state) || ''

  const { totalPot } = gameDetails
  const isBildLotto = config.tenantId === 'bild' && gameDetails.game!.gameType === Game.GameType.GT_LOTTERY

  return (
    <List>
      {gameDetails.groupDetails && (
        <Box mb={2}>
          <Box mb={1}>
            <Typography variant="h4">
              <Markdown>
                {groupTitle}
              </Markdown>
            </Typography>
          </Box>
          <DashedDivider />
          <Box my={1}>
            <Grid
              container
              alignItems="center"
            >
              <Grid item>
                <Box mr={1}>
                  <Avatar
                    src={groupHostPhotoUrl}
                    style={{ width: 32, height: 32 }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs
              >
                <Typography
                  variant="subtitle1"
                  className={classes.condensedText}
                >
                  {groupHostState}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.condensedText}
                >
                  <strong>
                    {groupHostNickname}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <DashedDivider />
        </Box>
      )}
      <Box mb={2}>
        <Typography variant="h5">
          <FormattedMessage
            id={
              isBildLotto
                ? 'web_bild_lotto_gamedetails_help_prizedistribution'
                : 'gamedetails_help_prizedistribution'
            }
          />
        </Typography>
      </Box>
      <Item
        title={<Markdown>{gameDetails.game!.prizeTitle}</Markdown>}
        prize={gameDetails.game!.prizeValue}
        subtitle={(
          <FormattedMessage
            id="game_prize_playermultiplier_format_plural"
            values={[gameDetails.game!.playerCount!]}
          />
        )}
      />

      {totalPot && (totalPot.custom || Number(totalPot.amount) > 0) && (
        <>
          <DashedDivider />

          <Box py={1}>
            <Item
              title={<FormattedMessage id="game_totalpot" />}
              prize={totalPot}
            />
          </Box>
        </>
      )}

      <Divider className={classes.darkDivider} />

      <Box pt={2}>
        {gameDetails.prizeDistribution!.map((prizeLevel, i) => (
          <Item
            title={prizeLevel.title}
            prize={prizeLevel.prize}
            key={prizeLevel.title || String(i)}
          />
        ))}
      </Box>

      {isBildLotto && (
        <Typography
          alpha={.5}
          variant="h6"
        >
          *nur die höchste Gewinnklasse gewinnt. Weitere Infos
          in den Teilnahmebedinungen
        </Typography>
      )}
    </List>
  )
}

export default CreditGamePrizeDistribution
