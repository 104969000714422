import React, { FC, ReactNode } from 'react'
import { Grid, makeStyles, Theme } from '@material-ui/core'
import { ContentContainer } from 'tackl-material'

type Props = {
  sideNode?: ReactNode
  mainNode?: ReactNode
}

const useStyles = makeStyles<Theme>(({ breakpoints, mixins, spacing }) => ({
  root: {
    [breakpoints.up('md')]: {
      ...mixins.gutters(),
      paddingTop: spacing(3),
      paddingBottom: spacing(3),
    },
  },
}))

const SplitLayout: FC<Props> = ({ sideNode, mainNode }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      className={classes.root}
    >
      <Grid
        item
        xs={12}
        md={3}
        lg={2}
      >
        {sideNode}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={8}
      >
        <ContentContainer>
          {mainNode as any /* WORKAROUND */}
        </ContentContainer>
      </Grid>
    </Grid>
  )
}

export default SplitLayout
