import React, { FC, ReactNode } from 'react'
import { ListItem } from '@material-ui/core'
import { MatchTimeline } from 'protos'
import { Box, CenteredBox, Typography } from 'tackl-material'

type ITimelineEvent =
  | MatchTimeline.ICardEvent
  | MatchTimeline.IScoreChangeEvent
  | MatchTimeline.ISubstitutionEvent

interface Props {
  event: ITimelineEvent
  leftNode: ReactNode
  rightNode: ReactNode
}

const TimelineListItem: FC<Props> = ({
  event,
  leftNode,
  rightNode,
}) => {
  return (
    <ListItem dense>
      <CenteredBox width="100%">
        <Box
          flex={1}
          display="flex"
          minWidth={0}
          justifyContent="flex-end"
        >
          {event.homeSide && leftNode}
        </Box>

        <Box>
          {event.clock && (
            <Typography align="center">
              {event.clock}
            </Typography>
          )}
        </Box>

        <Box
          flex={1}
          display="flex"
          minWidth={0}
        >
          {!event.homeSide && rightNode}
        </Box>
      </CenteredBox>
    </ListItem>
  )
}

export default TimelineListItem
