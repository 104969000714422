import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const CheckboxUnchecked: FC<ComponentProps<typeof SvgIcon>> = (props) => (
  <SvgIcon
    width="43"
    height="43"
    viewBox="0 0 43 43"
    {...props}
  >
    <g
      fill="none"
      fillRule="inherit"
    >
      <rect
        strokeOpacity="0.1"
        stroke="#000"
        fill="#FFF"
        width="41"
        height="41"
        rx="20"
        x="1"
        y="1"
      />
      <path
        d="M19.7879565,26.8088144 L19.7879565,26.8088144 L19.2366281,27.3601428 C18.6618804,27.9348906 17.7266423,27.9371633 17.1511095,27.3616305 L12.4313592,22.6418802 C11.8551567,22.0656777 11.8567767,21.1324319 12.432847,20.5563616 L12.9841753,20.0050333 C13.5589231,19.4302855 14.4941611,19.4280127 15.0696939,20.0035455 L19.7894442,24.7232958 C20.3656468,25.2994984 20.3640268,26.2327441 19.7879565,26.8088144 L19.7879565,26.8088144 Z M16.7122019,26.676993 C16.6201357,26.5849268 16.5428241,26.4836158 16.4802627,26.3760124 C16.1516962,25.8108895 16.2299796,25.072209 16.7144996,24.587689 L26.8678788,14.4343097 C27.4459199,13.8562686 28.380131,13.8549603 28.9571828,14.4320121 L29.5085112,14.9833404 C30.0841003,15.5589295 30.0829895,16.4958684 29.5062135,17.0726444 L19.3528343,27.2260237 C18.7747932,27.8040648 17.840582,27.8053732 17.2635302,27.2283214 L16.7122019,26.676993 Z"
        fill="#000"
        fillRule="nonzero"
        opacity="0.05"
      />
    </g>
  </SvgIcon>
)

export default CheckboxUnchecked
