import { Theme } from '@material-ui/core'
import { IGame, Game } from 'protos'

const getIndicatorColor = (game: IGame, palette: Theme['palette']) => {
  if (game.state === Game.State.GS_PENDING
    && game.gameType === Game.GameType.GT_PRIVATE_INVITED
  ) {
    return palette.brand
  }

  return palette.gameState[game.state || Game.State.GS_UNSET]
}

export default getIndicatorColor
