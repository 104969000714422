import React, { FC } from 'react'
import { BetOffer } from 'protos'
import { Grid } from '@material-ui/core'
import BetOfferMarket from './BetOfferMarket'
import BetOfferSponsorLogo from './BetOfferSponsorLogo'

interface Props {
  market1X2: BetOffer.IMarket1X2
  sponsor?: BetOffer.ISponsor | null
  variant?: 'light' | 'dark'
}

const BetOfferOdds: FC<Props> = ({ market1X2, sponsor, variant = 'light' }) => {
  return (
    <Grid
      container
      spacing={1}
      wrap="nowrap"
      justify="center"
      alignItems="center"
    >
      <Grid
        xs
        item
      />
      <Grid item>
        <BetOfferMarket market={market1X2.home} />
      </Grid>
      <Grid item>
        <BetOfferMarket market={market1X2.draw} />
      </Grid>
      <Grid item>
        <BetOfferMarket market={market1X2.away} />
      </Grid>
      <Grid
        xs
        item
      >
        {sponsor && (
          <BetOfferSponsorLogo
            sponsor={sponsor}
            variant={variant}
          />
        )}
      </Grid>
    </Grid>
  )
}



export default BetOfferOdds
