import React, { ComponentType } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import MuiButton, {
  ButtonProps as MuiButtonProps,
} from '@material-ui/core/Button'
import { Color } from '../propTypes'
import { findTextColor, findButtonColor } from '../util'

interface ButtonProps extends Omit<MuiButtonProps, 'color'> {
  color?: Color
  target?: string
  component?: ComponentType
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    textAlign: 'center',
    lineHeight: 1,
  },
  variantText: ({ variant, color }: ButtonProps) =>
    variant === 'text' && color !== 'default' ? findTextColor(palette, color) : {},
  variantContained: ({ variant, color }: ButtonProps) =>
    variant === 'contained' && color !== 'default' ? findButtonColor(palette, color) : {},
}))

const Button: React.FC<ButtonProps> = props => {
  const classes = useStyles(props)
  const { color, className, ...buttonProps } = props

  return (
    <MuiButton
      {...buttonProps}
      className={clsx(className, classes.root, classes.variantText, classes.variantContained)}
    />
  )
}

export default Button
