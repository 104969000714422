import React, { FC, useMemo, ComponentProps } from 'react'

import { List } from '@material-ui/core'

import { useIntl } from 'hooks'
import { CenteredBox } from 'tackl-material'
import { RpcError } from 'services/gamelogic'
import { IGamesGetDetailsResponse } from 'protos'
import { LoadingInline, RpcErrorMessage, VirtualList } from 'components'

import MatchListItem from './MatchListItem'
import { createListItems, createListItemsKey, createListItemsHeight } from './util'

type Props =
  ComponentProps<typeof List> &
  Pick<ComponentProps<typeof VirtualList>, 'container' | 'offsetTop' | 'offsetBottom'> & {
    error?: RpcError
    response?: IGamesGetDetailsResponse
    isLoading: boolean
  }

const MatchList: FC<Props> = ({
  error,
  response,
  isLoading,
  ...props
}) => {
  const { d } = useIntl()
  const items = useMemo(() => createListItems(d, response), [d, response])

  if (isLoading) {
    return (
      <CenteredBox p={4}>
        <LoadingInline />
      </CenteredBox>
    )
  }

  if (error) {
    return (
      <CenteredBox p={4}>
        <RpcErrorMessage error={error} />
      </CenteredBox>
    )
  }

  return (
    <VirtualList
      disablePadding
      items={items}
      offsetTop={-200}
      component={List}
      offsetBottom={-150}
      itemKey={createListItemsKey}
      itemComponent={MatchListItem}
      itemHeight={createListItemsHeight}
      {...props}
    />
  )
}

export default MatchList
