import React, { FC } from 'react'
import { List } from '@material-ui/core'
import { isCancelled } from 'util/match'
import { LoadingInline } from 'components'
import { FormattedMessage } from 'components'
import { IMatchTimeline, IMatch, Match, Game } from 'protos'
import { DividerHeadline, Container, CenteredBox } from 'tackl-material'
import { ScoreChangeListItem, CardListItem, SubstitutionListItem, EmptyState } from './components'

interface Props {
  timeline?: IMatchTimeline
  isLoading?: boolean
  matchState?: IMatch['state']
  betOffer?: IMatch['betOffer']
  gameType?: Game.GameType | null
}

const MatchTimeline: FC<Props> = ({
  timeline,
  betOffer,
  gameType,
  isLoading,
  matchState,
  ...props
}) => {
  const isMatchCancelled = isCancelled(matchState)
  const isLotto = gameType === Game.GameType.GT_LOTTERY
  const isCardsVisible = !!(timeline && timeline.card && timeline.card.length)
  const isScoresVisible = !!(timeline && timeline.scoreChange && timeline.scoreChange.length)
  const isSubstitutionVisible = !!(timeline && timeline.substitution && timeline.substitution.length)
  const isMatchNotStarted = matchState === Match.State.MS_UPCOMING
  const isEmptyStateVisible = isMatchNotStarted || (isMatchCancelled && isLotto) || !(
    isCardsVisible || isScoresVisible || isSubstitutionVisible
  )

  if (isLoading) {
    return (
      <CenteredBox pt={2}>
        <LoadingInline />
      </CenteredBox>
    )
  }

  if (isEmptyStateVisible) {
    let state: 'upcoming' | 'live' | 'cancelled' = 'live'

    if (isMatchNotStarted) {
      state = 'upcoming'
    } else if (isMatchCancelled && isLotto) {
      state = 'cancelled'
    }

    return (
      <EmptyState state={state} />
    )
  }

  return (
    <Container>
      {isScoresVisible && (
        <>
          <DividerHeadline pt={2}>
            <FormattedMessage id="timeline_section_scorers" />
          </DividerHeadline>
          <List>
            {timeline!.scoreChange!.map(event => (
              <ScoreChangeListItem
                key={event.eventId!}
                event={event}
              />
            ))}
          </List>
        </>
      )}

      {isCardsVisible && (
        <>
          <DividerHeadline pt={isScoresVisible ? 0 : 2}>
            <FormattedMessage id="timeline_section_cards" />
          </DividerHeadline>
          <List>
            {timeline!.card!.map(event => (
              <CardListItem
                key={event.eventId!}
                event={event}
              />
            ))}
          </List>
        </>
      )}

      {isSubstitutionVisible && (
        <>
          <DividerHeadline pt={isScoresVisible || isCardsVisible ? 0 : 2}>
            <FormattedMessage id="timeline_section_substitutions" />
          </DividerHeadline>
          <List>
            {timeline!.substitution!.map(event => (
              <SubstitutionListItem
                key={event.eventId!}
                event={event}
              />
            ))}
          </List>
        </>
      )}
    </Container>
  )
}

export default MatchTimeline
