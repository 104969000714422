import RelativeTimeFormat from 'relative-time-format'
import en from 'relative-time-format/locale/en.json'
import de from 'relative-time-format/locale/de.json'
import nl from 'relative-time-format/locale/nl.json'
import fr from 'relative-time-format/locale/fr.json'
import it from 'relative-time-format/locale/it.json'
import pt from 'relative-time-format/locale/pt.json'
import es from 'relative-time-format/locale/es.json'

RelativeTimeFormat.addLocale(en)
RelativeTimeFormat.addLocale(de)
RelativeTimeFormat.addLocale(nl)
RelativeTimeFormat.addLocale(fr)
RelativeTimeFormat.addLocale(it)
RelativeTimeFormat.addLocale(pt)
RelativeTimeFormat.addLocale(es)

const getRelativeTime = (locale: string, date: Date, now: Date): string => {
  const formatter = new RelativeTimeFormat(locale, { numeric: 'always' })
  const diffInMinutes = (+date - +now) / 60000

  if (Math.abs(diffInMinutes) >= 1440) {
    const diffInDays = Math.round(diffInMinutes / 1440)
    return formatter.format(diffInDays, 'day')
  }

  if (Math.abs(diffInMinutes) >= 60) {
    const diffInHours = Math.round(diffInMinutes / 60)
    return formatter.format(diffInHours, 'hour')
  }

  return formatter.format(
    Math.round(
      (diffInMinutes > 0 || (diffInMinutes === 0 && 1 / diffInMinutes === Infinity))
        ? Math.max(1, diffInMinutes)
        : Math.min(-1, diffInMinutes),
    ),
    'minute',
  )
}

// WORKAROUND: remove redundant prefixes
const getRelativeTimeWithoutPrefix: typeof getRelativeTime = (...args) => (
  getRelativeTime(...args)
    .replace(/^in /, '') // en, de
    .replace(/^over /, '') // nl
    .replace(/^dans /, '') // fr
    .replace(/^dentro de /, '') // es
    .replace(/^tra /, '') // it
    .replace(/^em /, '') // pt
)

export default getRelativeTimeWithoutPrefix
