import React, { ComponentProps } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from './Typography'

type TypographyProps = ComponentProps<typeof Typography>

interface MessageProps {
  icon?: React.ReactNode
  headline: React.ReactNode
  body: React.ReactNode
  headlineProps?: TypographyProps
  bodyProps?: TypographyProps
  actions?: React.ReactNodeArray
  spacing?: number
  footer?: React.ReactNode
  actionsLayout?: 'horizontal' | 'vertical'
}

const Message: React.FC<MessageProps> = ({
  icon,
  headline,
  body,
  headlineProps,
  bodyProps,
  actions,
  spacing,
  footer,
  actionsLayout = 'horizontal',
}) => (
  <Box
    display="flex"
    flexDirection="column"
  >
    {!!icon && (
      <Box
        mb={spacing}
        textAlign="center"
      >
        {icon}
      </Box>
    )}

    <Box my={spacing}>
      <Typography
        align="center"
        variant="h2"
        {...headlineProps}
      >
        {headline}
      </Typography>
    </Box>
    <Box my={spacing}>
      <Typography
        alpha={.5}
        align="center"
        variant="body1"
        {...bodyProps}
      >
        {body}
      </Typography>
    </Box>
    {actions && (
      <Box mt={spacing}>
        <Grid
          container
          direction={actionsLayout === 'horizontal' ? 'row' : 'column-reverse'}
          spacing={1}
        >
          {actions.map((action, i) => (
            <Grid
              item
              key={`action-${String(i)}`}
              xs={actionsLayout === 'horizontal' ? (12 / actions.length) as 12 : 12}
            >
              {action}
            </Grid>
          ))}
        </Grid>
      </Box>
    )}
    {footer && (
      <Box
        my={spacing}
        textAlign="center"
      >
        {footer}
      </Box>
    )}
  </Box>
)

Message.defaultProps = {
  spacing: 1,
}

export default Message
