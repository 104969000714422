import React, { FC, ComponentProps } from 'react'
import { makeStyles, Theme, alpha } from '@material-ui/core/styles'
import { Slider as MuiSlider } from '@material-ui/core'
import { Box } from '../Box'
import { Drag } from '../Icons'

type Props = ComponentProps<typeof MuiSlider> & {
  thumbColor?: string
  location?: 'left' | 'right'
}

export const thumbHeight = 50

const useStyles = makeStyles<Theme, Pick<Props, 'thumbColor' | 'location'>>(({ palette, typography, spacing, breakpoints }) => ({
  root: {
    padding: '40px 0',
    userSelect: 'none',
    '&$vertical': {
      padding: '0 50px',

      [breakpoints.down('xs')]: {
        padding: '0 20px',
      },
    },
  },
  mark: {
    opacity: 0,
  },
  marked: {
    margin: 0,
  },
  rail: {
    backgroundColor: 'transparent',
  },
  track: {
    backgroundColor: 'transparent',
  },
  markLabel: ({ location = 'left' }) => ({
    ...(typography.h4 as any),
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: alpha(palette.common.black, .2),

    [breakpoints.up('md')]: {
      width: 35,
      height: 35,
      borderRadius: 4,
      padding: 4,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      boxSizing: 'border-box',
      border: `1px solid #dcdcdc`,
      color: palette.common.black,
      backgroundColor: palette.common.white,
      boxShadow: 'inset 0 -4px rgba(0, 0, 0, .15)',
    },

    [breakpoints.down('xs')]: {
      padding: location === 'left' ? '0 0 0 12px' : '0 12px 0 0',
      justifyContent: location === 'left' ? 'flex-start' : 'flex-end',
    },
  }),
  thumb: ({ thumbColor = palette.background.default, location = 'left' }) => ({
    ...(typography.h4 as any),
    width: 50,
    height: '100%',
    padding: 12,
    marginLeft: -25,
    marginTop: 0,
    top: 0,
    bottom: 0,
    borderRadius: 25,
    color: palette.common.white,
    background: thumbColor,
    border: `5px solid ${palette.common.white}`,
    boxShadow: `0 0 ${spacing(5)}px ${alpha(palette.common.black, .15)}`,
    flexDirection: 'column',
    justifyContent: 'space-between',

    '& > svg': {
      fontSize: 10,
      opacity: .3,
      color: palette.common.black,
    },

    [breakpoints.up('md')]: {
      borderRadius: 5,
      marginRight: 'auto',
      boxSizing: 'border-box',
      color: palette.common.white,
      padding: 4,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      border: `1px solid rgba(0, 0, 0, .2)`,
      boxShadow: `inset 0 3px 0 rgba(0, 0, 0, .1)`,

      '& > svg': {
        display: 'none',
      },
    },

    [breakpoints.down('xs')]: {
      width: `calc(100% + ${spacing(3)}px)`,
      borderRadius: location === 'left' ? '0 25px 25px 0' : '25px 0 0 25px',
      borderLeft: location === 'left' ? 'none' : `5px solid ${palette.common.white}`,
      borderRight: location === 'right' ? 'none' : `5px solid ${palette.common.white}`,
      transform: location === 'left' ? `translateX(0px)` : `translateX(${spacing(-3)}px)`,

      '& > svg:first-child': {
        display: location === 'left' ? 'none' : 'initial',
      },

      '& > svg:last-child': {
        display: location === 'right' ? 'none' : 'initial',
      },
    },

    [breakpoints.up('sm')]: {
      width: '100%',
    },
  }),
  vertical: {
    '& > root': {
      background: 'red',
    },
    '&$marked': {
      margin: 0,
    },
    '& > $markLabel': {
      left: 0,
      right: 0,
      top: 'auto',
      bottom: 'auto',
      marginLeft: 'auto',
      [breakpoints.up('md')]: {
        marginRight: 'auto',
      },
    },
    '& > $thumb': {
      // width: '100%',
      height: thumbHeight,
      left: 0,
      right: 0,
      marginTop: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: -25,
      flexDirection: 'row',

      [breakpoints.up('md')]: {
        width: 35,
        height: 35,
        marginBottom: -17,
      },
    },
    '&$disabled $thumb': {
      marginBottom: 0,
      marginLeft: -25,
    },
  },
  disabled: {
    '& $thumb': {
      width: 50,
      height: '100%',
      marginLeft: -25,
      marginTop: 0,
      color: alpha(palette.common.black, .2),
      backgroundColor: palette.background.default,
    },
    '&$vertical $thumb': {
      // width: '100%',
      height: thumbHeight,
      marginTop: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: -25,
    },
  },
}))

const Thumb = (props: any) => {
  return (
    <Box
      {...props}
      component="span"
    >
      <Drag />
      <span>{props['aria-valuetext']}</span>
      <Drag />
    </Box>
  )
}

const Slider: FC<Props> = ({ thumbColor, location, ...props }) => {
  const classes = useStyles({ thumbColor, location })

  return (
    <MuiSlider
      {...props}
      classes={classes}
      ThumbComponent={Thumb}
    />
  )
}

Slider.defaultProps = {
  getAriaValueText: (value: number) => {
    return value.toString()
  },
}

export default Slider

