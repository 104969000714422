import React, { FC, ComponentProps } from 'react'
import { Fab } from 'tackl-material'
import { Down } from 'tackl-material/Icons'
import { FormattedMessage } from 'components'

const SaveButton: FC<Partial<ComponentProps<typeof Fab>>> = (props) => (
  <Fab
    size="large"
    color="default"
    {...props}
  >
    <FormattedMessage id="predictioneditor_action_save" />
    <Down
      color="action"
      fontSize="large"
    />
  </Fab>
)

export default SaveButton
