import React, { FC } from 'react'

import { alpha } from '@material-ui/core/styles'
import { makeStyles, Theme, useTheme } from '@material-ui/core'

import { Game, IGame } from 'protos'
import { Box } from 'tackl-material'

import { Props } from './propTypes'
import { getIndicatorColor } from './util'
import GameCardHeaderTime from './GameCardHeaderTime'
import GameCardHeaderTitle from './GameCardHeaderTitle'
import GameCardHeaderPrize from './GameCardHeaderPrize'
import LotteryGameIndicator from './LotteryGameIndicator'
import GameCardHeaderContainer from './GameCardHeaderContainer'

interface StyleProps {
  state: IGame['state']
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  time: ({ state }) => {
    switch (state) {
      case Game.State.GS_PENDING:
      case Game.State.GS_UPCOMING:
        return {
          opacity: 1,
          color: theme.palette.danger.main,
        }
      default:
        return {}
    }
  },
  title: ({ state }) => {
    return {
      fontSize: 12,
      fontWeight: 'bold',
      color: (() => {
        switch (state) {
          case Game.State.GS_FINISHED_WIN:
          case Game.State.GS_FINISHED_LOSS:
            return theme.palette.gameState[state].main
          default:
            return theme.palette.text.primary
        }
      })(),
      '& strong': {
        fontSize: 14,
        color: theme.palette.text.primary,
      },
    }
  },
  prizeValue: ({ state }) => {
    switch (state) {
      case Game.State.GS_PENDING:
      case Game.State.GS_UPCOMING:
        return {}
      default:
        return {
          fontWeight: 'normal',
          color: alpha(theme.palette.text.primary, 0.3),
          '& strong': {
            fontWeight: 'bold',
            color: theme.palette.text.primary,
          },
        }
    }
  },
}))

const LotteryGameCardHeader: FC<Props> = ({ game, isIndicatorHidden }) => {
  const theme = useTheme<Theme>()
  const classes = useStyles({ state: game.state })

  return (
    <GameCardHeaderContainer alignItems="center">
      {
        !isIndicatorHidden && (
          <LotteryGameIndicator
            state={game.state}
            color={getIndicatorColor(game, theme.palette)}
          />
        )
      }

      <Box
        flex={1}
        minWidth={0}
      >
        <GameCardHeaderTime
          className={classes.time}
          nextTime={game.nextTime}
          display={game.timeDisplay}
          startTime={game.startTime}
          timeOther={game.timeOther}
          finishTime={game.finishTime}
        />
        <GameCardHeaderTitle
          title={game.titleText}
          className={classes.title}
        />
      </Box>

      <Box px={2}>
        <GameCardHeaderPrize
          value={game.prizeValue}
          title={game.prizeTitle}
          ValueTypographyProps={{ className: classes.prizeValue }}
        />
      </Box>
    </GameCardHeaderContainer>
  )
}

export default LotteryGameCardHeader
