import { useState, Dispatch, SetStateAction } from 'react'

type PaginationState = {
  currentIndex: number
  setCurrentIndex: Dispatch<SetStateAction<number>>
  goBack: () => void
  goNext: () => void
}

const clamp = (value: number, min: number, max: number) =>
  Math.max(min, Math.min(value, max))

export default function usePagination(initialIndex = 0, maxIndex: number): PaginationState {
  if (initialIndex < 0) {
    throw new Error('initialIndex must be larger than 0')
  }

  const [currentIndex, setCurrentIndex] = useState<number>(initialIndex)

  const goBack = () => {
    setCurrentIndex(prevIndex => clamp(prevIndex - 1, 0, maxIndex))
  }

  const goNext = () => {
    setCurrentIndex(prevIndex => clamp(prevIndex + 1, 0, maxIndex))
  }

  return { currentIndex, setCurrentIndex, goBack, goNext }
}
