import React, { FC, ComponentType, ComponentProps } from 'react'
import clsx from 'clsx'
import { IOpponent, OpponentHighlight, User } from 'protos'
import { Link } from 'react-navi'
import { makeStyles } from '@material-ui/styles'
import { Avatar, Checkbox } from 'tackl-material'
import { analyticsService } from 'services/global'
import { Remove, AddFriend, StatusPending } from 'tackl-material/Icons'
import ListItem, { ListItemProps } from '@material-ui/core/ListItem'
import { Theme, ListItemText, ListItemAvatar } from '@material-ui/core'
import { ActionButton } from 'components'
import { Highlight } from './components'

interface Props extends ListItemProps {
  href?: ComponentProps<typeof Link>['href']
  variant?: 'default' | 'selectable'
  opponent: IOpponent
  component?: ComponentType
  highlight?: OpponentHighlight
  isSelected?: boolean
  disableSelfHighlight?: boolean
  onAddFriend?: (userId: string) => void
  onRemoveFriend?: (userId: string) => void
  isFacebookFriend?: boolean
}

const useStyles = makeStyles<Theme>(({ spacing, palette }) => ({
  avatar: {
    width: 45,
    height: 45,
  },
  actions: {
    right: 0,
  },
  actionsSpacing: {
    marginLeft: spacing(1),
  },
  highlight: {
    flex: 'none',
  },
  self: {
    borderRadius: 2,
    boxShadow: '0 0 5px rgba(0, 0, 0, .05)',
    backgroundColor: palette.neutral[50].main,
    '&:hover': {
      backgroundColor: palette.neutral[50].main,
    },
  },
  actionButton: {
    marginLeft: spacing(1),
  },
}))

const OpponentListItem: FC<Props> = ({
  opponent,
  disabled,
  className,
  isSelected,
  variant = 'default',
  highlight = OpponentHighlight.OPH_NONE,
  onAddFriend = () => undefined,
  onRemoveFriend = () => undefined,
  disableSelfHighlight = false,
  isFacebookFriend = false,
  ...props
}) => {
  const classes = useStyles()

  const { rank, user } = opponent
  const userId = (user && user.userId) || ''
  const nickname = (user && user.nickname) || ''
  const primaryText = rank ? `${rank}. ${nickname}` : nickname
  const relationship = user && user.relationship

  const isSelf = !disableSelfHighlight && relationship === User.Relationship.REL_SELF
  const isFriend = relationship === User.Relationship.REL_FRIEND
  const isPossibleFriend = relationship === User.Relationship.REL_UNSET
  const isPendingRequest = relationship === User.Relationship.REL_REQUEST_SENT
  const isIncomingRequest = relationship === User.Relationship.REL_REQUEST_RECEIVED

  const isHighlightVisible =
    (highlight !== OpponentHighlight.OPH_NONE || isFriend) &&
    !(isPendingRequest || isIncomingRequest) &&
    !isFacebookFriend

  return (
    <ListItem
      className={clsx(className, { [classes.self]: isSelf })}
      {...props as any}
    >
      <ListItemAvatar>
        <Avatar
          src={opponent.user!.photoUrl!}
          className={classes.avatar}
        />
      </ListItemAvatar>

      <ListItemText
        primary={primaryText}
        secondary={opponent.subtitle}
        primaryTypographyProps={{ noWrap: true }}
        secondaryTypographyProps={{ noWrap: true }}
      />

      {variant === 'default' && (
        <>
          {isIncomingRequest && (
            <>
              <ActionButton
                variant="secondary"
                className={classes.actionButton}
                onClick={() => {
                  onRemoveFriend(userId)
                  analyticsService.get().logEvent('Friends_Request_Declined')
                }}
              >
                <Remove fontSize="small" />
              </ActionButton>
              <ActionButton
                variant="primary"
                className={classes.actionButton}
                onClick={() => {
                  onAddFriend(userId)
                  analyticsService.get().logEvent('Friends_Request_Accepted')
                }}
              >
                <AddFriend fontSize="small" />
              </ActionButton>
            </>
          )}

          {isPendingRequest && (
            <ActionButton
              disabled
              className={classes.actionButton}
            >
              <StatusPending fontSize="small" />
            </ActionButton>
          )}

          {!isHighlightVisible && isPossibleFriend && (
            <ActionButton
              variant="primary"
              className={classes.actionButton}
              onClick={() => {
                onAddFriend(userId)
                analyticsService.get().logEvent('Search_Friend_Added')
              }}
            >
              <AddFriend fontSize="small" />
            </ActionButton>
          )}

          {isHighlightVisible && (
            <ListItemText
              primary={(
                <Highlight
                  winRatio={opponent.winRatio}
                  winCount={opponent.winCount}
                  highlight={highlight}
                  gamesCount={opponent.gamesCount}
                  drinkBalance={opponent.drinkBalance}
                  creditBalance={opponent.creditBalance}
                />
              )}
              className={classes.highlight}
              primaryTypographyProps={{
                color: 'primary',
                variant: 'h4',
              }}
            />
          )}
        </>
      )}

      {variant === 'selectable' && (
        <Checkbox
          edge="end"
          checked={isSelected}
          disabled={disabled}
        />
      )}
    </ListItem>
  )
}

export default OpponentListItem
