import React, { FC } from 'react'
import { IMatch } from 'protos'
import { MatchBanner } from 'components'
import { isScoreDisplayActive } from 'util/match'

type Props = {
  match: IMatch
  className?: string
}

const MatchDetailsHeader: FC<Props> = ({ match, className }) => {
  const homeScore = match.currentScore ? match.currentScore.homeScore : 0
  const awayScore = match.currentScore ? match.currentScore.awayScore : 0

  return (
    <header className={className}>
      <MatchBanner
        match={match}
        hidePredictions
        homeScore={isScoreDisplayActive(match.state) ? Number(homeScore) : -1}
        awayScore={isScoreDisplayActive(match.state) ? Number(awayScore) : -1}
      />
    </header>
  )
}

export default MatchDetailsHeader

