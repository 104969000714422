import React, { FC } from 'react'

import { Grid, Typography, Link, styled } from '@material-ui/core'

import { useAuth } from 'hooks'
import config from 'services/config'
import { FormattedMessage } from 'components'

import tacklLogoUrl from './assets/tackl_r.svg'

const Text = styled(Typography)(({ theme }) => ({
  lineHeight: '10px',
  margin: theme.spacing(0, 0, 1),
}))

const Image = styled('img')(({ theme }) => ({
  margin: theme.spacing(1, 0, 1, 1),
  height: 18,
}))

const PoweredByTackl: FC = () => {
  const { userInfo } = useAuth()

  return (
    <Link
      target="_blank"
      rel="noreferrer"
      underline="none"
      href={userInfo ? config.landingUrl : config.landingUrlAdjust}
    >
      <Grid
        container
        wrap="nowrap"
        justify="flex-end"
        alignItems="flex-end"
      >
        <Text
          variant="subtitle2"
          color="textSecondary"
        >
          <FormattedMessage id="web_bild_powered_by" />
        </Text>

        <Image
          src={tacklLogoUrl}
          alt="powered by tackl"
        />
      </Grid>
    </Link>
  )
}

export default PoweredByTackl
