import { lazy } from 'react'
import { tenantSwitch } from 'lib'

export * from './constants'

export default tenantSwitch({
  tackl: lazy(() => import('./MainNavigationTackl')),
  tippstar: lazy(() => import('./MainNavigationTackl')),
  bild: lazy(() => import('./MainNavigationBild')),
})
