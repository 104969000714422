import React, { FC, ComponentProps } from 'react'
import { Fab } from 'tackl-material'
import { FormattedMessage } from 'components'
import { GroupsFull } from 'tackl-material/Icons'

const InviteButton: FC<Partial<ComponentProps<typeof Fab>>> = (props) => (
  <Fab
    color="primary"
    size="large"
    {...props}
  >
    <GroupsFull color="action" />
    <FormattedMessage id="predictioneditor_action_invite" />
  </Fab>
)

export default InviteButton
