import React, { FC } from 'react'

import { makeStyles } from '@material-ui/core'
import { SimplePaletteColorOptions, Theme } from '@material-ui/core/styles'

import { Game, IGame } from 'protos'
import { GameTypeLottery } from 'tackl-material/Icons'

interface Props {
  color: SimplePaletteColorOptions
  state: IGame['state']
}

interface StyleProps {
  state: IGame['state']
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  root: {
    lineHeight: 0,
    fontSize: '56px',
    padding: theme.spacing(0, 1),
    color: ({ state }) => {
      switch (state) {
        case Game.State.GS_RUNNING_LIVE:
        case Game.State.GS_FINISHED_WIN:
        case Game.State.GS_FINISHED_LOSS:
          return theme.palette.gameState[state].main
        default:
          return theme.palette.danger.main
      }
    },
  },
}))

const LotteryGameIndicator: FC<Props> = ({ state }) => {
  const classes = useStyles({ state })

  return (
    <div className={classes.root}>
      <GameTypeLottery
        color="inherit"
        fontSize="inherit"
      />
    </div>
  )
}

export default LotteryGameIndicator
