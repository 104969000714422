import { PropsWithChildren } from 'react'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core'

type Props = {
  from?: 'xs' | 'sm' | 'md'
  className?: string
}

const stickyStyles = {
  position: 'sticky' as 'sticky',
  top: 0,
}

const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
  stickFromXs: stickyStyles,
  stickFromSm: {
    [breakpoints.up('sm')]: stickyStyles,
  },
  stickFromMd: {
    [breakpoints.up('md')]: stickyStyles,
  },
}))


export default function Sticky({ from = 'xs', className, ...props }: PropsWithChildren<Props>) {
  const classes = useStyles()

  return (
    <div
      {...props}
      className={clsx(className, {
        [classes.stickFromXs]: from === 'xs',
        [classes.stickFromSm]: from === 'sm',
        [classes.stickFromMd]: from === 'md',
      })}
    />
  )
}
