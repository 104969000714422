import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Moneybag: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="27"
    height="34"
    viewBox="0 0 27 34"
    {...props}
  >
    <path
      d="M14.21 28.28v.76a.71.71 0 01-1.42 0v-.76c-1.56-.25-2.73-1.27-2.84-2.58a.7.7 0 01.65-.77.7.7 0 01.77.65c.04.57.64 1.06 1.42 1.25v-2.89c-1.91-.47-2.84-1.36-2.84-2.69 0-1.37 1.22-2.51 2.84-2.78v-.76a.71.71 0 011.42 0v.76c1.56.24 2.73 1.27 2.84 2.58a.7.7 0 01-.65.77c-.38.05-.73-.26-.77-.65-.04-.57-.64-1.06-1.42-1.25v2.89c1.91.47 2.84 1.36 2.84 2.69 0 1.37-1.22 2.51-2.84 2.78zM9.01 9.92H18c2.7 2.93 9 10.08 9 13.46C27 29.23 20.94 34 13.5 34S0 29.23 0 23.37C0 20 6.3 12.86 9.01 9.93zm7.33-1.42H8.73c-1.8-1.6-4.55-4.32-4.71-6.18-.05-.53.1-1 .42-1.37.2-.23.58-.5 1.23-.5 1.43 0 3.7 1.38 4.8 2.1C11.27 1.41 12.48 0 13.5 0s2.22 1.4 3.03 2.55c1.1-.72 3.37-2.1 4.8-2.1.65 0 1.03.27 1.23.5.32.36.47.84.42 1.37-.16 1.86-2.9 4.58-4.7 6.18h-1.94zm-2.13 15.77v2.56c.82-.2 1.42-.73 1.42-1.33 0-.56-.47-.95-1.42-1.23zm-2.84-3.02c0 .56.47.95 1.42 1.23v-2.56c-.82.2-1.42.73-1.42 1.33z"
      fill="inherit"
      fillRule="evenodd"
    />
  </SvgIcon>
)

export default Moneybag
