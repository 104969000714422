import { Router as NaviRouter } from 'react-navi'
import { TranslateFunction } from 'hooks/useIntl'
import { RpcError } from 'services/gamelogic'

export interface RouterContext {
  t: TranslateFunction
  language: string
  userId: string | null
  sessionsLoginError?: RpcError | null
}

export default class Router extends NaviRouter<RouterContext> { }
