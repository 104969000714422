import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const GroupsAlternate: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="44"
    height="46"
    viewBox="0 0 44 46"
    {...props}
  >
    <g fill="currentColor">
      <path
        d="M12.833 37.583a.918.918 0 01-.916.917h-5.5a.918.918 0 01-.917-.917v-8.25c0-1.012-.82-1.833-1.833-1.833H2.75a.918.918 0 01-.917-.917V22a7.321 7.321 0 0113.322-4.208 9.093 9.093 0 00-2.322 6.041v13.75zm29.334-11a.918.918 0 01-.917.917h-.917c-1.012 0-1.833.82-1.833 1.833v8.25a.918.918 0 01-.917.917h-5.5a.918.918 0 01-.916-.917v-13.75a9.093 9.093 0 00-2.321-6.04A7.321 7.321 0 0142.166 22v4.583z"
        opacity="0.2"
      />
      <path d="M22 12.833a5.5 5.5 0 110-11 5.5 5.5 0 010 11zm0-9.166A3.667 3.667 0 1022 11a3.667 3.667 0 000-7.333zM34.833 11a5.5 5.5 0 110-11 5.5 5.5 0 010 11zm0-9.167a3.667 3.667 0 100 7.333 3.667 3.667 0 000-7.333zM9.167 11a5.5 5.5 0 110-11 5.5 5.5 0 010 11zm0-9.167a3.667 3.667 0 100 7.333 3.667 3.667 0 000-7.333zm25.666 11A9.167 9.167 0 0144 22v4.583a2.75 2.75 0 01-2.75 2.75h-.917v8.25a2.75 2.75 0 01-2.75 2.75h-5.5a2.75 2.75 0 01-2.75-2.75v-4.751a2.722 2.722 0 01-.916.168H27.5v10.083a2.75 2.75 0 01-2.75 2.75h-5.5a2.75 2.75 0 01-2.75-2.75V33h-.917a2.724 2.724 0 01-.916-.168v4.751a2.75 2.75 0 01-2.75 2.75h-5.5a2.75 2.75 0 01-2.75-2.75v-8.25H2.75A2.75 2.75 0 010 26.583V22a9.142 9.142 0 0116.495-5.456l-.003.003a9.005 9.005 0 0111.014 0l-.002-.002a9.124 9.124 0 017.33-3.712zm-22 24.75v-13.75a9.093 9.093 0 012.321-6.04A7.321 7.321 0 001.834 22v4.583a.918.918 0 00.916.917h.917c1.012 0 1.833.82 1.833 1.833v8.25a.918.918 0 00.917.917h5.5a.918.918 0 00.916-.917zm15.584-6.416a.918.918 0 00.916-.917v-6.417a7.333 7.333 0 00-14.666 0v6.417a.918.918 0 00.916.917h.917c1.012 0 1.833.82 1.833 1.833v10.083a.918.918 0 00.917.917h5.5a.918.918 0 00.917-.917V33c0-1.012.82-1.833 1.833-1.833h.917zm13.75-4.584V22a7.321 7.321 0 00-13.322-4.208 9.093 9.093 0 012.322 6.041v13.75a.918.918 0 00.916.917h5.5a.918.918 0 00.917-.917v-8.25c0-1.012.82-1.833 1.833-1.833h.917a.918.918 0 00.917-.917z" />
    </g>
  </SvgIcon>
)

export default GroupsAlternate
