import React, { FC, ComponentProps as CP } from 'react'
import { IGame } from 'protos'
import { Typography } from 'tackl-material'
import { Currency, Markdown } from 'components'
import useFormatAmount from 'hooks/useFormatAmount'

interface Props {
  value: IGame['prizeValue']
  title: IGame['prizeTitle']
  ValueTypographyProps?: CP<typeof Typography>
  LabelTypographyProps?: CP<typeof Typography>
}

const GameCardHeaderPrize: FC<Props> = ({ value, title, ValueTypographyProps, LabelTypographyProps }) => {
  const formatAmount = useFormatAmount()

  return (
    <>
      <Typography
        variant="h3"
        align="right"
        {...ValueTypographyProps}
      >
        {
          value?.custom ? (
            <Markdown>
              {value.custom}
            </Markdown>
          ) : (
            <Currency
              unit={(value && value.unit) || '€'}
              amount={formatAmount(value?.amount) || 0}
            />
          )
        }
      </Typography>
      <Typography
        alpha={.5}
        align="right"
        variant="subtitle2"
        {...LabelTypographyProps}
      >
        <Markdown>
          {title}
        </Markdown>
      </Typography>
    </>
  )
}

export default GameCardHeaderPrize
