import React, { FC } from 'react'

import { Theme, useTheme } from '@material-ui/core'

import { Box } from 'tackl-material'

import { Props } from './propTypes'
import { getIndicatorColor } from './util'
import GameCardHeaderTime from './GameCardHeaderTime'
import FriendGameIndicator from './FriendGameIndicator'
import GameCardHeaderTitle from './GameCardHeaderTitle'
import GameCardHeaderPrize from './GameCardHeaderPrize'
import GameCardHeaderContainer from './GameCardHeaderContainer'

const FriendGameCardHeader: FC<Props> = ({ game, isIndicatorHidden }) => {
  const theme = useTheme<Theme>()

  return (
    <GameCardHeaderContainer>
      {
        !isIndicatorHidden && (
          <FriendGameIndicator
            state={game.state}
            color={getIndicatorColor(game, theme.palette)}
          />
        )
      }

      <Box
        pl={2}
        flex={1}
        minWidth={0}
        alignSelf="center"
      >
        <GameCardHeaderTime
          nextTime={game.nextTime}
          display={game.timeDisplay}
          startTime={game.startTime}
          timeOther={game.timeOther}
          finishTime={game.finishTime}
        />
        <GameCardHeaderTitle
          title={game.titleText}
        />
      </Box>

      <Box
        px={2}
        alignSelf="center"
      >
        <GameCardHeaderPrize
          value={game.prizeValue}
          title={game.prizeTitle}
        />
      </Box>
    </GameCardHeaderContainer>
  )
}

export default FriendGameCardHeader
