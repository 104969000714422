import React, { FC, CSSProperties } from 'react'

import { makeStyles } from '@material-ui/styles'
import { ListItem, ListItemText, Theme } from '@material-ui/core'

import { Game } from 'protos'
import { NaviLink } from 'tackl-material'
import MatchCard from 'components/MatchCard'
import { useMatchDetailsDrawer } from 'hooks'
import { LotteryMatchCard } from 'components'
import { isPredictionEditable } from 'util/match'

import { ListItemType } from './util'

interface Props {
  data: ListItemType
  style: CSSProperties
}

const useStyles = makeStyles<Theme>(theme => ({
  card: {
    width: '100%',
  },
  item: {
    padding: 0,
    margin: theme.spacing(2, 0),
  },
}))

const MatchesListItem: FC<Props> = ({ data, style, ...props }) => {
  const classes = useStyles()
  const { open: openMatchDetailsDrawer } = useMatchDetailsDrawer()

  if (typeof data === 'string') {
    return (
      <ListItem
        className={classes.item}
        disableGutters
        {...props}
      >
        <ListItemText
          secondary={data}
          secondaryTypographyProps={{
            align: 'center',
            color: 'textPrimary',
            variant: 'body2',
          }}
        />
      </ListItem>
    )
  }

  const { match, prediction, gameType } = data
  const isEditable = isPredictionEditable(match?.state, prediction)

  if (isEditable) {
    return (
      <ListItem
        disableGutters
        className={classes.item}
        component={NaviLink as any}
        href={`./edit/${match.matchId}`}
        {...props}
      >
        {
          gameType === Game.GameType.GT_LOTTERY ? (
            <LotteryMatchCard
              match={match}
              prediction={prediction}
              className={classes.card}
            />
          ) : (
            <MatchCard
              match={match}
              prediction={prediction}
              className={classes.card}
            />
          )
        }
      </ListItem>
    )
  }

  return (
    <ListItem
      button
      disableGutters
      className={classes.item}
      onClick={() => openMatchDetailsDrawer(match.matchId!, gameType)}
      {...props}
    >
      {
        gameType === Game.GameType.GT_LOTTERY ? (
          <LotteryMatchCard
            match={match}
            prediction={prediction}
            className={classes.card}
          />
        ) : (
          <MatchCard
            match={match}
            prediction={prediction}
            className={classes.card}
          />
        )
      }
    </ListItem>
  )
}

export default MatchesListItem
