import React, { FC } from 'react'
import { Grid, makeStyles } from '@material-ui/core'

import { FormattedMessage } from 'components'
import { Typography } from 'tackl-material'
import { BetOffer } from 'protos'

type Props = {
  sponsor: BetOffer.ISponsor
  variant?: 'light' | 'dark'
}

const useStyles = makeStyles({
  fontSize11: {
    fontSize: 11,
  },
})

const LotteryBetOfferSponsor: FC<Props> = ({ sponsor, variant = 'light' }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      spacing={1}
      wrap="nowrap"
      alignItems="center"
    >
      <Grid
        xs
        item
      >
        <Typography
          noWrap
          alpha={.5}
          align="right"
          variant="body2"
          className={classes.fontSize11}
        >
          <FormattedMessage id="web_bild_presented_by" />
        </Typography>
      </Grid>

      <Grid
        xs
        item
      >
        <img
          height={10}
          alt={sponsor.name!}
          src={variant === 'light' ? sponsor.logoUrl! : sponsor.logoUrlLight!}
        />
      </Grid>
    </Grid>
  )
}

export default LotteryBetOfferSponsor
