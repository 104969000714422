import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Settings: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="25"
    height="24"
    viewBox="0 0 25 24"
    {...props}
  >
    <path d="M8.99 14.236a1.7 1.7 0 01-.411 1.323 1.482 1.482 0 01-1.027.505L7 23.536a.501.501 0 01-.5.464h-4a.5.5 0 01-.498-.464l-.552-7.472a1.478 1.478 0 01-1.027-.505 1.701 1.701 0 01-.412-1.323l.664-5.808C.832 7.044 1.928 6 3.224 6h2.553c1.296 0 2.393 1.044 2.55 2.428l.663 5.808zM4.5 5C3.123 5 2 3.878 2 2.5S3.123 0 4.5 0 7 1.122 7 2.5 5.88 5 4.5 5zm19.446 5.487a11.683 11.683 0 010 2.95c-.15 1.196-1.417 1.996-2.532 1.57-.463-.18-.934.077-1.126.517a8.84 8.84 0 01-.853 1.52c-.296.42-.241.972.125 1.282.443.374.69.924.678 1.509a1.947 1.947 0 01-.752 1.503 11.48 11.48 0 01-2.479 1.467 1.887 1.887 0 01-.751.157 1.887 1.887 0 01-1.862-1.597c-.077-.477-.508-.798-1.003-.746a8.224 8.224 0 01-1.704 0c-.507-.051-.926.269-1.004.746a1.884 1.884 0 01-2.185 1.568.498.498 0 01-.413-.492l.391-5.325a.503.503 0 01.244-.394c.226-.133.429-.3.605-.497.2-.224.364-.506.489-.837a.5.5 0 01.772-.221 3.955 3.955 0 002.415.833c2.206 0 4-1.794 4-4 0-2.205-1.794-4-4-4a3.978 3.978 0 00-2.778 1.13.502.502 0 01-.516.112.501.501 0 01-.328-.414l-.059-.512A3.781 3.781 0 008.082 5.91a.498.498 0 01-.168-.368l-.023-2.175a.52.52 0 01.013-.118 3.27 3.27 0 00.097-.75c0-.257-.036-.522-.114-.834a.501.501 0 01.324-.593 1.876 1.876 0 012.472 1.486.898.898 0 001.003.746 8.088 8.088 0 011.704 0c.51.056.927-.27 1.004-.745a1.886 1.886 0 012.613-1.442c.88.38 1.714.874 2.479 1.468.466.362.74.91.752 1.504a1.908 1.908 0 01-.678 1.507c-.366.31-.421.862-.125 1.283.332.474.619.985.853 1.52.192.44.7.68 1.115.52 1.171-.444 2.393.37 2.543 1.567z" />
  </SvgIcon>
)

export default Settings
