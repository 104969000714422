import { Grid, styled } from '@material-ui/core'
import { PoweredByTackl } from 'components'
import emblemUrl from './assets/fussball_lotto.png'

const Image = styled('img')({
  maxWidth: 220,
  height: 'auto',
})

const LottoEmblem = () => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
    >
      <Grid item>
        <Image
          src={emblemUrl}
          alt="Bild Fussball Lotto"
        />
      </Grid>

      <Grid item>
        <PoweredByTackl />
      </Grid>
    </Grid>
  )
}

export default LottoEmblem
