import { forwardRef, Ref } from 'react'
import MuiBox, { BoxProps as MuiBoxProps } from '@material-ui/core/Box'

const CenteredBox = forwardRef((props: MuiBoxProps, ref: Ref<HTMLDivElement>) => (
  <MuiBox
    // @ts-ignore
    ref={ref}
    alignItems="center"
    justifyContent="center"
    {...props}
    display="flex"
  />
))

export default CenteredBox
