import { IPrediction, IScore, IMatch } from 'protos'

export type PredictionMap = {
  [matchId: string]: IPrediction
}

type State = {
  teamIndex: 0 | 1
  matchIndex: number
  matchCount: number
  predictions: PredictionMap
}

type Action =
  // | { type: 'reset', matchCount: State['matchCount'], predictions: State['predictions'] }
  | { type: 'restart' }
  | { type: 'nextMatch' }
  | { type: 'toggleTeam' }
  | { type: 'removePrediction', matchId: string }
  | { type: 'changeHomeScore', matchId: string, score: number }
  | { type: 'changeAwayScore', matchId: string, score: number }

function changePrediction(state: State, matchId: string, predictedScore: IScore): State {
  return {
    ...state,
    predictions: {
      ...state.predictions,
      [matchId]: {
        matchId,
        predictedScore,
      },
    },
  }
}

export function getPredictedScore(
  predictions: PredictionMap,
  matchId?: IMatch['matchId'],
): { homeScore: number, awayScore: number } {
  const homeScore = matchId && predictions[matchId] ? predictions[matchId].predictedScore!.homeScore! : -1
  const awayScore = matchId && predictions[matchId] ? predictions[matchId].predictedScore!.awayScore! : -1

  return { homeScore, awayScore }
}

export default function stateReducer(state: State, action: Action): State {
  switch (action.type) {
    // case 'reset':
    //   return { ...state, matchIndex: 0, teamIndex: 0, matchCount: action.matchCount, predictions: action.predictions }
    case 'restart':
      return { ...state, matchIndex: 0, teamIndex: 0 }
    case 'toggleTeam':
      return { ...state, teamIndex: state.teamIndex === 0 ? 1 : 0 }
    case 'nextMatch':
      return { ...state, matchIndex: Math.min(state.matchIndex + 1, state.matchCount), teamIndex: 0 }
    case 'removePrediction':
      const { [action.matchId]: omitted, ...predictions } = state.predictions

      return { ...state, predictions }
    case 'changeHomeScore':
      const { awayScore: prevAwayScore } = getPredictedScore(state.predictions, action.matchId)

      return changePrediction(state, action.matchId, {
        homeScore: action.score,
        awayScore: action.score < 0 ? action.score : Math.max(0, prevAwayScore),
      })
    case 'changeAwayScore':
      const { homeScore: prevHomeScore } = getPredictedScore(state.predictions, action.matchId)

      return changePrediction(state, action.matchId, {
        homeScore: action.score < 0 ? action.score : Math.max(0, prevHomeScore),
        awayScore: action.score,
      })
  }
}
