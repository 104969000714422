import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const GameTypeInvited: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <g
      fill="inherit"
      fillRule="evenodd"
    >
      <path
        d="M9.69 6l-4.2 4.2a.552.552 0 0 0 .004.789l.305.304a.559.559 0 0 0 .788.004l4.473-4.473a.58.58 0 0 0 .141-.105l.305-.304a.56.56 0 0 0 .164-.373.557.557 0 0 0-.164-.456l-.305-.305a.56.56 0 0 0-.139-.102L6.587.703a.552.552 0 0 0-.788.004l-.305.305a.559.559 0 0 0-.004.788L9.69 6z"
        fill="inherit"
      />
      <rect
        fill="inherit"
        y="5"
        width="10"
        height="2"
        rx="1"
      />
    </g>
  </SvgIcon>
)

export default GameTypeInvited
