import React, { FC, ComponentProps } from 'react'
import { Fab } from '@material-ui/core'
import { NaviLink } from '../Link'

type Props = ComponentProps<typeof Fab> & ComponentProps<typeof NaviLink>

const NaviLinkFab: FC<Props> = (props) => (
  <Fab
    {...props}
    component={NaviLink as any}
  />
)

export default NaviLinkFab
