const loadScript = (url: string, domId: string) => {
  if (document.getElementById(domId)) {
    return
  }

  const script = document.createElement('script')
  script.id = domId
  script.type = 'text/javascript'
  script.src = url
  script.charset = 'utf-8'
  script.async = true

  const firstScript = document.getElementsByTagName('script')[0]
  firstScript.parentNode!.insertBefore(script, firstScript)
}

export default loadScript
