import React, { FC, ComponentProps } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import { Close } from '../Icons'

type Props = ComponentProps<typeof IconButton>

const useStyles = makeStyles(({ palette }) => ({
  icon: {
    fontSize: '75%',
    color: palette.common.black,
  },
}))

const CloseButton: FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <IconButton
      {...props}
      aria-label="Close"
      size="small"
    >
      <Close className={classes.icon} />
    </IconButton>
  )
}

export default CloseButton
