import React, { FC, ReactNode, ComponentProps as CP, useReducer, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import { IMatch, IPrediction } from 'protos'
import { makeStyles, Theme, useTheme, useMediaQuery } from '@material-ui/core'
import { FormattedMessage } from 'components'
import { Box, CenteredBox, Typography } from 'tackl-material'
import { Keyboard } from 'tackl-material/Icons'
import Actions from './Actions'
import Title from './Title'
import Counter from './Counter'
import MatchList from './MatchList'
import ScoreSlider from './ScoreSlider'
import CompletedState from './CompletedState'
import stateReducer, { PredictionMap, getPredictedScore } from './stateReducer'
import Tutorial from './Tutorial'
import useTutorial from './useTutorial'

type ExtendedMatch = IMatch & {
  title?: string | null
  gameId?: string | null
}

type Props = Pick<CP<typeof Actions>, 'finishButtonComponent'> & {
  matches: ExtendedMatch[]
  initialPredictions?: PredictionMap
  completedTitle?: ReactNode
  completedMessage?: ReactNode
  onPredictionSave?: (match: ExtendedMatch, prediction: IPrediction) => void
  onFinish: (predictions: PredictionMap, lastMatch?: ExtendedMatch, lastPrediction?: IPrediction) => void
}

const useStyles = makeStyles<Theme>(({ spacing, breakpoints, zIndex }) => ({
  root: {
    marginBottom: -24,
    overflow: 'hidden',
    [breakpoints.down('xs')]: {
      marginBottom: -16,
      height: 'calc(100vh - 72px)',
    },
  },
  sliderContainer: {
    width: 'auto',

    [breakpoints.up('md')]: {
      width: 48,
    },
  },
  padShadowTop: {
    paddingTop: spacing(5),
  },
  padShadowLeft: {
    [breakpoints.up('sm')]: {
      paddingLeft: 24,
    },

    [breakpoints.up('md')]: {
      paddingLeft: 16,
    },
  },
  padShadowRight: {
    [breakpoints.up('sm')]: {
      paddingRight: 24,
    },

    [breakpoints.up('md')]: {
      paddingRight: 16,
    },
  },
  slider: {
    zIndex: zIndex.modal - 1,
    minHeight: '60vh',
    maxHeight: 450,
    [breakpoints.up('sm')]: {
      height: 400,
      minHeight: 400,
    },
  },
  completedMessage: {
    maxWidth: 300,
  },
  middle: {
    width: '100%',
    maxWidth: 265,
  },
  helpIcon: {
    width: 52,
    height: 'auto',
  },
}))

const PredictionEditor: FC<Props> = ({
  matches,
  onFinish,
  onPredictionSave,
  completedTitle,
  completedMessage,
  finishButtonComponent,
  initialPredictions = {},
}) => {
  const classes = useStyles()
  const { zIndex } = useTheme<Theme>()

  const { shouldDisplayTutorial, closeTutorial } = useTutorial()

  const [{ matchIndex, teamIndex, predictions }, dispatch] = useReducer(stateReducer, {
    teamIndex: 0,
    matchIndex: 0,
    matchCount: matches.length,
    predictions: initialPredictions,
  })

  const isEmpty = !matches.length
  const isCompleted = !isEmpty && matchIndex >= matches.length
  const isLastMatch = !isEmpty && matchIndex >= matches.length - 1
  const isFinishActionEnabled = isLastMatch // change this to = isCompleted if you want the "old" behavior back

  const currentMatch = matches[matchIndex]
  const currentMatchId = currentMatch?.matchId ?? null
  const scores = getPredictedScore(predictions, currentMatchId)

  const homeScore = shouldDisplayTutorial ? 3 : scores.homeScore
  const awayScore = shouldDisplayTutorial ? 0 : scores.awayScore

  const restart = useCallback(() => {
    dispatch({ type: 'restart' })
  }, [])

  const toggleTeam = useCallback(() => {
    dispatch({ type: 'toggleTeam' })
  }, [])

  const incrementMatchIndex = useCallback(() => {
    if (onPredictionSave && currentMatch) {
      onPredictionSave(currentMatch, predictions[currentMatchId!])
    }

    dispatch({ type: 'nextMatch' })
  }, [currentMatch, currentMatchId, onPredictionSave, predictions])

  const finishPredictions = useCallback(() => {
    onFinish(predictions, currentMatch, currentMatchId ? predictions[currentMatchId] : undefined)
  }, [currentMatch, currentMatchId, onFinish, predictions])

  const changeHomeScore = useCallback((newScore: number) => {
    dispatch({ type: 'changeHomeScore', matchId: currentMatchId!, score: newScore })
  }, [currentMatchId])

  const changeAwayScore = useCallback((newScore: number) => {
    dispatch({ type: 'changeAwayScore', matchId: currentMatchId!, score: newScore })
  }, [currentMatchId])

  const changeCurrentTeamScore = teamIndex === 0 ? changeHomeScore : changeAwayScore

  const onSwipeToNextStep = incrementMatchIndex
  const onClickToNextStep = isFinishActionEnabled ? finishPredictions : incrementMatchIndex

  const handleKeydown = useCallback((event: KeyboardEvent) => {
    const value = event.key || String.fromCharCode(event.keyCode || event.which)
    const isNumeric = /^[0-9]$/.test(value)

    if (isNumeric) {
      changeCurrentTeamScore(Number(value))
      toggleTeam()
    } else if (event.key === 'Enter' || event.code === 'Enter') {
      onClickToNextStep()
    }
  }, [changeCurrentTeamScore, onClickToNextStep, toggleTeam])

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [handleKeydown])

  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'))

  return (
    <Box
      display="flex"
      flexWrap="nowrap"
      flexDirection="row"
      alignItems="flex-start"
      className={clsx(classes.root, classes.padShadowTop)}
    >
      <CenteredBox className={clsx(classes.sliderContainer, classes.padShadowLeft)}>
        <ScoreSlider
          value={homeScore}
          onChange={changeHomeScore}
          className={classes.slider}
          disabled={isEmpty}
          thumbColor={(
            (!!currentMatch && currentMatch.homeTeam!.primaryColor)
              ? `#${currentMatch.homeTeam!.primaryColor!}`
              : undefined
          )}
        />
      </CenteredBox>
      <CenteredBox
        display="flex"
        flexBasis="100%"
        flexDirection="column"
      >
        <Box
          display="flex"
          flexBasis="100%"
          flexDirection="column"
          className={classes.middle}
        >
          {!smDown && (
            <Box
              px={1}
              pb={3}
              mt={-5}
            >
              <Typography
                alpha={.5}
                align="center"
                variant="subtitle1"
              >
                <div>
                  <Keyboard
                    color="secondary"
                    className={classes.helpIcon}
                  />
                </div>
                <FormattedMessage id="web_predictioneditor_desktop_help_select" />
              </Typography>
            </Box>
          )}

          {
            isCompleted
              ? null
              : (
                <Title>
                  {
                    currentMatch?.title
                      ? currentMatch.title
                      : (
                        <Counter
                          total={matches.length}
                          current={Math.min(matchIndex + 1, matches.length)}
                        />
                      )
                  }
                </Title>
              )
          }

          <Box pt={isCompleted ? 4 : 2}>
            {isCompleted && (
              <CompletedState
                onRestart={restart}
                title={completedTitle}
                message={completedMessage}
              />
            )}
            <MatchList
              matches={matches}
              skipped={matchIndex}
              predictions={predictions}
              onRequestNextStep={onSwipeToNextStep}
              baseZIndex={shouldDisplayTutorial ? 0 : zIndex.drawer}
            />
          </Box>

          {
            !isCompleted
              ? (
                !smDown && (
                  <Box
                    px={1}
                    pt={42}
                  >
                    <Typography
                      alpha={.3}
                      align="center"
                      variant="subtitle1"
                    >
                      <FormattedMessage id="web_predictioneditor_desktop_help_save" />
                    </Typography>
                  </Box>
                )
              ) : null
          }
        </Box>
      </CenteredBox>
      <CenteredBox className={clsx(classes.sliderContainer, classes.padShadowRight)}>
        <ScoreSlider
          location="right"
          value={awayScore}
          onChange={changeAwayScore}
          className={classes.slider}
          disabled={isEmpty}
          thumbColor={(
            (!!currentMatch && currentMatch.awayTeam!.primaryColor)
              ? `#${currentMatch.awayTeam!.primaryColor!}`
              : undefined
          )}
        />
      </CenteredBox>

      <Actions
        step={(
          isFinishActionEnabled
            ? 'finish'
            : (homeScore >= 0 && awayScore >= 0) ? 'saveMatch' : 'skipMatch'
        )}
        onClick={onClickToNextStep}
        finishButtonComponent={finishButtonComponent}
      />

      <Tutorial
        open={shouldDisplayTutorial}
        onClose={closeTutorial}
      />
    </Box>
  )
}

export default PredictionEditor
