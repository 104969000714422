import { useState, useCallback } from 'react'

const useToggle = (initialValue: boolean) => {
  const [isOn, setIsOn] = useState(initialValue)

  const toggle = useCallback(() => {
    setIsOn(prevIsOn => !prevIsOn)
  }, [])

  const toggleOn = useCallback(() => {
    setIsOn(true)
  }, [])

  const toggleOff = useCallback(() => {
    setIsOn(false)
  }, [])

  return { isOn, toggle, toggleOn, toggleOff }
}

export default useToggle
