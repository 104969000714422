import React, { FC, ComponentProps } from 'react'
import clsx from 'clsx'
import { useIntl } from 'hooks'
import { useNavigation } from 'react-navi'
import { Navigation } from 'navi'
import { makeStyles, Theme, Box } from '@material-ui/core'
import { LinkIconButton, LinkButton } from '../Button'
import { Back as BackIcon } from '../Icons'

type LinkIconButtonProps = ComponentProps<typeof LinkIconButton>

interface Props extends Omit<LinkIconButtonProps, 'href' | 'tabIndex'> {
  href?: LinkIconButtonProps['href']
  tabIndex?: number
}

const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
  root: {
    color: 'inherit',
    // copied from IconButton to preserve old behavior
    padding: 12,
    minWidth: 'initial',
    [breakpoints.down('sm')]: {
      minHeight: 48,
      borderRadius: '150%',
    },
  },
  icon: {
    transform: 'translateX(-3px)',
  },
}))

const goBack = (navigation: Navigation) => (e: React.MouseEvent) => {
  e.preventDefault()
  navigation.goBack()
}

const BackButton: FC<Props> = ({ href, className, ...props }) => {
  const { t } = useIntl()
  const classes = useStyles()
  const navigation = useNavigation()

  return (
    <LinkButton
      href={href || '#'}
      aria-label={t('web_global_action_back')}
      onClick={href ? undefined : goBack(navigation)}
      onAuxClick={href ? undefined : goBack(navigation)}
      className={clsx(className, classes.root)}
      {...props}
    >
      <BackIcon
        color="inherit"
        className={classes.icon}
      />
      <Box
        component="span"
        sx={{ display: { md: 'block', xs: 'none '}}}
      >
        {t('web_global_action_back')}
      </Box>
    </LinkButton>
  )
}

export default BackButton
