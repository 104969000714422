import { createContext } from 'react'
import { SDKStatus, NotificationPermission } from 'services/onesignal'

export interface Context {
  sdkStatus: SDKStatus
  notificationPermission?: NotificationPermission
  isBlockedTemporarily: boolean
  isSubscribed: boolean
  userId: string | null
  subscribe?: () => void
  unsubscribe?: () => void
}

export const initialContext: Context = {
  sdkStatus: 'pending',
  isBlockedTemporarily: false,
  isSubscribed: false,
  userId: null,
}

const OneSignalContext = createContext<Context>(initialContext)

export default OneSignalContext
