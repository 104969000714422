import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Colon: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="14"
    height="72"
    viewBox="0 0 28 72"
    {...props}
  >
    <g>
      <circle
        r="14"
        cy="14"
        cx="14"
      />
      <circle
        r="14"
        cy="56"
        cx="14"
      />
    </g>
  </SvgIcon>
)

export default Colon
