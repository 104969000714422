import React, { FC } from 'react'
import { parseMarkdown } from 'lib'

type Props = {
  children?: string | null
}

const Markdown: FC<Props> = ({ children }) => (
  children ? (
    <span
      dangerouslySetInnerHTML={{
        __html: parseMarkdown(children),
      }}
    />
  ) : null
)

export default Markdown
