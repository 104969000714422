import { PropsWithChildren } from 'react'
import { makeStyles } from '@material-ui/core'
import { Typography } from 'tackl-material'

const useStyles = makeStyles({
  root: {
    minHeight: 21,
  },
})

export default function Title(props: PropsWithChildren) {
  const classes = useStyles()

  return (
    <Typography
      alpha={.5}
      align="center"
      variant="subtitle1"
      className={classes.root}
      {...props}
    />
  )
}
