import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Previous: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="10"
    height="17"
    viewBox="0 0 10 17"
    {...props}
  >
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M3.307 8.179l6.175 6.174a.801.801 0 0 1-.007 1.134l-.49.49a.81.81 0 0 1-1.135.008L1.245 9.38a.82.82 0 0 1-.157-.123l-.49-.49a.805.805 0 0 1-.233-.5.81.81 0 0 1 .233-.676l.49-.49a.8.8 0 0 1 .155-.12L7.85.372A.801.801 0 0 1 8.984.38l.49.49a.81.81 0 0 1 .008 1.134L3.307 8.18z"
    />
  </SvgIcon>
)

export default Previous
