import { useContext, useCallback, useEffect } from 'react'
import MatchDetailsDrawerContext from 'contexts/MatchDetailsDrawerContext'
import { Game } from 'protos'

interface Options {
  onClose?: () => void
}

const useMatchDetailsDrawer = (options?: Options) => {
  const { setMatchId, setGameType, setIsOpen, setOnClose } = useContext(MatchDetailsDrawerContext)

  const open = useCallback((matchId: string, gameType?: Game.GameType | null) => {
    setMatchId(matchId)
    setGameType(gameType)
    setIsOpen(true)
  }, [setIsOpen, setMatchId, setGameType])

  const close = useCallback(() => {
    setIsOpen(false)
    setMatchId(undefined)
    setGameType(undefined)
  }, [setIsOpen, setMatchId, setGameType])

  useEffect(() => {
    options && setOnClose(() => options.onClose)
  }, [options, setOnClose])

  return { open, close }
}

export default useMatchDetailsDrawer
