import React, { FC } from 'react'
import { Backdrop, Box, Grid, styled } from '@material-ui/core'
import { Tip } from 'tackl-material'
import { FormattedMessage } from 'components'

import tutorialArrowLeftUrl from './assets/tutorial_arrow_left.svg'
import tutorialArrowDownUrl from './assets/tutorial_arrow_down.svg'

type Props = {
  open: boolean
  onClose: () => void
}

const DimBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer - 1,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
}))

const CoverBackdrop = styled(Backdrop)(({ theme }) => ({
  color: theme.palette.common.white,
  zIndex: theme.zIndex.modal + 1,
  justifyContent: 'flex-start',
  alignItems: 'stretch',
}))

const LeftTipContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  minWidth: 300,
  top: '42vh',
  left: 70,

  [theme.breakpoints.up('sm')]: {
    width: '100vw',
    top: 300,
    left: 0,
  },

  [theme.breakpoints.up('md')]: {
    top: 400,
  },
}))

const LeftTipGrid = styled(Grid)(({ theme }) => ({
  margin: '0 auto',
  maxWidth: 300,

  [theme.breakpoints.up('md')]: {
    maxWidth: 400,
  },
}))

const BottomTipContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100vw',
  bottom: 100,

  [theme.breakpoints.up('md')]: {
    bottom: 165,
  },
}))

const LeftTip = styled(Tip)(({ theme }) => ({
  width: 160,
  marginLeft: theme.spacing(2),
}))

const BottomTip = styled(Tip)(({ theme }) => ({
  width: 160,
  marginBottom: theme.spacing(2),
}))

const Tutorial: FC<Props> = ({ open, onClose }) => {
  if (!open) {
    return null
  }

  return (
    <>
      <DimBackdrop open />

      <CoverBackdrop
        open
        invisible
        onClick={onClose}
      >
        <Box position="relative">
          <LeftTipContainer>
            <LeftTipGrid container>
              <Grid item>
                <img
                  src={tutorialArrowLeftUrl}
                  alt="arrow left"
                />
              </Grid>

              <Grid item>
                <LeftTip color="inherit">
                  <FormattedMessage id="web_bild_prediction_editor_slider_tip" />
                </LeftTip>
              </Grid>
            </LeftTipGrid>
          </LeftTipContainer>

          <BottomTipContainer>
            <BottomTip
              color="inherit"
              align="center"
            >
              <FormattedMessage id="web_bild_prediction_editor_button_tip" />
            </BottomTip>

            <img
              src={tutorialArrowDownUrl}
              alt="arrow down"
            />
          </BottomTipContainer>
        </Box>
      </CoverBackdrop>
    </>
  )
}

export default Tutorial
