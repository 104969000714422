interface Props {
  unit: string
  amount: number
  language: string
}

const currency = ({
  unit = '',
  amount = 0,
  language = 'en',
}: Props) => {
  const displayedAmount = Number.isFinite(amount as number)
    ? Number(amount).toLocaleString(language)
    : amount

  return `${displayedAmount}${unit}`
}

export default currency
