import React, { FC, ComponentProps } from 'react'
import { makeStyles, Theme, Drawer } from '@material-ui/core'
import { Box } from '../Box'
import CloseButton from './CloseButton'

type Props = ComponentProps<typeof Drawer> & {
  closeMethod?: 'default' | 'button'
  onClose?: () => void
}

const useStyles = makeStyles<Theme>(() => ({
  paperAnchorBottom: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
}))

const BottomDrawer: FC<Props> = ({ children, closeMethod = 'default', onClose, classes, ...props }) => {
  const localClasses = useStyles()

  return (
    <Drawer
      anchor="bottom"
      variant="temporary"
      {...props}
      onClose={onClose}
      classes={{
        ...localClasses,
        ...classes,
      }}
    >
      {
        closeMethod === 'button' && (
          <Box
            p={1}
            textAlign="right"
          >
            <CloseButton onClick={onClose} />
          </Box>
        )
      }
      {children}
    </Drawer>
  )
}

BottomDrawer.defaultProps = {
  elevation: 1,
}

export default BottomDrawer
