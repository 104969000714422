import React from 'react'
import MuiRadio, { RadioProps as MuiRadioProps } from '@material-ui/core/Radio'
import {
  RadioChecked as RadioCheckedIcon,
  RadioUnchecked as RadioUncheckedIcon,
} from '../Icons'

const Radio: React.FC<MuiRadioProps> = props => (
  <MuiRadio
    disableRipple
    disableTouchRipple
    {...props}
    icon={<RadioUncheckedIcon fontSize="large" />}
    checkedIcon={(
      <RadioCheckedIcon
        fontSize="large"
        color="primary"
      />
    )}
  />
)

export default Radio
