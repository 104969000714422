import { FC, ComponentProps as CP } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const BildBet: FC<CP<typeof SvgIcon>> = props => {
  return (
    <SvgIcon
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2066 0H0V21.4211H21.2066V0ZM16.9377 5.20792V2.27028H18.9588V19.1501L14.9385 19.1445C14.8202 19.1445 14.703 19.1209 14.5938 19.0751C14.4845 19.0293 14.3852 18.9622 14.3016 18.8777C14.2181 18.7931 14.1518 18.6927 14.1067 18.5822C14.0617 18.4718 14.0386 18.3534 14.0389 18.234L14.0415 6.11837C14.0409 5.87869 14.1342 5.64845 14.301 5.47791C14.4678 5.30737 14.6947 5.21032 14.9321 5.20792H16.9377ZM16.1171 17.0508H16.923V7.28281H16.1171V17.0508ZM13.1484 2.27028H11.0701V19.1501H13.1484V2.27028ZM10.179 2.27028H8.10075V4.36954H10.179V2.27028ZM10.1791 5.22648H8.10086V19.1501H10.1791V5.22648ZM2.24794 2.27028H6.31632C6.55438 2.27188 6.78214 2.36867 6.94974 2.53945C7.11729 2.71024 7.21096 2.9411 7.21027 3.18148V9.09741C7.20952 9.32684 7.11851 9.54655 6.95727 9.70825C6.79603 9.86995 6.57783 9.96038 6.35059 9.95969H6.26103C6.51223 9.95992 6.75319 10.0606 6.9311 10.2397C7.10901 10.4189 7.2094 10.6618 7.21027 10.9155V18.2393C7.21056 18.3586 7.18757 18.4768 7.14259 18.5872C7.09761 18.6975 7.03155 18.7978 6.94818 18.8824C6.86481 18.967 6.76575 19.0342 6.65668 19.0801C6.54761 19.126 6.4306 19.1498 6.31244 19.1501H2.24794V2.27028ZM4.32619 17.0508H5.13204L5.13203 11.2243H4.32618L4.32619 17.0508ZM4.3262 9.16786H5.13204L5.13203 4.36954H4.32619L4.3262 9.16786Z"
      />
    </SvgIcon>
  )
}

export default BildBet
