import React, { ComponentProps, FC } from 'react'
import { makeStyles } from '@material-ui/styles'
import { SimplePaletteColorOptions, Theme } from '@material-ui/core/styles'
import { Box } from 'tackl-material'
import { IGame } from 'protos'
import { JerseyFull } from 'components/Jersey'
import IndicatorIcon from './IndicatorIcon'

interface Props extends Omit<ComponentProps<typeof JerseyFull>, 'color' | 'label'> {
  color: SimplePaletteColorOptions
  state: IGame['state']
}

interface StyleProps {
  color: SimplePaletteColorOptions
}

const useStyles = makeStyles<Theme, StyleProps>(({ spacing }) => ({
  jerseyContainer: {
    zIndex: 1,
    position: 'relative',
    marginTop: 10,
    marginLeft: -27,
    marginBottom: -35,
  },
  jersey: {
    width: 88,
    height: 100,
  },
  icon: ({ color }) => ({
    color: color.contrastText,
  }),
}))

const PublicGameIndicator: FC<Props> = ({ color, state, ...props }) => {
  const classes = useStyles({ color })

  return (
    <Box className={classes.jerseyContainer}>
      <JerseyFull
        {...props}
        color={color.main.replace('#', '')}
        label={(
          <IndicatorIcon
            state={state}
            fontSize="small"
            className={classes.icon}
          />
        )}
        className={classes.jersey}
      />
    </Box>
  )
}

export default PublicGameIndicator
