import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const CheckboxChecked: FC<ComponentProps<typeof SvgIcon>> = (props) => (
  <SvgIcon
    width="47"
    height="47"
    viewBox="0 0 47 47"
    {...props}
  >
    <g fillRule="inherit">
      <rect
        x="2"
        y="2"
        width="43"
        height="43"
        rx="20"
        strokeWidth="3"
        stroke="#fff"
      />
      <path
        fill="#000"
        d="M22.7 30.5C22.2 31 21.2 31 20.7 30.5L15.9 25.7C15.4 25.2 15.4 24.2 15.9 23.7L16.5 23.1C17.1 22.5 18 22.5 18.6 23.1L23.3 27.8C23.9 28.4 23.9 29.3 23.3 29.9L22.7 30.5ZM20.2 29.8C20.1 29.7 20.1 29.6 20 29.5 19.7 28.9 19.7 28.2 20.2 27.7L30.4 17.5C31 17 31.9 17 32.5 17.5L33 18.1C33.6 18.7 33.6 19.6 33 20.2L22.9 30.3C22.3 30.9 21.4 30.9 20.8 30.3L20.2 29.8Z"
      />
    </g>
  </SvgIcon>
)

export default CheckboxChecked
