import {
  Palette,
  SimplePaletteColorOptions,
} from '@material-ui/core/styles/createPalette'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import { Color, TextColor } from './propTypes'

export const textColor = (paletteOption: SimplePaletteColorOptions) => ({
  color: paletteOption.main,
})

export const findTextColor = (palette: Palette, color?: Color | TextColor) => {
  if (!color) {
    return {}
  }

  const [colorName, shade] = color.split('-')

  return shade
    ? textColor(palette[colorName][shade])
    : textColor(palette[color])
}

export const paperColor = (paletteOption: SimplePaletteColorOptions) => ({
  color: paletteOption.contrastText,
  backgroundColor: paletteOption.main,
})

export const findPaperColor = (palette: Palette, color?: Color | TextColor) => {
  if (!color) {
    return {}
  }

  const [colorName, shade] = color.split('-')

  return shade
    ? paperColor(palette[colorName][shade])
    : paperColor(palette[color])
}

export const buttonColor = (paletteOption: SimplePaletteColorOptions) => ({
  ...paperColor(paletteOption),
  '&:hover': {
    backgroundColor: alpha(paletteOption.main, 0.7),
  },
})

export const findButtonColor = (palette: Palette, color?: Color) => {
  if (!color) {
    return {}
  }

  const [colorName, shade] = color.split('-')

  return shade
    ? buttonColor(palette[colorName][shade])
    : buttonColor(palette[color])
}
