import { useReducer, Reducer, useCallback, useEffect } from 'react'
import { useIntl } from 'hooks'
import { RpcError } from 'services/gamelogic'
import useDeepCompareCallback from './useDeepCompareCallback'

interface State<Res> {
  error?: RpcError
  response?: Res
  isLoading: boolean
}

type Action<Res> =
  | { type: 'reject', error: Error }
  | { type: 'resolve', response: Res }
  | { type: 'dismissError' }

const initialState = {
  isLoading: true,
}

const reducer = <Res>(state: State<Res>, action: Action<Res>): State<Res> => {
  switch (action.type) {
    case 'reject':
      return { ...state, isLoading: false, response: undefined, error: action.error }
    case 'resolve':
      return { ...state, isLoading: false, response: action.response, error: undefined }
    case 'dismissError':
      return { ...state, error: undefined }
  }
}

const useFetch = <Req = {}, Res = {}>(fn: (request?: Req, language?: string) => Promise<Res>, request?: Req) => {
  const { language } = useIntl()
  const [ state, dispatch ] = useReducer<Reducer<State<Res>, Action<Res>>>(reducer, initialState)

  const fetch = useDeepCompareCallback(async () => {
    try {
      dispatch({ type: 'resolve', response: await fn(request, language) })
    } catch (error) {
      dispatch({ type: 'reject', error: error as Error })
    }
  }, [fn, language, request])

  const dismissError = useCallback(() => {
    dispatch({ type: 'dismissError' })
  }, [])

  useEffect(() => {
    fetch()
  }, [fetch])

  return { ...state, refetch: fetch, dismissError }
}

export default useFetch
