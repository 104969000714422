import React, { FC, ComponentProps, Suspense } from 'react'
import { Modal, Box, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Tip } from 'tackl-material'
import { FormattedMessage } from 'components'
import LoadingInline from '../LoadingInline'

type Props = Pick<ComponentProps<typeof Modal>, 'open'>

const useStyles = makeStyles<Theme>(({ palette }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: palette.common.white,
  },
  loadingContainer: {
    outline: 'none',
  },
}))

const LoadingLayer: FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <Modal
      {...props}
      className={classes.root}
    >
      <Box className={classes.loadingContainer}>
        <Box ml={1}>
          <Tip
            align="center"
            component="div"
          >
            <div>
              <Suspense fallback={<div />}>
                <LoadingInline
                  width={122}
                  height={60}
                  hexFill="#ffffff"
                />
              </Suspense>
            </div>
            <FormattedMessage id="loading" />
          </Tip>
        </Box>
      </Box>
    </Modal>
  )
}

export default LoadingLayer
