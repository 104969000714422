import React, { FC } from 'react'
import prizesImg from './assets/prizes.svg'
import { ImageProps } from './propTypes'

const Drinks: FC<ImageProps> = props => (
  <img
    alt="drinks"
    {...props}
    src={prizesImg}
  />
)

export default Drinks
