import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const DragHandle: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="53"
    height="4"
    viewBox="0 0 53 4"
    {...props}
  >
    <rect
      rx="2"
      width="53"
      height="4"
      opacity={.15}
      fill="inherit"
      fillRule="evenodd"
    />
  </SvgIcon>
)

export default DragHandle
