import React, { FC } from 'react'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Colon, Edit } from 'tackl-material/Icons'
import { Box, CenteredBox, Typography } from 'tackl-material'
import { ActionProps } from './propTypes'

type Props = ActionProps

const useStyles = makeStyles<Theme>(({ palette, shape }) => ({
  colon: {
    opacity: .5,
    fontSize: 12,
    '&$live': {
      opacity: 1,
      color: palette.accent[100].main,
    },
  },
  editable: {
    width: 30,
    height: 30,
    border: `1px solid ${palette.divider}`,
    borderRadius: 15,
    backgroundColor: palette.neutral[50].main,
    '&$missingPrediction': {
      border: 'none',
      backgroundColor: palette.brand.main,
    },
  },
  editableIcon: {
    fontSize: '.9rem',
  },
  progress: {
    left: 0,
    right: 0,
    bottom: 0,
    height: 3,
    position: 'absolute',
  },
  live: {},
  missingPrediction: {},
}))

const MatchCardEditActions: FC<Props> = ({ homeScore, awayScore, isLive, isEditable, isPredictionMissing }) => {
  const classes = useStyles()

  return (
    <>
      {isEditable && (
        <CenteredBox
          mx={1.5}
          className={clsx(classes.editable, { [classes.missingPrediction]: isPredictionMissing })}
        >
          <Edit className={classes.editableIcon} />
        </CenteredBox>
      )}

      {!isEditable && (
        <>
          <Box pl={1.5}>
            <Typography variant="h3">
              {Number(homeScore)}
            </Typography>
          </Box>

          <Box px={0.5}>
            <Colon className={clsx(classes.colon, { [classes.live]: isLive })} />
          </Box>

          <Box pr={1.5}>
            <Typography variant="h3">
              {Number(awayScore)}
            </Typography>
          </Box>
        </>
      )}
    </>
  )
}

export default MatchCardEditActions
