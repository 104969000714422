import React, { FC, ComponentProps } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import Typography from './Typography'

type TypographyProps = ComponentProps<typeof Typography>

interface TipProps extends Omit<TypographyProps, 'variant'> {}

const useStyles = makeStyles(
  ({ customComponents = {} }: Theme) => customComponents.Tip || {},
)

const Tip: FC<TipProps> = props => {
  const classes = useStyles()

  return (
    <Typography
      {...props}
      classes={classes}
    />
  )
}

export default Tip
