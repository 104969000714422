import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const GameTypeJackpot: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="18"
    height="21"
    viewBox="0 0 18 21"
    {...props}
  >
    <path
      fill="inherit"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.73 11.25l-1.56 1.59.38 2.28c.02.06.04.13.04.2a.43.43 0 01-.45.43.42.42 0 01-.22-.06L9 14.5l-1.92 1.18a.42.42 0 01-.46-.02.44.44 0 01-.19-.43l.4-2.4-1.56-1.6a.44.44 0 01-.1-.44.43.43 0 01.33-.3l2.34-.39.76-1.96c.13-.33.67-.33.8 0l.77 1.96 2.33.4c.16.02.29.14.34.3.05.15 0 .32-.1.44zM9 .89l8.14 2.5v2.58L9.12 3.52a.42.42 0 00-.24 0L.86 5.97V3.4L9 .89zm8.7 1.75L9.11.02a.42.42 0 00-.24 0L.3 2.64a.44.44 0 00-.31.42V12.7c0 3.87 5.78 7.3 8.87 8.3a.42.42 0 00.26 0c3.1-1 8.87-4.43 8.87-8.3V3.06c0-.19-.12-.36-.3-.42z"
    />
  </SvgIcon>
)

export default GameTypeJackpot
