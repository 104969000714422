import React, { FC, ReactNode } from 'react'
import { CenteredBox, Typography, Box, Button } from 'tackl-material'
import { ThumbsUp } from 'tackl-material/Icons'
import { FormattedMessage } from 'components'

interface Props {
  title?: ReactNode
  message?: ReactNode
  onRestart?: () => void
}

const CompletedState: FC<Props> = ({ title, message, onRestart }) => {
  return (
    <CenteredBox flexDirection="column">
      <Box>
        <ThumbsUp
          fontSize="large"
          color="secondary"
        />
      </Box>

      <Box py={2}>
        <Typography variant="h3">
          {title || <FormattedMessage id="predictioneditor_public_complete_title" />}
        </Typography>
      </Box>

      <Box>
        <Typography
          alpha={.5}
          align="center"
        >
          {message || <FormattedMessage id="predictioneditor_public_complete_message" />}
        </Typography>
      </Box>
      <Box py={2}>
        <Button
          variant="contained"
          onClick={onRestart}
        >
          <FormattedMessage id="predictioneditor_action_reset" />
        </Button>
      </Box>
    </CenteredBox>
  )
}

export default CompletedState
