import { ListItemType } from './propTypes'

const createListItemsKey = (item: ListItemType) => {
  if (typeof item === 'string') {
    return item
  }

  return item.match.matchId!
}

export default createListItemsKey
