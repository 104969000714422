import React, { FC, ComponentProps, memo } from 'react'
import clsx from 'clsx'
import { JerseyStriped } from 'components'
import { Avatar, makeStyles, Theme } from '@material-ui/core'

interface Props extends Omit<ComponentProps<typeof Avatar>, 'color'> {
  color?: string | null
  width?: number
  height?: number
}

const useStyles = makeStyles<Theme, Props>({
  colorDefault: {
    backgroundColor: 'transparent',
  },
  avatar: ({ width, height }) => ({
    width,
    height,
    margin: 3,
    boxShadow: '0 0 0 3px white',
  }),
  jersey: {
    top: '30%',
    right: '15%',
    maxWidth: '115%',
    marginLeft: '-5%',
    flexShrink: 0,
    position: 'relative',
  },
})

const JerseyAvatar: FC<Props> = ({ width, height, color, className, ...props }) => {
  const classes = useStyles({ width, height })

  return (
    <Avatar
      className={clsx(className, classes.avatar)}
      classes={{ colorDefault: classes.colorDefault }}
      {...props}
    >
      <JerseyStriped
        color={color}
        side="front"
        className={classes.jersey}
      />
    </Avatar>
  )
}

JerseyAvatar.defaultProps = {
  width: 45,
  height: 45,
}

export default memo(JerseyAvatar)
