import React, { FC, ComponentProps } from 'react'
import clsx from 'clsx'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Box, Typography } from 'tackl-material'
import { FormattedMessage, FormattedDate } from 'components'

type Props = ComponentProps<typeof Box> & {
  isLive: boolean
  isUpcoming: boolean
  isPositive: boolean
  matchClock?: string | null
  matchStartTime?: number | Long
  predictionValue?: number | null
  homeScorePredicted?: number | null
  awayScorePredicted?: number | null
  isPredictionMissing: boolean
}

const useStyles = makeStyles<Theme>(({ spacing, palette, shape }) => ({
  root: {
    height: 30,
    display: 'flex',
    overflow: 'hidden',
    borderTop: `1px solid ${palette.divider}`,
    alignItems: 'stretch',
    borderBottomLeftRadius: shape.borderRadius,
    borderBottomRightRadius: shape.borderRadius,
  },
  column: {
    padding: `0 ${spacing(1)}px`,
    display: 'flex',
    minWidth: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  point: {
    '&$positive': {
      borderLeft: `2px solid ${palette.accent[50].main}`,
      backgroundColor: palette.accent[50].light,
    },
    '&$negative': {
      borderLeft: `2px solid ${palette.error.main}`,
      backgroundColor: palette.error.light,
    },
  },
  info: {
    flex: 1,
  },
  positive: {},
  negative: {},
}))

const MatchCardFooter: FC<Props> = ({
  isLive,
  className,
  isUpcoming,
  isPositive,
  matchClock = '0\'',
  matchStartTime = 0,
  predictionValue = 0,
  homeScorePredicted = 0,
  awayScorePredicted = 0,
  isPredictionMissing,
  ...props
}) => {
  const classes = useStyles()
  return (
    <Box
      className={clsx(className, classes.root)}
      {...props}
    >
      <Box className={clsx(classes.column)}>
        {isLive && (
          <Typography
            color="accent-100"
            variant="body2"
          >
            <strong>
              {matchClock}
            </strong>
          </Typography>
        )}

        {isUpcoming && (
          <Typography
            alpha={0.5}
            variant="body2"
          >
            <FormattedDate
              timestamp={Number(matchStartTime) * 1000}
              options={{
                hour: 'numeric',
                minute: 'numeric',
              }}
            />
          </Typography>
        )}
      </Box>

      <Box className={clsx(classes.column, classes.info)}>
        <Typography variant="body2">
          {isPredictionMissing && (
            <FormattedMessage
              id="match_footer_missingprediction"
            />
          )}

          {!isPredictionMissing && (
            <FormattedMessage
              id="match_footer_prediction_format"
              values={[Number(homeScorePredicted), Number(awayScorePredicted)]}
            />
          )}
        </Typography>
      </Box>

      <Box
        className={clsx(
          classes.column,
          classes.point,
          {
            [classes.positive]: !isUpcoming && isPositive,
            [classes.negative]: !isUpcoming && !isPositive,
          },
        )}
      >
        {!isUpcoming && (
          <Typography variant="body2">
            <FormattedMessage
              id="predictions_footer_format"
              values={[Number(predictionValue)]}
            />
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default MatchCardFooter
