import React, { FC, useCallback, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Box, Typography } from 'tackl-material'

interface Props {
  promoCode: string
}

const PromoCode: FC<Props> = ({ promoCode }) => {
  const [copied, setCopied] = useState(false)

  const copy = useCallback(() => {
    if (!copied) {
      setCopied(true)
      window.setTimeout(() => setCopied(false), 3000)
    }
  }, [copied])

  return (
    <Box
      mt={1}
      mb={-1}
      display="flex"
      justifyContent="center"
    >
      <CopyToClipboard
        text={promoCode}
        onCopy={copy}
      >
        <Box
          px={2}
          py={1}
          style={{ cursor: copied ? undefined : 'copy' }}
        >
          <Typography variant="h2">
            {promoCode}
          </Typography>
          <Typography
            variant="h5"
            color="primary"
          >
            {copied ? 'copied!' : 'copy code'} {/* TODO: missing translations */}
          </Typography>
        </Box>
      </CopyToClipboard>
    </Box>
  )
}

export default PromoCode
