import { Theme, createStyles, withStyles } from '@material-ui/core/styles'
import MuiFormControlLabel from '@material-ui/core/FormControlLabel'

const styles = ({ typography }: Theme) => createStyles({
  label: {
    fontWeight: typography.fontWeightBold as any,
  },
})

export default withStyles(styles)(MuiFormControlLabel)
