import React, { FC, ReactNode, ComponentProps } from 'react'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { BOTTOM_STEPPER_HEIGHT } from 'components/BottomStepper'
import { ContentContainer, Drawer } from 'tackl-material'

type Props = ComponentProps<typeof Drawer> & {
  action: ReactNode
}

export const FADED_DRAWER_HEIGHT = 80

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    marginTop: FADED_DRAWER_HEIGHT + BOTTOM_STEPPER_HEIGHT + theme.spacing(2),
  },
  paper: {
    border: 0,
    height: FADED_DRAWER_HEIGHT,
    pointerEvents: 'none',
    paddingTop: theme.spacing(1),
    bottom: BOTTOM_STEPPER_HEIGHT,
    ...theme.mixins.fadedBackground(theme),
  },
  container: {
    pointerEvents: 'auto',
  },
}))

const FadedActionDrawer: FC<Props> = ({ action, classes, className }) => {
  const { root, paper, container } = useStyles()

  return (
    <Drawer
      anchor="bottom"
      variant="permanent"
      className={clsx(root, className)}
      classes={{
        ...classes,
        paper: clsx(paper, classes && classes.paper),
      }}
    >
      <ContentContainer className={container}>
        {action as any /* WORKAROUND */}
      </ContentContainer>
    </Drawer>
  )
}

export default FadedActionDrawer
