import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const GameStateRefunded: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="18"
    height="16"
    viewBox="0 0 18 16"
    {...props}
  >
    <g
      fill="inherit"
      fillRule="evenodd"
    >
      <path d="M13.875 5.09C12.841 5.09 12 5.908 12 6.91c0 1.001.841 1.817 1.875 1.817s1.875-.816 1.875-1.818c0-1.002-.841-1.818-1.875-1.818zm0 4.365c-1.447 0-2.625-1.142-2.625-2.546s1.178-2.545 2.625-2.545S16.5 5.505 16.5 6.909s-1.178 2.546-2.625 2.546z" />
      <path d="M13.875 8a.37.37 0 0 1-.375-.364V6.182c0-.2.168-.364.375-.364a.37.37 0 0 1 .375.364v1.454a.37.37 0 0 1-.375.364M9.375.727C8.341.727 7.5 1.543 7.5 2.545c0 1.003.841 1.819 1.875 1.819s1.875-.816 1.875-1.819c0-1.002-.841-1.818-1.875-1.818zm0 4.364c-1.447 0-2.625-1.142-2.625-2.546C6.75 1.142 7.928 0 9.375 0S12 1.142 12 2.545c0 1.404-1.178 2.546-2.625 2.546z" />
      <path d="M9.375 3.636A.37.37 0 0 1 9 3.273V1.818c0-.2.168-.363.375-.363a.37.37 0 0 1 .375.363v1.455a.37.37 0 0 1-.375.363M4.383 7.372C3.74 6.782 1.17 6.59.397 6.545a.409.409 0 0 0-.28.099.358.358 0 0 0-.117.264v7.274c0 .2.168.363.375.363h2.25a.374.374 0 0 0 .356-.25c.055-.163 1.347-4.737 1.518-6.628a.358.358 0 0 0-.116-.295M15.75 11.279l-2.625 1.086c-.727.034-2.238-.01-2.625-.006-.381.005-2.329-.601-2.024-.721.065-.025.228-.03.524-.007 1.713.14 4.5.473 4.5-.727s-3.02-1.455-3.75-1.455-1.953-.634-3-.727c-.416-.036-.988-.091-1.568-.126-.237 1.563-.928 3.83-1.284 4.938l3.977 1.745c1.488.545 2.5 1.242 5.625 0 0 0 4.5-1.853 4.5-2.91 0-.874-1.031-1.5-2.25-1.09" />
    </g>
  </SvgIcon>
)

export default GameStateRefunded
