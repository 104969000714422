import React, { FC, useCallback } from 'react'
import clsx from 'clsx'
import { Grid, makeStyles, Theme } from '@material-ui/core'

import { FormattedMessage } from 'components'
import { Button, Typography } from 'tackl-material'
import { BetOffer } from 'protos'

type Props = {
  market: BetOffer.IMarket
  sponsor: BetOffer.ISponsor
  variant?: 'light' | 'dark'
}

const useStyles = makeStyles<Theme, Pick<Props, 'variant'>>(() => ({
  button: ({ variant }) => {
    const commonRules = {
      height: 30,
      color: '#fff',
      padding: `0 10px`,
      boxShadow: 'none',
      backgroundColor: 'rgb(124, 164, 231)',
      border: '1px solid rgba(0, 0, 0, 0.1)',

      '&:hover': {
        boxShadow: 'none',
        backgroundColor: 'rgba(124, 164, 231, 0.7)',
      },
    }

    if (variant === 'dark') {
      return commonRules
    }

    return {
      ...commonRules,
      borderBottom: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    }
  },
  fontSize11: {
    fontSize: 11,
  },
}))

const LotteryBetOfferOdds: FC<Props> = ({ market, sponsor, variant = 'light' }) => {
  const classes = useStyles({ variant })

  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
  }, [])

  return (
    <Grid
      container
      spacing={1}
      wrap="nowrap"
      alignItems="center"
    >
      <Grid
        xs
        item
      >
        <Typography
          noWrap
          alpha={.5}
          align="right"
          variant="body2"
          className={classes.fontSize11}
        >
          <FormattedMessage id="web_bild_advertisement_label" />
        </Typography>
      </Grid>

      <Grid item>
        <Button
          size="small"
          color={variant === 'dark' ? 'accent-300' : undefined}
          target="_blank"
          variant="contained"
          onClick={handleClick}
          disabled={!market.url}
          href={market.url ?? '#'}
          className={clsx(classes.button, classes.fontSize11)}
        >
          <FormattedMessage
            values={[market?.odd!]}
            id="web_bild_lotto_match_card_bookie_button_label"
          />
        </Button>
      </Grid>

      <Grid
        xs
        item
      >
        <img
          height={10}
          alt={sponsor.name!}
          src={variant === 'light' ? sponsor.logoUrl! : sponsor.logoUrlLight!}
        />
      </Grid>
    </Grid>
  )
}

export default LotteryBetOfferOdds
