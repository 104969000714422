import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const GameStateDraw: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="16"
    height="20"
    viewBox="0 0 16 20"
    {...props}
  >
    <g
      fill="inherit"
      fillRule="evenodd"
    >
      <path d="M6.86 5.955l.842-.833a.423.423 0 0 1 .72.295v4.167c0 .23-.19.416-.421.416a.418.418 0 0 1-.422-.416V6.423l-.123.122a.424.424 0 0 1-.596 0 .414.414 0 0 1 0-.59M1.07 9.226c.043.16-.02.432-.083.695-.1.43-.214.916.028 1.33.245.419.732.564 1.162.694.258.076.525.157.638.269.114.112.194.376.273.632.13.424.277.907.7 1.15.355.204.874.134 1.343.027.28-.064.56-.12.704-.082.147.039.355.232.539.403.33.308.705.656 1.205.656.5 0 .875-.348 1.206-.656.183-.171.391-.364.538-.403.146-.038.424.018.703.082.47.107.988.177 1.344-.028.423-.242.57-.724.7-1.149.079-.256.159-.519.272-.631.114-.113.381-.193.64-.27.429-.129.916-.275 1.16-.694.243-.414.129-.9.029-1.33-.063-.263-.126-.536-.083-.695.039-.146.234-.351.407-.533.31-.327.663-.698.663-1.193 0-.494-.352-.865-.663-1.192-.172-.183-.368-.388-.407-.534-.043-.16.02-.432.083-.695.1-.43.214-.916-.028-1.33-.245-.419-.732-.564-1.161-.693-.259-.077-.526-.158-.639-.27-.113-.112-.194-.376-.273-.632-.13-.424-.277-.907-.7-1.15C11.015.8 10.496.87 10.027.978c-.28.065-.559.12-.704.082-.147-.039-.355-.232-.538-.403C8.454.348 8.079 0 7.579 0s-.874.348-1.205.656c-.184.171-.392.364-.539.403-.145.038-.424-.017-.703-.082-.47-.107-.988-.177-1.344.028-.423.242-.57.724-.7 1.149-.079.256-.159.52-.272.631-.114.113-.381.193-.64.27-.429.129-.916.275-1.16.694-.243.414-.129.9-.029 1.33.063.263.126.536.084.695-.04.146-.235.351-.408.533-.31.327-.662.698-.662 1.193 0 .494.35.865.662 1.192.172.183.368.388.408.534" />
      <path d="M15.086 19.327l-2.06-4.95a.424.424 0 0 0-.717-.105 1.873 1.873 0 0 1-.517.445c-.572.327-1.316.262-2.012.105-.139-.037-.315.033-.419.13-.332.308-.888.825-1.67.872-.024.001-.045.014-.069.02-.015-.002-.028-.01-.043-.01-.833 0-1.405-.531-1.81-.908-.103-.098-.31-.125-.448-.091-.58.133-1.384.207-1.953-.116a1.876 1.876 0 0 1-.519-.447.423.423 0 0 0-.717.104l-2.1 5.048a.413.413 0 0 0 .103.465.42.42 0 0 0 .475.067l3.15-1.558 2.323 1.532a.422.422 0 0 0 .627-.2l.92-2.428.813 2.413c.041.123.138.22.263.262a.418.418 0 0 0 .37-.047l2.323-1.532 3.15 1.558c.06.03.124.044.188.044h.008c.197-.003.43-.184.43-.416a.413.413 0 0 0-.09-.257" />
    </g>
  </SvgIcon>
)

export default GameStateDraw
