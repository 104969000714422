import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const AddFriends: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="27"
    height="24"
    viewBox="0 0 27 24"
    {...props}
  >
    <g>
      <path d="M21.586 6c1.379 0 2.5-1.122 2.5-2.5S22.965 1 21.586 1a2.503 2.503 0 0 0-2.5 2.5c0 1.378 1.121 2.5 2.5 2.5M18.99 14.236l-.664-5.808C18.17 7.044 17.072 6 15.776 6h-2.553c-1.296 0-2.392 1.044-2.549 2.428l-.664 5.808c-.054.486.095.968.412 1.323.272.305.634.483 1.027.505l.552 7.472a.5.5 0 0 0 .5.464h4a.502.502 0 0 0 .498-.464l.552-7.472c.392-.022.754-.2 1.027-.505a1.7 1.7 0 0 0 .411-1.323M0 4h8v2H0z" />
      <path d="M3 1h2v8H3zM14.5 5C15.88 5 17 3.878 17 2.5S15.88 0 14.5 0A2.503 2.503 0 0 0 12 2.5C12 3.878 13.121 5 14.5 5M26.073 14.246l-.662-5.125c-.16-1.247-1.257-2.187-2.549-2.187h-2.554a2.61 2.61 0 0 0-.79.127.502.502 0 0 0-.33.615c.06.207.107.42.132.64l.662 5.807a2.698 2.698 0 0 1-.657 2.102 2.572 2.572 0 0 1-.472.402.5.5 0 0 0-.217.455l.452 5.393a.5.5 0 0 0 .498.459h4c.26 0 .476-.2.498-.46l.548-6.542a1.55 1.55 0 0 0 1.084-.521c.282-.322.412-.747.357-1.165" />
    </g>
  </SvgIcon>
)

export default AddFriends
