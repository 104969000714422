import React, { FC, ComponentProps } from 'react'
import { Typography } from 'tackl-material'

const MatchClock: FC<ComponentProps<typeof Typography>> = (props) => (
  <Typography
    {...props}
    variant="h5"
    color="accent-100"
  />
)

export default MatchClock
