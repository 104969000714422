import React, { ComponentProps, FC } from 'react'

import clsx from 'clsx'
import { makeStyles, Theme, LinearProgress, Grid } from '@material-ui/core'

import { IBetOffer, IPrediction } from 'protos'
import { Colon } from 'tackl-material/Icons'
import { JerseyStriped, LotteryBetOffer } from 'components'
import { CenteredBox, Typography } from 'tackl-material'

type Props = {
  isLive?: boolean | null
  homeScore?: number | null
  awayScore?: number | null
  betOffer?: IBetOffer | null
  isCancelled?: boolean | null
  homeTeamName?: string | null
  awayTeamName?: string | null
  homeTeamColor?: string | null
  awayTeamColor?: string | null
  matchProgress?: number | null
  prediction?: IPrediction | null
} & ComponentProps<typeof CenteredBox>

const useStyles = makeStyles<Theme, Pick<Props, 'isLive'>>(({ spacing, palette, shape }) => ({
  root: {
    height: 80,
    overflow: 'hidden',
    borderTopLeftRadius: shape.borderRadius,
    borderTopRightRadius: shape.borderRadius,
    backgroundColor: palette.neutral[100].main,
  },
  bold: {
    fontWeight: 'bold',
  },
  team: {
    lineClamp: 2,
    overflow: 'hidden',
    lineHeight: '14px',
    boxOrient: 'vertical',
    display: '-webkit-box',
    padding: spacing(0, 1),
    wordBreak: 'break-word',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  jerseyContainer: {
    width: 72,
    display: 'flex',
    alignSelf: 'start',
    height: ({ isLive }) => isLive ? 77 : 80,
    '&$left': {
      marginLeft: -32,
    },
    '&$right': {
      marginRight: -32,
    },
  },
  progress: {
    height: 3,
  },
  left: {},
  right: {},
}))

const LotteryMatchCardHeader: FC<Props> = ({
  isLive,
  betOffer,
  className,
  prediction,
  isCancelled,
  homeTeamColor,
  awayTeamColor,
  homeScore = 0,
  awayScore = 0,
  homeTeamName = '-',
  awayTeamName = '-',
  matchProgress = 0,
  ...props
}) => {
  const classes = useStyles({ isLive })

  return (
    <Grid
      container
      direction="column"
      className={clsx(classes.root, className)}
    >
      <Grid
        xs
        item
        container
      >
        <Grid
          item
          className={clsx(classes.jerseyContainer, classes.left)}
        >
          <JerseyStriped
            stripeOrigin="left"
            color={homeTeamColor}
          />
        </Grid>

        <Grid
          xs
          item
          container
          direction="column"
        >
          <Grid
            xs
            item
            container
            alignItems="center"
          >
            <Grid
              xs
              item
            >
              <Typography
                align="right"
                variant="body2"
                className={clsx(classes.bold, classes.team)}
              >
                {homeTeamName}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="h3">
                {isCancelled ? '-' : homeScore}
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                variant="body2"
                alpha={isLive ? 1 : .5}
                color={isLive ? 'accent-100' : 'default'}
              >
                <Colon
                  color="inherit"
                  fontSize="inherit"
                />
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="h3">
                {isCancelled ? '-' : awayScore}
              </Typography>
            </Grid>

            <Grid
              xs
              item
            >
              <Typography
                variant="body2"
                className={clsx(classes.bold, classes.team)}
              >
                {awayTeamName}
              </Typography>
            </Grid>
          </Grid>

          <Grid item>
            <LotteryBetOffer
              betOffer={betOffer}
              prediction={prediction}
            />
          </Grid>
        </Grid>

        <Grid
          item
          className={clsx(classes.jerseyContainer, classes.right)}
        >
          <JerseyStriped
            stripeOrigin="right"
            color={awayTeamColor}
          />
        </Grid>
      </Grid>

      {
        isLive && (
          <Grid item>
            <LinearProgress
              variant="determinate"
              className={classes.progress}
              value={Math.round(Number(matchProgress) * 100)}
            />
          </Grid>
        )
      }
    </Grid>
  )
}

export default LotteryMatchCardHeader
