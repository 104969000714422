import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Lock: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="33"
    height="50"
    viewBox="0 0 33 50"
    {...props}
  >
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M18.653 36.938l.75 5.624c.04.292-.044.605-.228.834a.937.937 0 0 1-.734.354H14.56a.937.937 0 0 1-.734-.354 1.108 1.108 0 0 1-.227-.834l.75-5.624c-1.624-.876-2.7-2.667-2.7-4.646 0-2.875 2.175-5.209 4.852-5.209 2.677 0 4.853 2.334 4.853 5.209 0 1.979-1.077 3.77-2.7 4.645zM9.706 13.541c0-4.021 3.048-7.292 6.794-7.292 3.746 0 6.794 3.27 6.794 7.292v7.291H9.706v-7.291zm19.412 7.396v-7.396C29.118 6.083 23.458 0 16.5 0 9.543 0 3.882 6.083 3.882 13.542v7.396C1.67 21.416 0 23.52 0 26.041v18.75C0 47.667 2.176 50 4.853 50h23.294C30.824 50 33 47.667 33 44.792v-18.75c0-2.521-1.67-4.625-3.882-5.105z"
    />
  </SvgIcon>
)

export default Lock
