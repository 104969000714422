import { useState, useCallback } from 'react'
import config from 'services/config'

const localStorageKey = 'tackl_prediction_tutorial'
const isTutorialSupported = config.tenantId === 'bild'

const useTutorial = () => {
  const [shouldDisplayTutorial, setTutorialEnabled] = useState(isTutorialSupported && !window.localStorage.getItem(localStorageKey))
  const closeTutorial = useCallback(() => {
    setTutorialEnabled(false)
    window.localStorage.setItem(localStorageKey, '1')
  }, [])

  return {
    shouldDisplayTutorial,
    closeTutorial,
  }
}

export default useTutorial
