import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Wager: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="50"
    height="46"
    viewBox="0 0 50 46"
    {...props}
  >
    <g
      fillRule="evenodd"
      fill="currentColor"
    >
      <path d="M32.467 13c-3.296 0-5.6 2.244-5.6 5s2.304 5 5.6 5c2.363 0 4.666-2.244 4.666-5s-2.303-5-4.666-5zm0 12C28.14 25 25 21.86 25 18s3.14-7 7.467-7C35.86 11 39 14.14 39 18s-3.14 7-6.533 7z" />
      <path d="M32 21a1 1 0 01-1-1v-4a1 1 0 012 0v4a1 1 0 01-1 1M20.467 2c-3.296 0-5.6 2.244-5.6 5s2.303 5 5.6 5c2.363 0 4.666-2.244 4.666-5s-2.304-5-4.666-5zm0 12C16.14 14 13 10.86 13 7s3.14-7 7.467-7C23.86 0 27 3.14 27 7s-3.14 7-6.533 7z" />
      <path d="M20 10a1 1 0 01-1-1V5a1 1 0 012 0v4a1 1 0 01-1 1" />
      <g fillRule="nonzero">
        <path
          d="M20.14 29.01a1 1 0 10-.281 1.98l.01.001 5.941 1.189a2.192 2.192 0 00.19-.87c0-.892 1.457-1.67.648-2.012a2.177 2.177 0 01-1.16.19l-5.348-.478z"
          opacity="0.2"
        />
        <path
          d="M46.15 25.624L31.021 39.178a2.995 2.995 0 01-2.94.691l-16.754-5.814a.991.991 0 00-.776.05L8 35.38v4l2.553-1.276a.991.991 0 01.775-.05l16.754 5.814a2.995 2.995 0 002.94-.691L47.51 28.404c.341-.33.52-.793.489-1.266a1.612 1.612 0 00-1.85-1.515v.001z"
          opacity="0.2"
        />
        <path
          d="M48.541 24.32a3.624 3.624 0 00-4.162-.142l-14.563 8.764-3.112-.622a4.181 4.181 0 00-1.575-6.998l-9.813-3.27a1.006 1.006 0 00-.558-.022l-6.812 1.704A1.979 1.979 0 006 22H1a1 1 0 100 2h5v18H1a1 1 0 100 2h5a2.002 2.002 0 002-2v-.383l3.07-1.535 16.383 5.685a4.991 4.991 0 004.924-1.119l16.488-14.772a3.64 3.64 0 00-.324-5.556zm-1.031 4.085L31.022 43.177a2.995 2.995 0 01-2.94.692l-16.754-5.815a.991.991 0 00-.776.05L8 39.38v-13.6l6.96-1.74 9.535 3.178a2.202 2.202 0 01-1.008 4.27l-3.347-.478a1 1 0 10-.281 1.98l.01.001 9.935 1.988a.996.996 0 00.711-.124l14.934-8.99a1.643 1.643 0 012.549 1.273 1.615 1.615 0 01-.488 1.267z"
        />
      </g>
    </g>
  </SvgIcon>
)

export default Wager
