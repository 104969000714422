import React, { ComponentProps as CP, FC } from 'react'

import clsx from 'clsx'
import { Box } from 'tackl-material'
import { makeStyles } from '@material-ui/core'

type Props = CP<typeof Box> & {}

const useStyles = makeStyles({
  root: {
    zIndex: 2,
    minHeight: 75,
    overflow: 'hidden',
    position: 'relative',
  },
})

const GameCardHeaderContainer: FC<Props> = ({ className, ...props }) => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      component="header"
      flexDirection="row"
      className={clsx(classes.root, className)}
      {...props}
    />
  )
}

export default GameCardHeaderContainer
