/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useCallback } from 'react'
import { marked, Renderer } from 'marked'
import { vsprintf } from 'sprintf-js'
import IntlContext from 'contexts/IntlContext'

export type TranslateFunction = (
  key: keyof Messages,
  values?: Array<string | number>,
  defaultMessage?: string,
) => string

const decode = (value: string): string => {
  return value.replace(/&amp;/g, '&')
}

const parse = (message: string, values?: Array<string | number>): string => {
  const renderer = new Renderer()
  renderer.text = value => value.replace(/&amp;/g, '&')
  renderer.paragraph = value => value

  try {
    if (values && values.length > 0) {
      return decode(marked(vsprintf(message, values) || '', { renderer, smartypants: true }))
    }

    return decode(marked(message || '', { renderer, smartypants: true }))
  } catch (error) {
    console.error({ error, message, values })
    return message
  }
}

const createTranslateFunction = (messages: Messages, language: string): TranslateFunction => {
  return (
    key,
    values,
    defaultMessage = key,
  ): string => {
    if (!messages[key]) {
      console.warn(`Message key not found: ${key}`)
      return parse(defaultMessage, values)
    }

    if (!(messages[key]![language])) {
      console.warn(`Message not found in this language: ${key} - ${language}`)
      return parse(messages[key]!['en'], values)
    }

    return parse(messages[key]![language], values)
  }
}

const createDateFormatterFunction = (language: string) => {
  return (timestamp: number, options?: Intl.DateTimeFormatOptions) => {
    return new Date(timestamp).toLocaleString(language, options)
  }
}

const createNumberFormatterFunction = (language: string) => {
  return (value: number, options?: Intl.NumberFormatOptions) => {
    return Intl.NumberFormat(language, options).format(value)
  }
}

const useIntl = () => {
  const { messages, language, setLanguage } = useContext(IntlContext)

  const t = useCallback(createTranslateFunction(messages, language), [language, messages])
  const d = useCallback(createDateFormatterFunction(language), [language])
  const n = useCallback(createNumberFormatterFunction(language), [language])

  return { t, d, n, language, setLanguage }
}

export default useIntl
