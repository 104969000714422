import React, { FC, ComponentProps } from 'react'
import MuiLink, { LinkProps as MuiLinkProps } from '@material-ui/core/Link'
import NaviLink from './NaviLink'

type Props = ComponentProps<typeof NaviLink> & MuiLinkProps

const Link: FC<Props> = props => (
  <MuiLink
    {...props}
    component={NaviLink}
  />
)

export default Link