import throttler from 'lodash.throttle'
import { useEffect, useState, useMemo, useCallback } from 'react'

const useWindowSize = (throttle = 250) => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  const setSizeThrottled = useMemo(() => throttler(setSize, throttle), [throttle])

  const resizeHandler = useCallback(function (this: Window) {
    setSizeThrottled({
      width: this.innerWidth,
      height: this.innerHeight,
    })
  }, [setSizeThrottled])

  useEffect(() => {
    window.addEventListener('resize', resizeHandler)
    return () => window.removeEventListener('resize', resizeHandler)
  }, [resizeHandler])

  return size
}

export default useWindowSize
