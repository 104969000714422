import React, { FC, ComponentProps } from 'react'
import { useTheme } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const Batman: FC<ComponentProps<typeof SvgIcon>> = props => {
  const { palette }: Theme = useTheme()

  return (
    <SvgIcon
      width="53"
      height="53"
      viewBox="0 0 53 53"
      {...props}
    >
      <g fillRule="inherit">
        <path d="M34.7 17.5L33.1 18.4C33.1 18.5 33.2 18.6 33.2 18.8 33.2 19.4 32.7 19.9 32.1 19.9L29.9 19.9C29.3 19.9 28.9 19.5 28.8 19 28.7 18.5 28.9 18 29.4 17.8L33.8 15.6C34.3 15.3 35 15.5 35.2 16.1 35.5 16.6 35.3 17.3 34.7 17.5L34.7 17.5ZM24.3 19C24.2 19.5 23.8 19.9 23.3 19.9L21.1 19.9C20.4 19.9 20 19.4 20 18.8L20 18.3 18.4 17.5C17.8 17.3 17.6 16.6 17.9 16.1 18.1 15.5 18.8 15.3 19.3 15.6L21.5 16.7C21.5 16.7 21.6 16.7 21.6 16.7L23.7 17.8C24.2 18 24.4 18.5 24.3 19L24.3 19ZM52.4 42.1L41.9 35.8 39.8 1C39.7 0.6 39.5 0.2 39 0.1 38.6-0.1 38.2 0 37.9 0.3L34.1 4.1C32.8 3.4 30.1 2.2 26.6 2.2 23 2.2 20.3 3.4 19 4.1L15.2 0.3C14.9 0 14.5-0.1 14.1 0.1 13.7 0.2 13.4 0.6 13.4 1L11.2 35.8 0.7 42.1C0.4 42.3 0.2 42.7 0.2 43.1L0.2 51.9C0.2 52.5 0.6 53 1.3 53L51.9 53C52.5 53 53 52.5 53 51.9L53 43.1C53 42.7 52.8 42.3 52.4 42.1L52.4 42.1Z" />
        <path
          d="M26.9 26.5C26.7 26.5 26.5 26.5 26.3 26.5L19.6 24.6 17.9 31.5 23.6 35.3 29.5 35.3 35.2 31.5 33.5 24.6 26.9 26.5"
          fill={palette.background.default}
        />
        <path d="M28.8 30.9L24.4 30.9C23.7 30.9 23.3 30.4 23.3 29.8 23.3 29.2 23.7 28.7 24.4 28.7L28.8 28.7C29.4 28.7 29.9 29.2 29.9 29.8 29.9 30.4 29.4 30.9 28.8 30.9" />
      </g>
    </SvgIcon>
  )
}

export default Batman
