import React, { FC, ComponentProps } from 'react'
import { NaviLink } from '../Link'
import Button from './Button'

type Props = Omit<ComponentProps<typeof Button>, 'href'> & Pick<ComponentProps<typeof NaviLink>, 'href' | 'state'>

const NaviLinkButton: FC<Props> = ({ href, ...props }) => (
  <Button
    {...props}
    href={href as any}
    component={NaviLink as any}
  />
)

export default NaviLinkButton
