import { useRef } from 'react'
import { dequal as deepEqual } from 'dequal'

const useDeepCompare = <T>(deps: T) => {
  const ref = useRef<T>()

  if (!deepEqual(deps, ref.current)) {
    ref.current = deps
  }

  return ref.current || deps
}

export default useDeepCompare
