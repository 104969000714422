import React, { FC } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'

interface Props {
  rankDelta?: number | null
}

const useStyles = makeStyles(theme => ({
  rankDeltaPositive: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 6px 6px 6px',
    borderColor: `transparent transparent ${theme.palette.accent[50].main} transparent`,
    WebkitTransform: 'rotate(360deg)',
  },
  rankDeltaNegative: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '6px 6px 0 6px',
    borderColor: `${theme.palette.error.main} transparent transparent transparent`,
    WebkitTransform: 'rotate(360deg)',
  },
  rankDeltaUnchanged: {
    width: 6,
    height: 6,
    marginLeft: 3,
    marginRight: 3,
    borderRadius: 6,
    backgroundColor: theme.palette.divider,
  },
}))

const RankChange: FC<Props> = ({ rankDelta }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx({
        [classes.rankDeltaPositive]: rankDelta! > 0,
        [classes.rankDeltaNegative]: rankDelta! < 0,
        [classes.rankDeltaUnchanged]: rankDelta === 0,
      })}
    />
  )
}

export default RankChange
