import ExifReader from 'exifreader'

const getOrientation = (file: File) => {
  const reader = new FileReader()

  return new Promise<number>((resolve) => {
    reader.onload = (ev) => {
      const target = ev.target as EventTarget & { result: ArrayBuffer }
      try {
        resolve(ExifReader.load(target.result).Orientation?.value as number || 0)
      } catch {
        resolve(0)
      }
    }

    reader.readAsArrayBuffer(file.slice(0, 128 * 1024))
  })
}

const resetOrientation = (srcImage: HTMLImageElement, srcOrientation: number) => {
  const canvas = document.createElement('canvas')
  const width = srcImage.width
  const height = srcImage.height
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('Cannot get canvas context.')
  }

  if (4 < srcOrientation && srcOrientation < 9) {
    canvas.width = height
    canvas.height = width
  } else {
    canvas.width = width
    canvas.height = height
  }

  switch (srcOrientation) {
    case 2: ctx.transform(-1, 0, 0, 1, width, 0); break
    case 3: ctx.transform(-1, 0, 0, -1, width, height); break
    case 4: ctx.transform(1, 0, 0, -1, 0, height); break
    case 5: ctx.transform(0, 1, 1, 0, 0, 0); break
    case 6: ctx.transform(0, 1, -1, 0, height, 0); break
    case 7: ctx.transform(0, -1, -1, 0, height, width); break
    case 8: ctx.transform(0, -1, 1, 0, 0, width); break
    default: break
  }

  ctx.drawImage(srcImage, 0, 0)

  return canvas
}

export const imageFileToBlob = (file: File) => {
  return new Promise<Blob>((resolve, reject) => {
    const fileUrl = URL.createObjectURL(file)
    const image = new Image()
    image.src = fileUrl

    image.onload = async () => {
      URL.revokeObjectURL(fileUrl)
      const orientation = await getOrientation(file)
      const result = await resetOrientation(image, orientation)

      result.toBlob(blob => {
        if (blob) {
          resolve(blob)
        } else {
          reject(new Error('Unknown error while processing image.'))
        }
      }, file.type)
    }

    image.onerror = (err) => {
      URL.revokeObjectURL(fileUrl)
      reject(err)
    }
  })
}
