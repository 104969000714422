import React, { FC, useState, useEffect, useCallback } from 'react'
import { Notification, INotification } from 'protos'
import { useSubmit } from 'hooks'
import isEmail from 'validator/lib/isEmail'
import { analyticsService } from 'services/global'
import { usersClaimBonus } from 'services/gamelogic'
import { Typography, MessageDrawer, Button, TextField, Box } from 'tackl-material'
import { FormattedMessage, Markdown } from 'components'
import { PromoCode, NotificationHeader } from './components'

interface Props {
  open: boolean
  onDismiss: (notificationId: INotification['notificationId']) => void
  initialNotification: INotification
  claimBonus?: typeof usersClaimBonus
}

const mapNotificationButtonLayoutToMessageActionsLayout = (buttonLayout?: INotification['buttonLayout']) => {
  switch (buttonLayout) {
    case Notification.ButtonLayout.BL_VERTICAL:
      return 'vertical'

    case Notification.ButtonLayout.BL_HORIZONTAL:
    default:
      return 'horizontal'
  }
}

const NotificationDrawer: FC<Props> = ({ open, onDismiss, initialNotification, claimBonus }) => {
  const [notification, setNotification] = useState(initialNotification)
  const { isLoading, submit, response, reset } = useSubmit(claimBonus ?? usersClaimBonus)

  useEffect(() => {
    if (open) {
      analyticsService.get().logEvent('Notification_Opened', {
        Title: notification.title,
      })
    }
  }, [notification.title, open])

  useEffect(() => {
    if (response?.resultNotification) {
      setNotification(response.resultNotification)
      reset()
    }
  }, [response, reset])

  const [email, setEmail] = useState(notification.extraAction?.claimEmail ?? '')

  const { notificationId } = notification
  const dismiss = useCallback(() => onDismiss(notificationId), [onDismiss, notificationId])

  const altNotificationId = notification.extraAction?.altNotificationId
  const dismissAlt = useCallback(() => onDismiss(altNotificationId), [onDismiss, altNotificationId])

  if (!notification.extraAction) {
    return (
      <MessageDrawer
        open={open}
        icon={<NotificationHeader notification={notification} />}
        headline={<Markdown>{notification.title}</Markdown>}
        body={<Markdown>{notification.message}</Markdown>}
        actions={[
          <Button
            fullWidth
            key="action"
            color="primary"
            variant="contained"
            onClick={dismiss}
          >
            {notification.action || <FormattedMessage id="alert_ok" />}
          </Button>,
        ]}
      />
    )
  }

  const { actionTitle, promoCode } = notification.extraAction
  let shouldDisplayEmailInput = false
  let actionButtonProps = {} as any

  switch (notification.extraAction.actionType) {
    case Notification.ExtraAction.ActionType.AT_CLAIM_BONUS:
      actionButtonProps = {
        onClick: () => submit({ bonusId: notification.extraAction!.bonusId }),
      }
      break

    case Notification.ExtraAction.ActionType.AT_CLAIM_BONUS_WITH_EMAIL:
      shouldDisplayEmailInput = true
      actionButtonProps = {
        disabled: !isEmail(email),
        onClick: () => submit({
          bonusId: notification.extraAction!.bonusId,
          email,
        }),
      }
      break

    case Notification.ExtraAction.ActionType.AT_OPEN_URL:
      actionButtonProps = {
        href: notification.extraAction.url,
        target: '_blank',
        component: 'a',
      }
      break

    case Notification.ExtraAction.ActionType.AT_CONFIRM_ALT_NOTIFICATION:
      actionButtonProps = {
        onClick: dismissAlt,
      }
      break

    case Notification.ExtraAction.ActionType.AT_DISMISS:
    default:
      actionButtonProps = {
        onClick: dismiss,
      }
      break
  }

  return (
    <MessageDrawer
      open={open}
      icon={<NotificationHeader notification={notification} />}
      headline={<Markdown>{notification.title}</Markdown>}
      body={(
        <>
          <Typography
            alpha={.5}
            align="center"
            variant="body1"
          >
            <Markdown>{notification.message}</Markdown>
            {shouldDisplayEmailInput && (
              <Box mt={2}>
                <TextField
                  id="email"
                  name="email"
                  type="email"
                  label={<FormattedMessage id="registration_placeholder_email" />}
                  disabled={isLoading}
                  value={email}
                  onChange={ev => setEmail(ev.target.value)}
                />
              </Box>
            )}
          </Typography>
          {promoCode && <PromoCode promoCode={promoCode} />}
        </>
      )}
      bodyProps={{ alpha: 1 }}
      footer={notification.footer && (
        <Box mx={7}>
          <Typography
            alpha={0.5}
            variant="caption"
          >
            <strong>
              {notification.footer}
            </strong>
          </Typography>
        </Box>
      )}
      actionsLayout={mapNotificationButtonLayoutToMessageActionsLayout(notification.buttonLayout)}
      actions={[
        <Button
          fullWidth
          key="action"
          color="secondary"
          variant="contained"
          disabled={isLoading}
          onClick={dismiss}
        >
          {notification.action || <FormattedMessage id="alert_ok" />}
        </Button>,
        <Button
          fullWidth
          key="action"
          color="primary"
          variant="contained"
          disabled={isLoading}
          {...actionButtonProps}
        >
          {actionTitle}
        </Button>,
      ]}
    />
  )
}

export default NotificationDrawer
