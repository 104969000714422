import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const GroupsFull: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="22"
    height="24"
    viewBox="0 0 22 24"
    {...props}
  >
    <g
      fill="inherit"
      fillRule="evenodd"
    >
      <path d="M4.30444348,6 C5.62253043,6 6.69574783,4.878 6.69574783,3.5 C6.69574783,2.122 5.62253043,1 4.30444348,1 C2.98635652,1 1.91313913,2.122 1.91313913,3.5 C1.91313913,4.878 2.98635652,6 4.30444348,6" />
      <path d="M17.6957478,6 C19.0138348,6 20.0870522,4.878 20.0870522,3.5 C20.0870522,2.122 19.0138348,1 17.6957478,1 C16.3776609,1 15.3044435,2.122 15.3044435,3.5 C15.3044435,4.878 16.3776609,6 17.6957478,6" />
      <path d="M15.2114696,14.2363 L14.5772957,8.4283 C14.4271217,7.0443 13.3778174,6.0003 12.1391217,6.0003 L9.69712174,6.0003 C8.45746957,6.0003 7.40912174,7.0443 7.25894783,8.4283 L6.62477391,14.2363 C6.5712087,14.7223 6.71468696,15.2043 7.01790435,15.5593 C7.27903478,15.8643 7.62433913,16.0423 8.00025217,16.0643 L8.52825217,23.5363 C8.54642609,23.7983 8.75494783,24.0003 9.0046,24.0003 L12.830687,24.0003 C13.0812957,24.0003 13.2898174,23.7983 13.3079913,23.5363 L13.8359913,16.0643 C14.2119043,16.0423 14.5572087,15.8643 14.8183391,15.5593 C15.1215565,15.2043 15.2650348,14.7223 15.2114696,14.2363" />
      <path d="M10.9179304,5 C12.2360174,5 13.3092348,3.878 13.3092348,2.5 C13.3092348,1.122 12.2360174,0 10.9179304,0 C9.59984348,0 8.52662609,1.122 8.52662609,2.5 C8.52662609,3.878 9.59984348,5 10.9179304,5" />
      <path d="M6.30386087,16.2251 C5.81986087,15.6581 5.59029565,14.8921 5.67351304,14.1231 L6.30768696,8.3151 C6.33064348,8.1051 6.3756,7.9031 6.4282087,7.7051 C6.46264348,7.5771 6.44542609,7.4381 6.38133913,7.3231 C6.3182087,7.2071 6.21203478,7.1221 6.08864348,7.0881 C5.86768696,7.0281 5.67638261,7.0001 5.48507826,7.0001 L3.12342609,7.0001 C1.92012174,7.0001 0.900469565,7.9411 0.751252174,9.1901 L0.138121739,14.3141 C0.087426087,14.7411 0.213686957,15.1701 0.486295652,15.4931 C0.73786087,15.7901 1.09081739,15.9711 1.46768696,15.9961 L1.91438261,22.5361 C1.93255652,22.7971 2.14012174,23.0001 2.39168696,23.0001 L6.21777391,23.0001 C6.46838261,23.0001 6.67690435,22.7971 6.69412174,22.5361 L7.06238261,17.1491 C7.07577391,16.9631 6.98873043,16.7861 6.8376,16.6881 C6.63194783,16.5551 6.45307826,16.3991 6.30386087,16.2251" />
      <path d="M21.8618783,14.314 L21.2487478,9.19 C21.100487,7.941 20.0798783,7 18.8765739,7 L16.5149217,7 C16.2490087,7 15.9811826,7.049 15.7181391,7.147 C15.4837913,7.234 15.3517913,7.495 15.4158783,7.747 C15.4637043,7.932 15.5067478,8.12 15.5287478,8.315 L16.1629217,14.123 C16.2461391,14.892 16.0165739,15.658 15.5325739,16.225 C15.4264,16.349 15.2982261,16.465 15.1289217,16.592 C14.9930957,16.693 14.9175304,16.861 14.9299652,17.035 L15.3058783,22.536 C15.3240522,22.797 15.5316174,23 15.7831826,23 L19.6092696,23 C19.8598783,23 20.0684,22.797 20.0856174,22.536 L20.532313,15.997 C20.9091826,15.971 21.2630957,15.791 21.5137043,15.493 C21.786313,15.17 21.9125739,14.741 21.8618783,14.314" />
    </g>
  </SvgIcon>
)

export default GroupsFull
