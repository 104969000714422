import React, { FC, ComponentProps } from 'react'
import clsx from 'clsx'
import { Paper } from 'tackl-material'
import { IBetSlip } from 'protos'
import { makeStyles, Theme } from '@material-ui/core'

import BetSlipBets from './BetSlipBets'
import BetSlipHeader from './BetSlipHeader'
import BetSlipFooter from './BetSlipFooter'

interface Props extends ComponentProps<typeof Paper> {
  betSlip: IBetSlip
}

const useStyles = makeStyles<Theme>(({ shadows }) => ({
  root: {
    backgroundColor: 'transparent',
    filter: `drop-shadow(${shadows[1]})`,
  },
}))

const BetSlip: FC<Props> = ({ betSlip, className, ...props }) => {
  const classes = useStyles()

  return (
    <Paper
      square={false}
      className={clsx(className, classes.root)}
      {...props}
    >
      <BetSlipHeader
        title={betSlip.slipTitle}
        subtitle={betSlip.slipSubtitle}
        timestamp={betSlip.slipTimestamp}
        sponsorLogoUrl={betSlip.sponsorLogoUrl}
      />

      <BetSlipBets bets={betSlip.bet} />

      <BetSlipFooter
        fee={betSlip.footerFee}
        stake={betSlip.footerStake}
        bonus={betSlip.footerBonus}
        prizeTitle={betSlip.footerPrizeTitle}
        prizeValue={betSlip.footerPrizeValue}
      />
    </Paper>
  )
}

export default BetSlip
