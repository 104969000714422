import { createContext } from 'react'

interface Context {
  messages: Messages
  language: string
  setLanguage: (language: string) => void
}

const initialContext: Context = {
  messages: {},
  language: '',
  setLanguage: () => undefined,
}

const IntlContext = createContext<Context>(initialContext)

export default IntlContext
