import { FC, ComponentProps as CP } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Shield: FC<CP<typeof SvgIcon>> = props => (
  <SvgIcon
    width="21"
    height="24"
    viewBox="0 0 21 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 14.5c0 2.57-4.39 5.94-8.331 7.36a.494.494 0 01-.457-.06.5.5 0 01-.212-.41V12H2.5c-.276 0-.5-.22-.5-.5V4.989a.5.5 0 01.356-.479l8-2.401a.502.502 0 01.644.479V11h7.5c.276 0 .5.22.5.5v3zm1.644-11.479l-10-3a.504.504 0 00-.288 0l-10 3A.502.502 0 000 3.5v11c0 4.42 6.742 8.35 10.352 9.48.048.01.098.02.148.02.05 0 .1-.01.148-.02C14.258 22.85 21 18.92 21 14.5v-11a.502.502 0 00-.356-.479z"
    />
  </SvgIcon>
)

export default Shield
