import React, { FC, ComponentProps } from 'react'
import { RpcError } from 'services/gamelogic'
import { FormattedMessage } from 'components'
import { MessageDrawer, Button } from 'tackl-material'

type Props = Pick<ComponentProps<typeof MessageDrawer>, 'open' | 'onClose'> & {
  error?: RpcError
}

const RpcErrorMessageDrawer: FC<Props> = ({ error, onClose, ...props }) => (
  <MessageDrawer
    {...props}
    onClose={onClose}
    headline={error && error.title? error.title : <FormattedMessage id="alert_unexpectederror_title" />}
    body={error && error.message? error.message : <FormattedMessage id="alert_unexpectederror_message" />}
    actions={[
      <Button
        fullWidth
        color="primary"
        variant="contained"
        key="dismissError"
        onClick={onClose}
      >
        <FormattedMessage id="alert_ok" />
      </Button>,
    ]}
  />
)

export default RpcErrorMessageDrawer
