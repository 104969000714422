import React from 'react'
import Select, { SelectProps } from '@material-ui/core/Select'
import { Down } from '../Icons'

const NativeSelect: React.FC<SelectProps> = (props) => {
  return (
    <Select
      {...props}
      native
      disableUnderline
      IconComponent={Down}
    />
  )
}

export default NativeSelect
