import React from 'react'
import { Beers } from 'tackl-material/Graphics'
import { FormattedMessage } from 'components'
import { Container, Box, Message, LinkButton } from 'tackl-material'

const NotFound = () => {
  return (
    <Container maxWidth="sm">
      <Box py={8}>
        <Message
          icon={<Beers />}
          headline={<FormattedMessage id="web_notfound_title" />}
          body={<FormattedMessage id="web_notfound_message" />}
          actions={[
            <Box
              key="games"
              textAlign="center"
            >
              <LinkButton
                href="/games"
                color="primary"
                variant="contained"
              >
                <FormattedMessage id="web_notfound_action" />
              </LinkButton>
            </Box>,
          ]}
        />
      </Box>
    </Container>
  )
}

export default NotFound
