import { IGamesGetDetailsResponse } from 'protos'
import { ListItemType } from './propTypes'

const createListItem = (
  date: (timestamp: number, options?: Intl.DateTimeFormatOptions) => string,
  response?: IGamesGetDetailsResponse,
) => {
  const items: ListItemType[] = []

  let previousStartDate = ''

  if (
    response &&
    response.gameDetails &&
    response.gameDetails.match &&
    response.gameDetails.ownEntry &&
    response.gameDetails.ownEntry.prediction
  ) {
    const predictions = response.gameDetails.ownEntry.prediction

    for (const match of response.gameDetails.match) {
      if (match.startTime) {
        const startDate = date(Number(match.startTime) * 1000, {
          day: '2-digit',
          month: 'long',
          weekday: 'long',
        })

        if (previousStartDate !== startDate) {
          previousStartDate = startDate
          items.push(startDate)
        }

        // NOTE: meh!
        const prediction = predictions.find(prediction => {
          return prediction.matchId === match.matchId
        })

        items.push({ match, prediction, gameType: response.gameDetails.game?.gameType })
      }
    }
  }

  return items
}

export default createListItem
