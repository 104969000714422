import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Edit: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="14"
    height="14"
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M1.337 10.496l2.152 2.155-.026.03a.277.277 0 0 1-.146.128L.4 13.977a.284.284 0 0 1-.315-.064.292.292 0 0 1-.064-.316l1.167-2.92a.252.252 0 0 1 .064-.093l.085-.088zM13.55.43c.292.298.451.683.45 1.092a1.512 1.512 0 0 1-.452 1.075c-.52.513-1.012 1.004-1.48 1.465l-2.15-2.149L11.396.43c.575-.572 1.578-.572 2.154 0zM9.506 2.321l2.147 2.15c-4.966 4.922-6.988 6.954-7.762 7.754L1.75 10.082l7.756-7.76z"
    />
  </SvgIcon>
)

export default Edit
