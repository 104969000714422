import { map, redirect, Matcher } from 'navi'
import { RouterContext } from 'components/Router'

const redirectOptions = {
  exact: false,
}

const createWithAuthCheck = (redirectTo: string) => (matcher: Matcher<RouterContext>) => {
  return map<RouterContext>((request, context) => {
    if (!context.userId) {
      return redirect({
        pathname: redirectTo,
        query: request.mountpath ? {
          redirectTo: request.mountpath,
        } : undefined,
      }, redirectOptions)
    }

    return matcher
  })
}

export default createWithAuthCheck('/auth')
