import React, { FC } from 'react'
import { useIntl } from 'hooks'

interface Props {
  id: keyof Messages
  values?: Array<string | number>
  component?: 'span' | 'strong' | 'p'
  defaultMessage?: string
}

const FormattedMessage: FC<Props> = ({
  id,
  values,
  component: Component = 'span',
  defaultMessage,
}) => {
  const { t } = useIntl()

  return <Component dangerouslySetInnerHTML={{ __html: t(id, values, defaultMessage) }} />
}

export default FormattedMessage
