import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Checkmark: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="16"
    height="12"
    viewBox="0 0 16 12"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.06 11.02l-.11.12-.5.48c-.5.5-1.34.5-1.86 0L.4 7.53a1.26 1.26 0 0 1 0-1.82l.49-.48c.51-.5 1.34-.5 1.86 0l2.78 2.71L13.26.38c.52-.5 1.35-.5 1.86 0l.5.48c.5.5.5 1.31 0 1.81l-8.56 8.35z"
    />
  </SvgIcon>
)

export default Checkmark
