const createSingleton = <T>(initial: T) => {
  let value: T = initial

  return {
    get: <U extends T>() => value as U,
    set: (newValue: T) => { value = newValue },
  }
}

export default createSingleton
