import * as protos from 'protos'
import config from 'services/config'
import { authService } from 'services/global'

const { baseUrl } = config.gamelogic

export type GamelogicFunction<Req, Res> = (request?: Req) => Promise<Res>
type ProtoClassSignature = { create: Function, encode: Function, decode: Function }
type ProtoClassType<T = typeof protos[keyof typeof protos]> = T extends ProtoClassSignature ? T : never

interface Options<T> {
  rpcPath: string
  requestClass?: ProtoClassType
  responseClass: ProtoClassType
  errorResultBlacklist?: number[]
  getBody?: (req?: T) => RequestInit['body']
}

export class RpcError extends Error {
  readonly code?: string | null
  readonly title?: string | null
  readonly result?: number | null

  constructor(
    code?: string | null,
    title?: string | null,
    result?: number | null,
    message?: string | null,
  ) {
    super()
    this.code = code
    this.title = title
    this.result = result
    this.message = message || ''
  }
}

const createGamelogicFunction = <Req, Res>({
  rpcPath,
  requestClass,
  responseClass,
  errorResultBlacklist = [],
  getBody = () => undefined,
}: Options<Req>) => {
  return async (request?: Req, language: string = 'en') => {
    const customInit = requestClass
      ? {
        body: requestClass.encode(request || {}).finish(),
        headers: { 'Content-Type': 'application/x-protobuf' } as Record<string, string>,
      } : {
        body: getBody(request),
        headers: {},
      }

    const { authorizedFetch } = authService.get()
    const response = await authorizedFetch(baseUrl + '/' + rpcPath, {
      method: 'POST',
      headers: {
        'Accept': 'application/x-protobuf',
        'App-Device': window.navigator.userAgent,
        'App-Version': '1.3.1',
        'App-Platform': 'web',
        'App-Locale': language,
        ...customInit.headers,
      },
      body: customInit.body,
    })

    const responseData: any = responseClass.decode(new Uint8Array(await response.arrayBuffer()))

    const result: number = responseData.result
    const hasError = result !== 1 && !errorResultBlacklist.includes(result)

    if (hasError) {
      throw new RpcError(
        undefined,
        responseData.errorTitle,
        responseData.result,
        responseData.errorMessage,
      )
    }

    return responseData as Res
  }
}

export default createGamelogicFunction
