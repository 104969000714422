import React, { FC, ComponentProps, MouseEvent, useCallback } from 'react'
import clsx from 'clsx'
import { useNavigation } from 'react-navi'
import { analyticsService } from 'services/global'
import { IUser, Game, GameTemplate } from 'protos'
import { Markdown, FormattedMessage } from 'components'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Box, Paper, CenteredBox, Typography, Avatar, Button } from 'tackl-material'
import { GameTypePublic, GameTypeHosted, GameTypeInvited, GameTypeJackpot } from 'tackl-material/Icons'

type Props = Omit<ComponentProps<typeof Paper>, 'variant'> & {
  user?: IUser | null
  text?: string | null
  gameId?: string | null
  size?: 'normal' | 'large'
  state?: Game.State | null
  variant?: 'game' | 'template'
  showPredictNext?: boolean | null
  type?: Game.GameType | GameTemplate.TemplateType | null
}

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  leftDivider: {
    borderLeft: `1px solid ${palette.divider}`,
  },
  rightDivider: {
    borderRight: `1px solid ${palette.divider}`,
  },
  iconContainer: {
    minWidth: 40,
    minHeight: 40,
    backgroundColor: palette.background.default,
  },
  typeIcon: {
    width: 16,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  square: {
    borderRadius: 0,
  },
  largeAvatar: {
    margin: spacing(2, 0, 2, 1),
    minWidth: 45,
    minHeight: 45,
  },
}))

const GameTypeIcon: FC<{ type: Props['type'], variant: Props['variant'] }> = ({ type, variant = 'game' }) => {
  const { iconContainer, rightDivider, typeIcon } = useStyles()

  let Icon = GameTypePublic

  if (variant === 'game') {
    switch (type) {
      case Game.GameType.GT_PRIVATE_HOSTED:
        Icon = GameTypeHosted
        break
      case Game.GameType.GT_PRIVATE_INVITED:
        Icon = GameTypeInvited
        break
      case Game.GameType.GT_JACKPOT:
        Icon = GameTypeJackpot
        break
    }
  }

  if (variant === 'template') {
    switch (type) {
      case GameTemplate.TemplateType.GT_JACKPOT:
        Icon = GameTypeJackpot
        break
      case GameTemplate.TemplateType.GT_PUBLIC:
        Icon = GameTypePublic
        break
    }
  }

  return (
    <CenteredBox
      p={1}
      className={clsx(iconContainer, rightDivider)}
    >
      <Icon
        fontSize="small"
        className={typeIcon}
      />
    </CenteredBox>
  )
}

const UserPhoto: FC<{ src: string, size: 'normal' | 'large' }> = ({ src, size }) => {
  const classes = useStyles()

  return (
    <Box ml={1}>
      <Avatar
        src={src}
        className={clsx({ [classes.largeAvatar]: size === 'large' })}
      />
    </Box>
  )
}

const InfoText: FC<{ text: Props['text'], size: 'normal' | 'large' }> = ({ text, size }) => {
  return (
    <Box
      px={1}
      pr={size === 'normal' ? 1 : 2}
      flex={1}
      minWidth={0}
    >
      <Typography
        noWrap={size === 'normal'}
        variant="subtitle1"
      >
        <Markdown>{text}</Markdown>
      </Typography>
    </Box>
  )
}

const PredictNextButton: FC<{ gameId: Props['gameId'], state: Props['state'] }> = ({ gameId, state }) => {
  const navigation = useNavigation()
  const { noWrap, leftDivider, square } = useStyles()

  const predictNext = useCallback((e: MouseEvent) => {
    e.preventDefault()

    analyticsService.get().logEvent('PredictNext_Tapped', {
      State: Game.State[state ?? Game.State.GS_UNSET],
    })

    navigation.navigate(`/games/${gameId}/my-predictions`)
  }, [gameId, navigation, state])

  return (
    <Box>
      <Button
        size="small"
        color="brand"
        variant="contained"
        onClick={predictNext}
        className={clsx(noWrap, leftDivider, square)}
      >
        <FormattedMessage id="game_action_predictnext" />
      </Button>
    </Box>
  )
}

const GameInfoBar: FC<Props> = ({
  user,
  text,
  type,
  state,
  gameId,
  showPredictNext,
  size = 'normal',
  variant = 'game',
  ...props
}) => {
  return (
    <Paper
      color="neutral-300"
      elevation={0}
      component="footer"
      {...props}
    >
      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
      >
        {type !== undefined && (
          <GameTypeIcon
            type={type}
            variant={variant}
          />
        )}

        {user && (
          <UserPhoto
            src={user.photoUrl!}
            size={size}
          />
        )}

        <InfoText
          text={text}
          size={size}
        />

        {showPredictNext && (
          <PredictNextButton
            state={state}
            gameId={gameId}
          />
        )}
      </Box>
    </Paper>
  )
}

export default GameInfoBar
