import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Info: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="5"
    height="11"
    viewBox="0 0 5 11"
    {...props}
  >
    <path d="M2.292 0c.69 0 1.25.547 1.25 1.222 0 .675-.56 1.222-1.25 1.222s-1.25-.547-1.25-1.222C1.042.547 1.602 0 2.292 0zm1.875 11H.833A.824.824 0 0 1 0 10.185c0-.45.373-.815.833-.815h.625a.206.206 0 0 0 .209-.203V5.5a.206.206 0 0 0-.209-.204H.833A.824.824 0 0 1 0 4.481c0-.45.373-.814.833-.814h.834c.92 0 1.666.73 1.666 1.63v3.87c0 .112.094.203.209.203h.625c.46 0 .833.365.833.815 0 .45-.373.815-.833.815z" />
  </SvgIcon>
)

export default Info
