import React, { FC, ReactNode, memo } from 'react'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Typography } from 'tackl-material'
import jerseySmall from './assets/jersey_front_small.png'
import jerseySmall2x from './assets/jersey_front_small@2x.png'
import jerseySmall3x from './assets/jersey_front_small@3x.png'
import jerseyMedium from './assets/jersey_front_medium.png'
import jerseyMedium2x from './assets/jersey_front_medium@2x.png'
import jerseyMedium3x from './assets/jersey_front_medium@3x.png'
import jerseyLarge from './assets/jersey_front_large.png'
import jerseyLarge2x from './assets/jersey_front_large@2x.png'
import jerseyLarge3x from './assets/jersey_front_large@3x.png'

type Props = {
  className?: string
  label?: ReactNode
  color?: string | null
  size?: 'small' | 'medium' | 'large'
}

const createBackgroundBreakpoints = (assets: [string, string, string]) => ({
  maskImage: `url(${assets[0]})`,
  background: `center / contain no-repeat url(${assets[0]})`,
  '@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)': {
    background: `center / contain no-repeat url(${assets[1]})`,
  },
  '@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 292dpi)': {
    background: `center / contain no-repeat url(${assets[2]})`,
  },
})

const useStyles = makeStyles<Theme, Props>(({ palette, typography }) => ({
  root: ({ color }) => ({
    color: palette.common.white,
    maskSize: 'contain',
    maskPosition: 'center',
    position: 'relative',
    userSelect: 'none',
    margin: 0,
    padding: 0,
    '&:before': {
      content: '" "',
      display: 'block',
      height: '25%',
      mixBlendMode: 'multiply',
      backgroundColor: color? `#${color}` : palette.secondary.main,
    },
    '&:after': {
      content: '" "',
      display: 'block',
      borderStyle: 'solid',
      mixBlendMode: 'multiply',
      borderColor: `${color? '#' + color : palette.secondary.main} transparent transparent transparent`,
    },
  }),
  sizeSmall: {
    fontSize: 7,
    width: 50,
    height: 57,
    ...createBackgroundBreakpoints([jerseySmall, jerseySmall2x, jerseySmall3x]),
    '&:after': {
      borderWidth: `${50 / 4}px ${50 / 2}px 0 ${50 / 2}px`,
    },
  },
  sizeMedium: {
    fontSize: 10,
    width: 88,
    height: 100,
    ...createBackgroundBreakpoints([jerseyMedium, jerseyMedium2x, jerseyMedium3x]),
    '&:after': {
      borderWidth: `${88 / 4}px ${88 / 2}px 0 ${88 / 2}px`,
    },
  },
  sizeLarge: {
    fontSize: 14,
    width: 146,
    height: 166,
    ...createBackgroundBreakpoints([jerseyLarge, jerseyLarge2x, jerseyLarge3x]),
    '&:after': {
      borderWidth: `${146 / 4}px ${146 / 2}px 0 ${146 / 2}px`,
    },
  },
  label: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '20%',
    zIndex: 1,
    fontWeight: typography.fontWeightBold as any,
  },
}))

const JerseyFull: FC<Props> = (props) => {
  const classes = useStyles(props)
  const { size, label, className } = props

  return (
    <figure
      className={clsx(className, classes.root, {
        [classes.sizeSmall]: size === 'small',
        [classes.sizeMedium]: !size || size === 'medium',
        [classes.sizeLarge]: size === 'large',
      })}
    >
      {label && (
        <Typography
          align="center"
          variant="inherit"
          component="figcaption"
          className={classes.label}
        >
          {label}
        </Typography>
      )}
    </figure>
  )
}

export default memo(JerseyFull)
